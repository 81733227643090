import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soluciones-y-productos',
  templateUrl: './soluciones-y-productos.component.html',
  styleUrls: ['./soluciones-y-productos.component.css']
})
export class SolucionesYProductosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
