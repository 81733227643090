import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cultura-organizacional',
  templateUrl: './cultura-organizacional.component.html',
  styleUrls: ['./cultura-organizacional.component.css']
})
export class CulturaOrganizacionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
