<header>
<div id="encabezado">
<nav id="nav">
    <ul class="container">
        <li><a href="#/principal"><!--<img id="logo" src="..\..\assets\Imagenes\tbell.png">-->家</a></li>
        <!--<li><a href="#pprincipal">Inicio</a></li>-->
        <li><a href="#/quienes-somos">关于我们</a></li>
        <li><a href="#/que-hacemos">我们所做的</a>
            <ul>
                <li><a href="#/qh-infraestructura-de-telecom">Infraestructura de Telecom.</a>
                    <ul>
                        <li><a href="#/i-infraestructura">Industria</a></li>
                        <li><a href="#/ic-infraestructura">Infraestructura Critica</a></li>
                        <li><a href="#/ci-infraestructura">Ciudades Inteligentes</a></li>
                        <li><a href="#/c-infraestructura">Corporativos</a></li>
                        <li><a href="#/e-infraestructura">Escuelas</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-seguridad">Seguridad</a>
                    <ul>
                        <li><a href="#/i-seguridad">Industria</a></li>
                        <li><a href="#/ic-seguridad">Infraestructura Critica</a></li>
                        <li><a href="#/ci-seguridad">Ciudades Inteligentes</a></li>
                        <li><a href="#/c-seguridad">Corporativos</a></li>
                        <li><a href="#/e-seguridad">Escuelas</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-comunicaciones-unificadas">Comunicaciones Unificadas</a>
                    <ul>
                        <li><a href="#/i-com-uni">Industria</a></li>
                        <li><a href="#/ic-com-uni">Infraestructura Critica</a></li>
                        <li><a href="#/ci-com-uni">Ciudades Inteligentes</a></li>
                        <li><a href="#/c-com-uni">Corporativos</a></li>
                        <li><a href="#/e-com-uni">Escuelas</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-logistica">Logística</a>
                    <ul>
                        <li><a href="#/i-logistica">Industria</a></li>
                        <li><a href="#/ci-logistica">Ciudades Inteligentes</a></li>
                        <li><a href="#/c-logistica">Corporativos</a></li>
                        <li><a href="#/e-logistica">Escuelas</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-colaboracion-remota">Colaboración Remota</a>
                    <ul>
                        <li><a href="#/i-colab-remota">Industria</a></li>
                        <li><a href="#/ci-colab-remota">Ciudades Inteligentes</a></li>
                        <li><a href="#/c-colab-remota">Corporativos</a></li>
                        <li><a href="#/e-colab-remota">Escuelas</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="#/donde-trabajamos">我们在哪里工作</a>
            <ul>
                <li><a href="#/dt-industria">Industria</a>
                    <ul class="para-quien">
                        <li><a href="#/i-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/i-seguridad">Seguridad</a></li>
                        <li><a href="#/i-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/i-logistica">Logística</a></li>
                        <li><a href="#/i-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-infraestructura-critica">Infraestructura Critica</a>
                    <ul class="para-quien">
                        <li><a href="#/ic-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/ic-seguridad">Seguridad</a></li>
                        <li><a href="#/ic-com-uni">Comunicaciones Unificadas</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a>
                    <ul class="para-quien">
                        <li><a href="#/ci-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/ci-seguridad">Seguridad</a></li>
                        <li><a href="#/ci-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/ci-logistica">Logística</a></li>
                        <li><a href="#/ci-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-corporativos">Corporativos</a>
                    <ul class="para-quien">
                        <li><a href="#/c-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/c-seguridad">Seguridad</a></li>
                        <li><a href="#/c-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/c-logistica">Logística</a></li>
                        <li><a href="#/c-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-escuelas">Escuelas</a>
                    <ul class="para-quien">
                        <li><a href="#/e-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/e-seguridad">Seguridad</a></li>
                        <li><a href="#/e-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/e-logistica">Logística</a></li>
                        <li><a href="#/e-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <!--<li><a href="#/blog">Blog</a></li>-->
        <li><a href="https://t-bell.mercadoshops.com.mx/" id="link-tienda">店铺</a></li>
        <!--<li><a href="https://tbell.freshdesk.com/support/home">Soporte</a></li>-->
        <li><a href="#/contacto">接触</a></li>
    </ul>
</nav>
</div>
</header>