import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-cities-remote-collaboration',
  templateUrl: './smart-cities-remote-collaboration.component.html',
  styleUrls: ['./smart-cities-remote-collaboration.component.css']
})
export class SmartCitiesRemoteCollaborationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
