import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-critical-infrastructure-unified-comm',
  templateUrl: './critical-infrastructure-unified-comm.component.html',
  styleUrls: ['./critical-infrastructure-unified-comm.component.css']
})
export class CriticalInfrastructureUnifiedCommComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
