<div class="footer">
    <footer>
        <div class="container-footer-all">
        <div class="container-footer">
            <!--
            <div class="columna1">
                <h1>Mas información de la empresa</h1>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d943.0145480167374!2d-98.19403557084685!3d19.017157267104018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc0966034380d%3A0x439eef3fdfc4b144!2sT-Bell%20Telecom!5e0!3m2!1ses!2smx!4v1626885284872!5m2!1ses!2smx" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                --
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat vero eum incidunt nam explicabo. Est a aliquid quis debitis atque molestias iure, necessitatibus sunt illum sapiente expedita adipisci, porro quia!
                </p>
                --
            </div>
        -->
            <div class="columna2">
                <h1>Redes sociales</h1>
                <div class="fila">
                    <a href="https://faceboook.com/T-Bell-Telecom/" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\facebook2.png" alt="facebook">
                    </a>
                    <label for="">Siguenos en Facebook</label>
                </div>
                <div class="fila">
                    <a href="https://twitter.com/TbellTelecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\twitter2.png" alt="twitter">
                    </a>
                    <label for="">Siguenos en Twitter</label>
                </div>
                <div class="fila">
                    <a href="https://www.linkedin.com/company/t-bell-telecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\linkedin2.png" alt="linkedin">
                    </a>
                    <label for="">Siguenos en LinkedIn</label>
                </div>
            </div>
            <div class="columna3">
                <h1>Contacto</h1>
                <div class="fila2">
                    <a href="mailto:ventascorporativas@t-bell.com.mx">
                        <img src="..\..\assets\Imagenes\rs\b-email.png" alt="email">
                    </a>
                    <label for="">ventascorporativas@t-bell.com.mx</label>       
                </div>
                <div class="fila2">
                    <a href="https://api.whatsapp.com/send?phone=5212223017051&text=¿Qué servicios ofrecen?" target="_blank">
                        <img src="..\..\assets\Imagenes\rs\whatsapp-logo.png" alt="telefono">
                    </a>
                    <label for="">WhatsApp</label>       
                </div>
                <!--
                <div class="fila2">
                    <img src="..\..\assets\Imagenes\rs\b-telefono.png" alt="telefono">
                    <label for="">+52 800-7-2355-11</label>       
                </div>
                -->
            </div>
        </div>
        </div>
        <!---->
        <div class="container-derechos">
            <div class="footer-copy">
                <div class="copyright">
                    <small>© 2022 T-Bell Telecom</small>
                </div>
            </div>
        </div>
    </footer>
</div>