<!--<app-navegador></app-navegador>
<div id="cuerpo">
	<div id="titulo">
		<h1>Ciudades Inteligentes</h1>	
	</div>
</div>-->

<app-navegador-wb></app-navegador-wb>
<div class="base">

	<!--                            INICIO VIDEO                                   -->
    <div class="video">
        <div class="videos">
            <div>
				<video src="..\..\assets\Videos\video-home3.mp4" autoplay="true" loop="true" muted="true" width="1702" height="1216"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\CIUDAD INT. A.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\CIUDAD INT. C.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div>
        <div class="tit">
            <h1>Ciudades Inteligentes</h1>
        </div>
    </div>
	<!--                            FIN VIDEO                                      -->


	<!--                            INICIO CIRCULOS                                -->
	<div class="circulos">
        <a href="#infraestructura">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (11).png">
        </a>
        <a href="#seguridad">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (1).png">
        </a>
        <a href="#comunicacion">
            <img src="..\..\assets\Imagenes\nuevas\comunicaciones-unificadas.png">
        </a>
        <a href="#logistica">
			<img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (3).png">
		</a>
        <a href="#colaboracion">
			<img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (4).png">
		</a>
    </div>
	<!--                           FIN CIRCULOS                                    -->


    <div class="titulo-infraestructura" id="infraestructura">
        <h1>infraestructura de telecomunicaciones</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-infraestructura-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-1.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Fibra osp</h1>
                            <p>
                                Ideales para la instalación de edificios, <br>
                                campus y conductos subeterráneos. <br>
                                Diseñados para tolerar ambientes <br>
                                externos con velocidades de hasta 10 GB.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-2.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Microondas PTM / PMP</h1>
                            <p>
                                Conecta hasta las redes de más <br>
                                difícil acceso, de manera <br>
                                inalámbrica teniendo un mayor <br>
                                alcance y estabilidad, Logrando asi <br>
                                la comunicación entre las <br>
                                diferentes sedes.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR INFRAESTRUCTURA-->

    <div class="titulo-seg-elec" id="seguridad">
        <h1>Seguridad Electrónica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-seguridad-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-3.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>CCTV y Voceo</h1>
                            <p>
                                Circuito cerrado de televisión <br>
                                (servidor, VMS, cámaras) para la <br>
                                vigilancia de algún punto. Pueden estar <br>
                                integrados altavoces que sirven para <br>
                                dar alertas en caso de contingencias, <br>
                                dar anuncios, hacer sonar alarmas y/o <br>
                                advertencias.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-seguridad-6">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-4.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Video-Analíticos</h1>
                            <p>
                                Apoya al personal de seguridad para <br>
                                analizar comportamientos y <br>
                                actitudes en zonas difíciles de <br>
                                visualizar; agilizando su respuesta <br>
                                en caso de percance.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-5.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Postes de emergencia</h1>
                            <p>
                                Permite que los usuarios manden <br>
                                una señal de alarma en caso de <br>
                                emergencia.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-6.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Centro de monitoreo - C5</h1>
                            <p>
                                Centraliza el monitoreo de <br>
                                comunicación y seguridad de <br>
                                diferentes zonas.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-5">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-7.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Detección de disparos</h1>
                            <p>
                                Funciona para detectar <br>
                                frecuencias y diferenciar entre un <br>
                                ruido común y un disparo, <br>
                                mandando una señal de alarma.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-6">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-6">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-8.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Luces de señalización de vehiculos</h1>
                            <p>
                                La más alta tecnología en sistemas de <br>
                                señalización audiovisual para vehículos de emergencia.<br>
                                Contamos con los mejores productos como <br>
                                Torretas, Minitorretas, Estrobos, Sirenas, <br>
                                Bocinas, Controladores, Barras para Interior, <br>
                                Accesorios para Motocicletas y mucho más.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR SEGURIDAD-->

    <div class="titulo-com-uni" id="comunicacion">
        <h1>Comunicación unificada</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-comunicacion-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-9.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Voceo IP</h1>
                            <p>
                                Comunica anuncios, <br>
                                notificaciones y alarmas con <br>
                                una mayor fidelidad.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COMUNICACION-->
    <div class="espacio-footer"></div>

    <div class="titulo-infraestructura" id="logistica">
        <h1>Logistica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-logistica-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-10.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de velocidad</h1>
                            <p>
                                Vigila la velocidad del <br>
                                transporte dentro de la <br>
                                ciudad, reduciendo accidentes <br>
                                automovilísticos.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-logistica-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-logistica-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-11.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de flujo</h1>
                            <p>
                                Vigila que los automóviles sigan <br>
                                el sentido de la calle y/o avenida, <br>
                                en caso contrario, se manda una <br>
                                alarma al centro de control.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-logistica-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-logistica-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-12.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Detección de tumultos</h1>
                            <p>
                                Detecta cuando se aglomeran <br>
                                personas en un lugar para así <br>
                                poder evitar disturbios, tumultos, etc.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-logistica-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR LOGISTICA-->

    <div class="titulo-seg-elec" id="colaboracion">
        <h1>Colaboración Remota</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-colab-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-13.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Sala de juntas</h1>
                            <p>
                                Mejora la experiencia en <br>
                                conferencias realizadas de <br>
                                manera remota.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-colab-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-colab-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-colab-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\ciudad-14.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Aulas interactivas</h1>
                            <p>
                                Mejora la experiencia educativa <br>
				                de los estudiantes, durante su aprendizaje.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-colab-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-colab-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COLABORACION-->

    <div class="espacio-footer"></div>


	<!--                            INICIO INFRAESTRUCTURA                         -->
    <!--
	<div id="infraestructura" class="infraestructura">
		<div class="infraestructura-titulo">
            <h1>infraestructura de telecomunicaciones</h1>
        </div>
        <div class="infraestructura-subtitulo">
            <h2>Fibra osp</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\23.png">
            <p>
                Ideales para la instalación de edificios, <br>
				campus y conductos subeterráneos. <br>
				Diseñados para tolerar ambientes <br>
				externos con velocidades de hasta 10 GB.
            </p>
        </div>

        <div class="infraestructura-subtitulo2">
            <div class="vacio2i"></div>
            <h2>Microondas PTM / PMP</h2>
        </div>
		<div class="derecha">
            <p>
                Conecta hasta las redes de más <br>
				difícil acceso, de manera <br>
				inalámbrica teniendo un mayor <br>
				alcance y estabilidad, Logrando asi <br>
				la comunicación entre las <br>
				diferentes sedes.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\24.png">
        </div>

	</div>
    -->
	<!--                            FIN INFRAESTRUCTURA                            -->

    <!--                            INICIO SEGURIDAD                               -->
    <!--
    <div id="seguridad" class="seguridad">

        <div class="seguridad-titulo">
            <h1>Seguridad Electrónica</h1>
        </div>

		<div class="seguridad-subtitulo">
            <h2>CCTV y voceo</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\25.png">
            <p>
                Circuito cerrado de televisión <br>
                (Servidor, VMS, Cámaras). Pueden tener <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas <br>
                y/o advertencias.
            </p>
        </div>

        <div class="seguridad-subtitulo">
            <div class="vacio-sd"></div>
            <h2>Video-analíticos</h2>
        </div>
		<div class="derecha">
            <p>
                Apoya al personal de seguridad para <br>
				analizar comportamientos y <br>
				actitudes en zonas difíciles de <br>
				visualizar; agilizando su respuesta <br>
				en caso de percance.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\26.png">
        </div>

        <div class="seguridad-subtitulo2">
            <h2>Postes de emergencia</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\27.png">
            <p>
                Permite que los usuarios manden <br>
				una señal de alarma en caso de <br>
				emergencia.
            </p>
        </div>

        <div class="seguridad-subtitulo3">
            <div class="vacio-sd"></div>
            <h2>Centro de monitoreo - C5</h2>
        </div>
		<div class="derecha">
            <p>
                Centraliza el monitoreo de <br>
				comunicación y seguridad de <br>
				diferentes zonas.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\28.png">
        </div>

        <div class="seguridad-subtitulo4">
            <h2>Detección de disparos</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\29.png">
            <p>
                Funciona para detectar <br>
				frecuencias y diferenciar entre un <br>
				ruido común y un disparo, <br>
				mandando una señal de alarma.
            </p>
        </div>

		<div class="seguridad-subtitulo3">
            <div class="vacio-sd"></div>
            <h2>Luces de señalización de vehiculos</h2>
        </div>
		<div class="derecha">
            <p>
                La más alta tecnología en sistemas de <br>
				señalización audiovisual para vehículos de emergencia.<br>
				Contamos con los mejores productos como <br>
				Torretas, Minitorretas, Estrobos, Sirenas, <br>
				Bocinas, Controladores, Barras para Interior, <br>
				Accesorios para Motocicletas y mucho más.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\30.png">
        </div>

    </div>
    -->
    <!--                            FIN SEGURIDAD                                  -->

    <!--                            INICIO COMUNICACION                            -->
    <!--
    <div id="comunicacion" class="comunicacion">
        
        <div class="comunicacion-titulo">
            <h1>Comunicación Unificada</h1>
        </div>

        <div class="comunicacion-subtitulo2">
            <h2>voceo ip</h2>
            <div class="vacio-cui"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\31.png">
            <p>
                Comunica anuncios, <br>
				notificaciones y alarmas con <br>
				una mayor fidelidad.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN COMUNICACION                               -->

    <!--                            INICIO LOGISTICA                               -->
    <!--
    <div id="logistica" class="logistica">

        <div class="logistica-titulo">
            <h1>Logística</h1>
        </div>

        <div class="logistica-subtitulo">
            <div class="vacio-ld"></div>
            <h2>Control de velocidad</h2>
        </div>
		<div class="derecha">
            <p>
                Vigila la velocidad del <br>
				transporte dentro de la <br>
				ciudad, reduciendo accidentes <br>
				automovilísticos.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\32.png">
        </div>

        <div class="logistica-subtitulo2">
            <h2>Control de flujo</h2>
            <div class="vacio-li"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\33.png">
            <p>
                Vigila que los automóviles sigan <br>
				el sentido de la calle y/o avenida, <br>
				en caso contrario, se manda una <br>
				alarma al centro de control.
            </p>
        </div>

        <div class="logistica-subtitulo3">
            <div class="vacio-ld"></div>
            <h2>Detección de tumultos</h2>
        </div>
		<div class="derecha">
            <p>
                Detecta cuando se aglomeran <br>
				personas en un lugar para así <br>
				poder evitar disturbios, tumultos, etc.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\34.png">
        </div>

    </div>
    -->
    <!--                            FIN LOGISTICA                                  -->

    <!--                            INICIO COLABORACION                            -->
    <!--
    <div id="colaboracion" class="colaboracion">

        <div class="colaboracion-titulo">
            <h1>Colaboración Remota</h1>
        </div>

        <div class="colaboracion-subtitulo">
            <h2>Sala de juntas</h2>
            <div class="vacio-cri"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\35.png">
            <p>
                Mejora la experiencia en <br>
                conferencias realizadas de <br>
                manera remota.
            </p>
        </div>

        <div class="colaboracion-subtitulo">
            <div class="vacio-crd"></div>
            <h2>Aulas interactivas</h2>
        </div>
		<div class="derecha">
            <p>
                Mejora la experiencia educativa <br>
				de los estudiantes, durante su aprendizaje.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\ciudades-inteligentes\36.png">
        </div>

    </div>
    -->
    <!--                            FIN COLABORACION                               -->

    <app-new-footer></app-new-footer>

</div>