<app-navegador-n></app-navegador-n>
<div class="base">
    <div class="video">
        <!--<video src="..\..\assets\Videos\video-home2.mp4" autoplay="true" loop="true" muted="true"></video>-->
        <video src="..\..\assets\Videos\cultura organizacional.mp4" autoplay="true" loop="true" muted="true"></video>
        <div class="fvideo">
            <h1>Cultura Organizacional</h1>
        </div>
    </div>
    <div class="tbell-world">
        <div class="tbell-world-title">
            <h1>Misión y Visión</h1>
        </div>
        <div class="tbell-world-img">
            <img src="..\..\assets\Imagenes\home\t-bell-world-font.png">
        </div>
    </div>
    <div class="mvv">
        <!--
        <div class="titulo">
            <h1>MISIÓN Y VISIÓN</h1>
            <div class="blanco1"></div>
        </div>
        -->
        <div class="mision">
            <div class="texto">
                <h1>MISIÓN</h1>
                <p>
                    Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional,
                    preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor,
                    distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano
                    y de la aplicación y administración de tecnología certificada por nuestros proveedores,
                    para permitirle a nuestros clientes lograr sus objetivos
                </p>
            </div>
            <!--
            <div class="blanco2">
                <img src="..\..\assets\Imagenes\nuevas\2.png" width="200px" height="200px">
            </div>
            -->
        </div>
        <div class="vision">
            <!--
            <div class="blanco3">
                <img src="..\..\assets\Imagenes\nuevas\3.png" width="200px" height="200px">
            </div>
            -->
            <div class="texto-vision">
                <h1>VISIÓN</h1>
                <p>
                    Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios.
                    Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades
                </p>
            </div>
        </div>

        <div class="parallax2">
            <div id="dentro2"></div>
        </div>

        <div class="valores">
            <!--<div class="blanco4"></div>-->
            <h1>VALORES</h1>
        </div>
        <div class="valores-grid">
            <div class="vertical1">
                <div>
                    <div class="img1">
                        <img src="..\..\assets\Imagenes\nuevas\valor-1.png" width="300px" height="200px">
                    </div>
                    <div>
                        <h2>Responsabilidad</h2>
                        <p>
                            Somos responsables al cumplir con los compromisos y deberes, tanto en el trabajo como en la sociedad.
                        </p>
                    </div>
                </div>
                <div>
                    <img src="..\..\assets\Imagenes\nuevas\valor-5.png" width="300px" height="200px">
                    <h2>Respeto</h2>
                    <p>
                        Reconocemos que todas las personas dentro y fuera de la empresa deben ser tratadas sin distinción.
                    </p>
                </div>
            </div>
            <div class="vertical2">
                <div>
                    <img src="..\..\assets\Imagenes\nuevas\valor-2.png" width="300px" height="200px">
                    <h2>Disciplina</h2>
                    <p>
                        Respetamos las políticas y reglamentos de nuestra empresa (y la de nuestros clientes).
                    </p>
                </div>
                <div>
                    <img src="..\..\assets\Imagenes\nuevas\valor-3.png" width="300px" height="200px">
                    <h2>Trabajo en equipo, actitud de servicio y calidad</h2>
                    <p>
                        Son otros de los valores que nos distinguen.
                    </p>
                </div>
            </div>
            <div class="vertical3">
                <div>
                    <img src="..\..\assets\Imagenes\nuevas\valor-4.png" width="300px" height="200px">
                    <h2>Honestidad</h2>
                    <p>
                        Es un compromiso actuar con honestidad a la hora de trabajar y otorgar el servicio a nuestros clientes
                        y ser congruentes con nuestros hechos y palabras.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <app-new-footer></app-new-footer>
</div>