<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Institutions</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones1.jpg" alt="Instituciones">
    </div>
    <div class="texto">
        <h2>Educational institutions</h2>
        <p>
            Link of different university campuses by managing point-to-point or point multipoint communications through high-capacity links. Line of sight studies and installation of braced or self-supported towers are included. Greater efficiency in educational, research and scientific dissemination processes by increasing the output capacity to the World Wide Web.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <h2>
            Scientific Institutions
        </h2>
        <p>
            Speed and security in the handling of information from the world's largest Millimeter Radio Telescope, through the installation of IP Telecommunications network infrastructure.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones2.jpg" alt="Instituciones">
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones3.jpg" alt="Instituciones">
    </div>
    <div class="texto">
        <h2>
            Educational and Health Institutions
        </h2>
        <p>
            Extension of the telecommunications network to high schools, hospitals and campuses in other cities through high capacity microwave links, point-point or point-multipoint. We carry out from line of sight studies, to the installation of braced or self-supporting towers and microwave equipment.
        </p>
    </div>
</div>
<app-footer-page></app-footer-page>