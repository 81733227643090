<app-navegador-wb></app-navegador-wb>
<div class="base">

	<!--                            INICIO VIDEO                                   -->
    <div class="video">
        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\EDUCACION A.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\EDUCACION B.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\EDUCACION C.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div>
        <div class="tit">
            <h1>Educación</h1>
        </div>
    </div>
	<!--                            FIN VIDEO                                      -->


	<!--                            INICIO CIRCULOS                                -->
	<div class="circulos">
        <a href="#infraestructura">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (11).png">
        </a>
        <a href="#seguridad">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (1).png">
        </a>
        <a href="#comunicacion">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (2).png">
        </a>
        <p>
            <img src="..\..\assets\Imagenes\nuevas\vacio.png">
        </p>
        <p>
            <img src="..\..\assets\Imagenes\nuevas\vacio.png">
        </p>
    </div>
	<!--                           FIN CIRCULOS                                    -->

    <!--                           INICIO CARRUSEL                                 -->
    <div class="titulo-infraestructura" id="infraestructura">
        <h1>infraestructura de telecomunicaciones</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-infraestructura-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-1.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>MDF / IDF</h1>
                            <p>
                                Ayuda a extender el alcance de las <br>
                                comunicaciones entre equipos, voz <br>
                                y datos (LAN), Manteniendo la <br>
                                estetica del lugar.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-2.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Cableado de cobre / fibra</h1>
                            <p>
                                Ayuda a tener una mejor consistencia <br>
                                en cuanto a la infraestructura de la <br>
                                comunicación de datos, abarcando una <br>
                                mayor distancia en un menor tiempo.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR INFRAESTRUCTURA-->

    <div class="titulo-seg-elec" id="seguridad">
        <h1>Seguridad Electrónica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-seguridad-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-3.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>CCTV y Voceo</h1>
                            <p>
                                Circuito cerrado de televisión <br>
                                (Servidor, VMS, Cámaras). Pueden tener <br>
                                integrados altavoces que sirven para <br>
                                dar alertas en caso de contingencias, <br>
                                dar anuncios, hacer sonar alarmas <br>
                                y/o advertencias.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-4.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de acceso</h1>
                            <p>
                                Sistema que permite o restringe el <br>
                                acceso a un área determinada. <br>
                                Ayuda a controlar los accesos a <br>
                                zonas restringidas o donde el <br>
                                personal sea limitado
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-5.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Seguridad perimetral</h1>
                            <p>
                                Mantiene seguros los alrededores <br>
                                de sus instalaciones a través de <br>
                                cámaras de merodeo, geo cercas, etc.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-6.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Lectores Biométricos</h1>
                            <p>
                                Autentica la identidad de una <br>
                                persona a través de lectores de <br>
                                huella, faciales O RETINA <br>
                                maximizando la seguridad del <br>
                                área.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR SEGURIDAD-->

    <div class="titulo-com-uni" id="comunicacion">
        <h1>Comunicación unificada</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-comunicacion-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-7.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Postes de emergencia</h1>
                            <p>
                                Comunica anuncios, <br>
                                notificaciones y alarmas con <br>
                                una mayor fidelidad.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-comunicacion-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\educacion\educacion-8.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Salones de clases virtuales</h1>
                            <p>
                                Imparta clases a distancia, con salones <br>
                                debidamente equipados para asistencia <br>
                                presencial y virtual.<br>
                                Audio, Video, Pizarrones interactivos... <br>
                                Todo lo necesario para que sus clases <br>
                                sean un éxito.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COMUNICACION-->

    <div class="espacio-footer"></div>

    <!--                           FIN CARRUSEL                                    -->


	<!--                            INICIO INFRAESTRUCTURA                         -->
    <!--
	<div id="infraestructura" class="infraestructura">
		<div class="infraestructura-titulo">
            <h1>infraestructura de telecomunicaciones</h1>
        </div>
        -->

        <!--
        <div class="col-carousel-wrapper">
            
            <div class="row-img-coll">

                <div class="c1">
                    <div class="info">
                        <img src="..\..\assets\Imagenes\nuevas\educacion\36.png">
                        <h2>MDF / IDF</h2>
                        <p>
                            Ayuda a extender el alcance de las <br>
				            comunicaciones entre equipos, voz <br>
				            y datos (LAN), Manteniendo la <br>
				            estetica del lugar.
                        </p>
                    </div>
                </div>

            </div>

        </div>
        -->
        <!--
        <div class="infraestructura-subtitulo2">
            <div class="vacio2i"></div>
            <h2>MDF / IDF</h2>
        </div>
		<div class="derecha">
            <p>
                Ayuda a extender el alcance de las <br>
				comunicaciones entre equipos, voz <br>
				y datos (LAN), Manteniendo la <br>
				estetica del lugar.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\educacion\36.png">
        </div>

        <div class="infraestructura-subtitulo3">
            <h2>Cableado de cobre / fibra</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\educacion\37.png">
            <p>
                Ayuda a tener una mejor consistencia <br>
				en cuanto a la infraestructura de la <br>
				comunicación de datos, abarcando una <br>
				mayor distancia en un menor tiempo.
            </p>
        </div>

	</div>-->
	<!--                            FIN INFRAESTRUCTURA                            -->

    <!--                            INICIO SEGURIDAD                               -->
    <!--
    <div id="seguridad" class="seguridad">

        <div class="seguridad-titulo">
            <h1>Seguridad Electrónica</h1>
        </div>

		<div class="seguridad-subtitulo2">
            <div class="vacio-sd"></div>
            <h2>CCTV y Voceo</h2>
        </div>
		<div class="derecha">
            <p>
                Circuito cerrado de televisión <br>
                (Servidor, VMS, Cámaras). Pueden tener <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas <br>
                y/o advertencias.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\educacion\38.png">
        </div>

        <div class="seguridad-subtitulo3">
            <h2>Control de acceso</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\educacion\39.png">
            <p>
                Sistema que permite o restringe el <br>
                acceso a un área determinada. <br>
                Ayuda a controlar los accesos a <br>
                zonas restringidas o donde el <br>
                personal sea limitado
            </p>
        </div>

		<div class="seguridad-subtitulo4">
            <div class="vacio-sd"></div>
            <h2>Seguridad Perimetral</h2>
        </div>
		<div class="derecha">
            <p>
                Mantiene seguros los alrededores <br>
				de sus instalaciones a través de <br>
				cámaras de merodeo, geo cercas, etc.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\educacion\40.png">
        </div>

        <div class="seguridad-subtitulo5">
            <h2>Lectores biométricos</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\educacion\41.png">
            <p>
                Autentica la identidad de una <br>
                persona a través de lectores de <br>
                huella, faciales O RETINA <br>
                maximizando la seguridad del <br>
                área.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN SEGURIDAD                                  -->

    <!--                            INICIO COMUNICACION                            -->
    <!--
    <div id="comunicacion" class="comunicacion">
        
        <div class="comunicacion-titulo">
            <h1>Comunicación Unificada</h1>
        </div>

        <div class="comunicacion-subtitulo">
            <div class="vacio-cud"></div>
            <h2>Postes de emergencia</h2>
        </div>
		<div class="derecha">
            <p>
                Comunica anuncios, <br>
				notificaciones y alarmas con <br>
				una mayor fidelidad.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\educacion\42.png">
        </div>

        <div class="comunicacion-subtitulo2">
            <h2>Salones de clases virtuales</h2>
            <div class="vacio-cui"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\educacion\43.png">
            <p>
                Imparta clases a distancia, con salones <br>
                debidamente equipados para asistencia <br>
                presencial y virtual.<br>
                Audio, Video, Pizarrones interactivos... <br>
                Todo lo necesario para que sus clases <br>
                sean un éxito.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN COMUNICACION                               -->

    <app-new-footer></app-new-footer>

</div>