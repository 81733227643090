import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ss-business-services',
  templateUrl: './ss-business-services.component.html',
  styleUrls: ['./ss-business-services.component.css']
})
export class SsBusinessServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
