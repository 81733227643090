import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-critical-infrastructure-telecomm-infrastructure',
  templateUrl: './critical-infrastructure-telecomm-infrastructure.component.html',
  styleUrls: ['./critical-infrastructure-telecomm-infrastructure.component.css']
})
export class CriticalInfrastructureTelecommInfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
