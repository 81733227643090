<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Automotive Industry</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz1.jpg" alt="">
    </div>
    <div class="texto">
        <p>
            IP security camera and access control support system for C-TPAT (Customs-Trade Partnership Against Terrorism) certification.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Accident prevention, higher productivity and quality control in the tire industry, all this through a CCTV system and environmental condition sensors. All over a Certified Cat 6 IP network.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz2.png" alt="">
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz3.jpg" alt="">
    </div>
    <div class="texto-b">
        <p>
            Greater efficiency, security and productivity through a copper and fiber optic IP network that transmits voice and data as well as video from the security camera system.
        </p>
    </div>
</div>
<app-footer-page></app-footer-page>