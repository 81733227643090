import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-unified-comm',
  templateUrl: './education-unified-comm.component.html',
  styleUrls: ['./education-unified-comm.component.css']
})
export class EducationUnifiedCommComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
