import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qh-seguridad',
  templateUrl: './qh-seguridad.component.html',
  styleUrls: ['./qh-seguridad.component.css']
})
export class QhSeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
