<app-navegador-n></app-navegador-n>
<div id="escuelas-comunicacion">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-educacion">Educación</a> - <a href="#/e-seguridad">Seguridad</a> < <a href="#/e-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Educación</h1>
        </div>
        <div>
            <div>
                <h3>Comunicaciones Unificadas</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Conmutadores IP</h3>
                <p>
                    Sistema para la red telefónica, enfocado a interconectar sus comunicaciones internas y externas.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Voceo IP</h3>
                <p>
                    Comunica anuncios, notificaciones y alarmas con una mayor fidelidad.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Postes de Emergencia</h3>
                <p>
                    Permite que los estudiantes y personal notifiquen al centro de monitoreo cualquier incidencia.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Salones de clases virtuales</h3>
                <p>
                    Imparta clases a distancia, con salones debidamente equipados para asistencia presencial y virtual. <br>
                    Audio, Video, Pizarrones interactivos... Todo lo necesario para que sus clases sean un éxito.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>