<!--
<app-navegador></app-navegador>
<div id="cuerpo">
	<div id="titulo">
		<h1>Infraestructura Critica</h1>	
	</div>
</div>
-->
<app-navegador-wb></app-navegador-wb>
<div class="base">

	<!--                            INICIO VIDEO                                   -->
    <div class="video">
        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\INFRA. CRIT. A.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INFRA. CRIT.  B.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INFRA. CRIT. C.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div>
        <div class="tit">
            <h1>Infraestructura Crítica</h1>
        </div>
    </div>
	<!--                            FIN VIDEO                                      -->


	<!--                            INICIO CIRCULOS                                -->
	<div class="circulos">
        <a href="#infraestructura">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (11).png">
        </a>
        <a href="#seguridad">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (1).png">
        </a>
        <a href="#comunicacion">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (2).png">
        </a>
        <p>
			<img src="..\..\assets\Imagenes\nuevas\vacio.png">
		</p>
        <p>
			<img src="..\..\assets\Imagenes\nuevas\vacio.png">
		</p>
    </div>
	<!--                           FIN CIRCULOS                                    -->



    <div class="titulo-infraestructura" id="infraestructura">
        <h1>infraestructura de telecomunicaciones</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-infraestructura-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-01.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Data center</h1>
                            <p>
                                Almacena una gran cantidad de datos <br>
                                dentro de la organización que pueden <br>
                                ser críticos para los servicios.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-02.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>MDF / IDF</h1>
                            <p>
                                Ayuda a extender el alcance de las <br>
                                comunicaciones entre equipos, voz <br>
                                y datos (LAN), Manteniendo la <br>
                                estetica del lugar.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-03.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Cableado de cobre / fibra</h1>
                            <p>
                                Ayuda a tener una mejor consistencia <br>
                                en cuanto a la infraestructura de la <br>
                                comunicación de datos, abarcando una <br>
                                mayor distancia en un menor tiempo.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-04.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Microondas banda E</h1>
                            <p>
                                Intercomunica hasta las redes <br>
                                de más difícil acceso, de manera <br>
                                inalámbrica teniendo un mayor <br>
                                alcance y estabilidad.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR INFRAESTRUCTURA-->

    <div class="titulo-seg-elec" id="seguridad">
        <h1>Seguridad Electrónica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-seguridad-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-05.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>CCTV y Voceo</h1>
                            <p>
                                Circuito cerrado de televisión <br>
                                (servidor, VMS, cámaras) para la <br>
                                vigilancia de algún punto. Pueden estar <br>
                                integrados altavoces que sirven para <br>
                                dar alertas en caso de contingencias, <br>
                                dar anuncios, hacer sonar alarmas y/o <br>
                                advertencias.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-06.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Radar</h1>
                            <p>
                                Detecte intrusiones de manera <br>
                                efectiva y haga que el sistema de CCTV <br>
                                graben los sucesos importantes.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-07.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de acceso</h1>
                            <p>
                                Sistema que permite o restringe el <br>
                                acceso a un área determinada. <br>
                                Ayuda a controlar los accesos a <br>
                                zonas restringidas o donde el <br>
                                personal sea limitado
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-08.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Seguridad Perimetral</h1>
                            <p>
                                Mantiene seguros los alrededores <br>
                                de sus instalaciones a través de <br>
                                cámaras de merodeo, geo cercas, etc.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-5">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-09.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Lectores Biométricos</h1>
                            <p>
                                Autentica la identidad de una <br>
                                persona a través de lectores de <br>
                                huella o faciales, maximizando la <br>
                                seguridad del área.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR SEGURIDAD-->

    <div class="titulo-com-uni" id="comunicacion">
        <h1>Comunicación unificada</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-comunicacion-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\critica-10.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Voceo IP</h1>
                            <p>
                                Comunica anuncios, <br>
                                notificaciones y alarmas con <br>
                                una mayor fidelidad.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COMUNICACION-->

    <div class="espacio-footer"></div>


	<!--                            INICIO INFRAESTRUCTURA                         -->
    <!--
	<div id="infraestructura" class="infraestructura">
		<div class="infraestructura-titulo">
            <h1>infraestructura de telecomunicaciones</h1>
        </div>
        <div class="infraestructura-subtitulo">
            <h2>Data Center</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\23.png">
            <p>
                Almacena una gran cantidad de datos <br>
				dentro de la organización que pueden <br>
				ser críticos para los servicios.
            </p>
        </div>

        <div class="infraestructura-subtitulo2">
            <div class="vacio2i"></div>
            <h2>MDF / IDF</h2>
        </div>
		<div class="derecha">
            <p>
                Ayuda a extender el alcance de las <br>
				comunicaciones entre equipos, voz <br>
				y datos (LAN), Manteniendo la <br>
				estetica del lugar.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\24.png">
        </div>

        <div class="infraestructura-subtitulo3">
            <h2>Cableado de cobre / fibra</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\25.png">
            <p>
                Ayuda a tener una mejor consistencia <br>
				en cuanto a la infraestructura de la <br>
				comunicación de datos, abarcando una <br>
				mayor distancia en un menor tiempo.
            </p>
        </div>

        <div class="infraestructura-subtitulo4">
            <div class="vacio2i"></div>
            <h2>Microondas banda E</h2>
        </div>
		<div class="derecha">
            <p>
                Intercomunica hasta las redes <br>
				de más difícil acceso, de manera <br>
				inalámbrica teniendo un mayor <br>
				alcance y estabilidad.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\26.png">
        </div>

	</div>
    -->
	<!--                            FIN INFRAESTRUCTURA                            -->

    <!--                            INICIO SEGURIDAD                               -->
    <!--
    <div id="seguridad" class="seguridad">

        <div class="seguridad-titulo">
            <h1>Seguridad Electrónica</h1>
        </div>

        <div class="seguridad-subtitulo">
            <h2>CCTV y Voceo</h2>
			<div class="vacio-si"></div>
        </div>
		<div class="izquierda">
			<img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\27.png">
            <p>
                Circuito cerrado de televisión <br>
                (Servidor, VMS, Cámaras). Pueden tener <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas <br>
                y/o advertencias.
            </p>
        </div>

		<div class="seguridad-subtitulo2">
            <div class="vacio-sd"></div>
            <h2>Radar</h2>
        </div>
		<div class="derecha">
            <p>
                Detecte intrusiones de manera <br>
				efectiva y haga que el sistema de CCTV <br>
				graben los sucesos importantes.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\28.png">
        </div>

        <div class="seguridad-subtitulo3">
            <h2>Control de acceso</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\29.png">
            <p>
                Sistema que permite o restringe el <br>
                acceso a un área determinada. <br>
                Ayuda a controlar los accesos a <br>
                zonas restringidas o donde el <br>
                personal sea limitado
            </p>
        </div>

		<div class="seguridad-subtitulo4">
            <div class="vacio-sd"></div>
            <h2>Seguridad Perimetral</h2>
        </div>
		<div class="derecha">
            <p>
                Mantiene seguros los alrededores <br>
				de sus instalaciones a través de <br>
				cámaras de merodeo, geo cercas, etc.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\30.png">
        </div>

        <div class="seguridad-subtitulo5">
            <h2>Lectores biométricos</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\31.png">
            <p>
                Autentica la identidad de una <br>
                persona a través de lectores de <br>
                huella, faciales O RETINA <br>
                maximizando la seguridad del <br>
                área.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN SEGURIDAD                                  -->

    <!--                            INICIO COMUNICACION                            -->
    <!--
    <div id="comunicacion" class="comunicacion">
        
        <div class="comunicacion-titulo">
            <h1>Comunicación Unificada</h1>
        </div>

        <div class="comunicacion-subtitulo">
            <div class="vacio-cud"></div>
            <h2>voceo ip</h2>
        </div>
		<div class="derecha">
            <p>
                Comunica anuncios, <br>
				notificaciones y alarmas con <br>
				una mayor fidelidad.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\infraestructura-critica\32.png">
        </div>

    </div>
    -->
    <!--                            FIN COMUNICACION                               -->

    <app-new-footer></app-new-footer>

</div>