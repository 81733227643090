<div class="base">
    <div class="menu1080">
        <div class="contenedor1080">
            <div class="logo1080">
                <a href="#/home" class="logo" aria-label="logo">
                    <img class="logo-img" src="../../assets/Imagenes/logostbell/T-Bell-2016-Guinda-png.png" alt="logo">
                </a>
            </div>

            <div class="enlaces1080">
                <ul class="ul1080">
                    <li><a href="#/principal">Inicio</a></li>
                    <li><a href="#/soluciones-y-productos">Soluciones y Productos</a></li>
                    <li><a href="#/donde-trabajamos">Donde Trabajamos</a></li>
                    <!--
                    <li id="ul-que"><a href="#/quehacemos">Que Hacemos </a>
                        <i class="fas fa-caret-down"></i>
                        <ul class="sub-ul1080">
                            <li id="li-sub1"><a href="#/infraestructuradetelecom">Infraestructura de Telecom.</a>
                                <ul class="subsub1">
                                    <li><a href="#/i-infraestructura">Industria</a></li>
                                    <li><a href="#/ic-infraestructura">Infraestructura Critica</a></li>
                                    <li><a href="#/ci-infraestructura">Ciudades Inteligentes</a></li>
                                    <li><a href="#/c-infraestructura">Corporativos</a></li>
                                    <li><a href="#/e-infraestructura">Educación</a></li>
                                </ul>
                            </li>
                            <li id="li-sub2"><a href="#/seguridadelectronica">Seguridad Electronica</a>
                                <ul class="subsub2">
                                    <li><a href="#/i-seguridad">Industria</a></li>
                                    <li><a href="#/ic-seguridad">Infraestructura Critica</a></li>
                                    <li><a href="#/ci-seguridad">Ciudades Inteligentes</a></li>
                                    <li><a href="#/c-seguridad">Corporativos</a></li>
                                    <li><a href="#/e-seguridad">Educación</a></li>
                                </ul>
                            </li>
                            <li id="li-sub3"><a href="#/comunicacionesunificadas">Comunicaciones Unificadas</a>
                                <ul class="subsub3">
                                    <li><a href="#/i-com-uni">Industria</a></li>
                                    <li><a href="#/ic-com-uni">Infraestructura Critica</a></li>
                                    <li><a href="#/ci-com-uni">Ciudades Inteligentes</a></li>
                                    <li><a href="#/c-com-uni">Corporativos</a></li>
                                    <li><a href="#/e-com-uni">Educación</a></li>
                                </ul>
                            </li>
                            <li id="li-sub4"><a href="#/logistica">Logistica</a>
                                <ul class="subsub4">
                                    <li><a href="#/i-logistica">Industria</a></li>
                                    <li><a href="#/ci-logistica">Ciudades Inteligentes</a></li>
                                </ul>
                            </li>
                            <li id="li-sub5"><a href="#/colaboracionremota">Colaboración Remota</a>
                                <ul class="subsub5">
                                    <li><a href="#/i-colab-remota">Industria</a></li>
                                    <li><a href="#/ci-colab-remota">Ciudades Inteligentes</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li id="ul-donde"><a href="#/donde-trabajamos">Donde Trabajamos </a>
                        <i class="fas fa-caret-down"></i>
                        <ul class="dub-ul1080">
                            <li id="li-dub1"><a href="#/corporativos">Corporativos</a>
                                <ul class="dubdub1">
                                    <li><a href="#/c-infraestructura">Infraestructura de Telecom.</a></li>
                                    <li><a href="#/c-seguridad">Seguridad Electronica</a></li>
                                    <li><a href="#/c-com-uni">Comunicaciones Unificadas</a></li>
                                </ul>
                            </li>
                            <li id="li-dub2"><a href="#/industria">Industria</a>
                                <ul class="dubdub2">
                                    <li><a href="#/i-infraestructura">Infraestructura de Telecom.</a></li>
                                    <li><a href="#/i-seguridad">Seguridad Electronica</a></li>
                                    <li><a href="#/i-com-uni">Comunicaciones Unificadas</a></li>
                                    <li><a href="#/i-logistica">Logistica</a></li>
                                    <li><a href="#/i-colab-remota">Colaboración Remota</a></li>
                                </ul>
                            </li>
                            <li id="li-dub3"><a href="#/infraestructuracritica">Infraestructura Critica</a>
                                <ul class="dubdub3">
                                    <li><a href="#/ic-infraestructura">Infraestructura de Telecom.</a></li>
                                    <li><a href="#/ic-seguridad">Seguridad Electronica</a></li>
                                    <li><a href="#/ic-com-uni">Comunicaciones Unificadas</a></li>
                                </ul>
                            </li>
                            <li id="li-dub4"><a href="#/ciudadesinteligentes">Ciudades Inteligentes</a>
                                <ul class="dubdub4">
                                    <li><a href="#/ci-infraestructura">Infraestructura de Telecom.</a></li>
                                    <li><a href="#/ci-seguridad">Seguridad Electronica</a></li>
                                    <li><a href="#/ci-com-uni">Comunicaciones Unificadas</a></li>
                                    <li><a href="#/ci-logistica">Logistica</a></li>
                                    <li><a href="#/ci-colab-remota">Colaboración Remota</a></li>
                                </ul>
                            </li>
                            <li id="li-dub5"><a href="#/educacion">Educación</a>
                                <ul class="dubdub5">
                                    <li><a href="#/e-infraestructura">Infraestructura de Telecom.</a></li>
                                    <li><a href="#/e-seguridad">Seguridad Electronica</a></li>
                                    <li><a href="#/e-com-uni">Comunicaciones Unificadas</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    -->
                    <li><a href="#/casos-de-exito">Casos de Exito</a></li>
                    <li><a href="#/blog">Blog</a></li>
                    <li><a href="#/contacto">Contacto</a></li>
                </ul>
            </div>
            <div class="tienda1080">
                <button>
                    <a class="btn-menu-shop" id="btn-menu-shop" href="https://t-bell.mercadoshops.com.mx/" target="_blank">
                        <i class="fas fa-shopping-cart"></i>
                    </a>
                </button>
                <ul class="i1080ul">
                    <li>
                        <i class="fas fa-globe-americas"></i>
                        <ul>
                            <li><a href="#/principal">MX</a></li>
                            <li><a href="#/home">ENG</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <nav class="menu" id="menu">
        <div class="contenedor contenedor-botones-menu">
            <button class="btn-menu-barras" id="btn-menu-barras">
                <i class="fas fa-bars"></i>
            </button>
            <a href="#/principal" class="logo nav-link" aria-label="logo">
                <img class="logo-img" src="../../assets/Imagenes/logostbell/T-Bell-2016-Guinda-png.png" alt="logo">
            </a>
            <button>
                <a class="btn-menu-shop" id="btn-menu-shop" href="https://t-bell.mercadoshops.com.mx/" target="_blank">
                    <i class="fas fa-shopping-cart"></i>
                </a>
            </button>
        </div>
        <div class="contenedor-enlaces-nav">
            <button id="btn-menu-cerrar" class="btn-menu-cerrar">
                <i class="fas fa-times"></i>
            </button>
            <div class="enlaces">
                <a href="#/principal">Inicio</a>
                <a href="#/soluciones-y-productos">Soluciones y Productos</a>
                <a href="#/donde-trabajamos">Donde trabajamos</a>
                <a href="#/casos-de-exito">Casos de exito</a>
                <a href="#/blog">Blog</a>
                <a href="#/contacto">Contacto</a>
            </div>
            <!--
            <div class="btn-botones" id="btn-hacemos">
                <p>Que hacemos</p>
                <i class="fas fa-caret-right"></i>
            </div>
            <div class="btn-botones" id="btn-donde">
                <p>Donde trabajamos</p>
                <i class="fas fa-caret-right"></i>
            </div>
            <div class="enlaces">
                <a href="#/casos-de-exito">Casos de exito</a>
                <a href="#/blog">Blog</a>
                <a href="#/contacto">Contacto</a>
            </div>
            <div class="btn-botones" id="btn-idioma">
                <p>Idioma</p>
                <i class="fas fa-caret-right"></i>
            </div>
            -->
        </div>
        <div class="contenedor contenedor-grid">
            <div class="grid" id="grid">
                <div class="categorias">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Regresar
                    </button>
                    <h3 class="subtitulo">Donde trabajamos</h3>
                    <a href="#/corporativos" data-categoria="corporativos" id="a-corporativo">
                        Corporativos <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subcorporativos" data-categoria="corporativos" id="ir-corporativos">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Corporativos</h3>
                            <a href="#/c-infraestructura" onclick="location.href">Infraestructura de Telecom.</a>
                            <a href="#/c-seguridad">Seguridad Electrónica</a>
                            <a href="#/c-com-uni">Comunicaciones Unificadas</a>
                        </div>
                    </div>
                    <a href="#/industria" data-categoria="industria" id="a-industria">
                        Industria <i class="fas fa-angle-right"></i>                            
                    </a>
                    <div class="subindustria" data-categoria="industria" id="ir-industria">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Industria</h3>
                            <a href="#/i-infraestructura">Infraestructura de Telecom.</a>
                            <a href="#/i-seguridad">Seguridad Electrónica</a>
                            <a href="#/i-com-uni">Comunicaciones Unificadas</a>
                            <a href="#/i-logistica">Logística</a>
                            <a href="#/i-colab-remota">Colaboración Remota</a>
                        </div>
                    </div>
                    <a href="#/infraestructuracritica" data-categoria="infraestructura-critica" id="a-critica">
                        Infraestructura Critica <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subcritica" data-categoria="infraestructura-critica" id="ir-critica">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Infraestructura Critica</h3>
                            <a href="#/ic-infraestructura">Infraestructura de Telecom.</a>
                            <a href="#/ic-seguridad">Seguridad Electrónica</a>
                            <a href="#/ic-com-uni">Comunicaciones Unificadas</a>
                        </div>
                    </div>
                    <a href="#/ciudadesinteligentes" data-categoria="ciudades-inteligentes" id="a-ciudades">
                        Ciudades Inteligentes <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subciudades" data-categoria="ciudades-inteligentes" id="ir-ciudades">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Ciudades Inteligentes</h3>
                            <a href="#/ci-infraestructura">Infraestructura de Telecom.</a>
                            <a href="#/ci-seguridad">Seguridad Electrónica</a>
                            <a href="#/ci-com-uni">Comunicaciones Unificadas</a>
                            <a href="#/ci-logistica">Logística</a>
                            <a href="#/ci-colab-remota">Colaboración Remota</a>
                        </div>
                    </div>
                    <a href="#educacion" data-categoria="educacion" id="a-educacion">
                        Educación <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subeducacion" data-categoria="educacion" id="ir-educacion">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Educación</h3>
                            <a href="#/e-infraestructura">Infraestructura de Telecom.</a>
                            <a href="#/e-seguridad">Seguridad Electrónica</a>
                            <a href="#/e-com-uni">Comunicaciones Unificadas</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="contenedor contenedor-idiomas">
            <div class="idiomas" id="idiomas">
                <div class="lista">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Regresar
                    </button>
                    <h3 class="subtitulo">Idioma</h3>
                    <a href="#/principal">
                        Español
                    </a>
                    <a href="#/home">
                        English
                    </a>
                </div>
            </div>
        </div>

        <div class="contenedor contenedor-que">
            <div class="que" id="que">
                <div class="que-hacemos">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Regresar
                    </button>
                    <h3 class="subtitulo">Que hacemos</h3>
                    <a href="#/infraestructuradetelecom" id="a-infra-telecom">
                        Infraestructura de Telecom. <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/seguridadelectronica" id="a-seguridad">
                        Seguridad Electrónica <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/comunicacionesunificadas" id="a-comunicaciones">
                        Comunicaciones Unificadas <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/logistica" id="a-logistica">
                        Logística <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/colaboracionremota" id="a-colaboracion">
                        Colaboración Remota <i class="fas fa-angle-right"></i>
                    </a>

                    <div class="queinfraestructura" id="ir-infra-telecom">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Infraestructura de Telecom.</h3>
                            <a href="#/c-infraestructura">Corporativos</a>
                            <a href="#/i-infraestructura">Industria</a>
                            <a href="#/ic-infraestructura">Infraestructura Critica</a>
                            <a href="#/ci-infraestructura">Ciudades Inteligentes</a>
                            <a href="#/e-infraestructura">Educación</a>
                        </div>
                    </div>
                    <div class="queseguridad" id="ir-seguridad">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Seguridad Electrónica</h3>
                            <a href="#/c-seguridad">Corporativos</a>
                            <a href="#/i-seguridad">Industria</a>
                            <a href="#/ic-seguridad">Infraestructura Critica</a>
                            <a href="#/ci-seguridad">Ciudades Inteligentes</a>
                            <a href="#/e-seguridad">Educación</a>
                        </div>
                    </div>
                    <div class="quecomunicaciones" id="ir-comunicaciones">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Comunicaciones Unificadas</h3>
                            <a [routerLink]="['/c-com-uni']">Corporativos</a>
                            <a [routerLink]="['/i-com-uni']">Industria</a>
                            <a [routerLink]="['/ic-com-uni']">Infraestructura Critica</a>
                            <a [routerLink]="['/ci-com-uni']">Ciudades Inteligentes</a>
                            <a [routerLink]="['/e-com-uni']">Educación</a>
                        </div>
                    </div>
                    <div class="quelogistica" id="ir-logistica">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Logística</h3>
                            <a [routerLink]="['/i-logistica']">Industria</a>
                            <a [routerLink]="['/ci-logistica']">Ciudades Inteligentes</a>
                        </div>
                    </div>
                    <div class="quecolaboracion" id="ir-colaboracion">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Regresar
                            </button>
                            <h3 class="subtitulo">Colaboración Remota</h3>
                            <a [routerLink]="['/i-colab-remota']">Industria</a>
                            <a [routerLink]="['/ci-colab-remota']">Ciudades Inteligentes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </nav>
    <div class="linea-divisora"></div>
</div>