<app-navegador-n></app-navegador-n>
<div id="pagina-principal">
    <div id="cuerpo">
        <!--
        <div class="parallax">
            <div id="dentro">
                <img id="logo" src="..\..\assets\Imagenes\logostbell\T-Bell-2016-Guinda-png.png">
            </div>
        </div>FIN DIV PARALLAX-->
        <div class="video">
            <video src="..\..\assets\Videos\vino.mp4" autoplay="true" loop="true" muted="true"></video>
            <h1>En T-Bell somos expertos en el mundo de <br> de las comunicaciones inteligentes</h1>
            <!--
            <a href="">
                <div id="boton-conoce-mas">
                    CONOCE MÁS
                </div>
            </a>
            -->
        </div>
        <!--<app-slider></app-slider>-->
        <div id="quienes-somos">
            <h1>¿Quiénes Somos?</h1>
            <p>
                T -Bell Telecom es una empresa dedicada al desarrollo, implementación y soporte de soluciones <BR>
                    integrales de telecomunicaciones, redes de voz y datos, seguridad y tecnologías de información; <br>
                    integrando profesionales expertos en cada una de estas áreas
            </p>
            <!--<iframe width="560" height="315" src="https://www.youtube.com/embed/ISFqDnorrkE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
            <div class="img-tbell">
                <img src="../../assets/Imagenes/T-Bell-22.jpg" id="t-bell-foto">
            </div>

            <!-- <h1>En Donde Trabajamos</h1> -->
            <h1>Donde Hemos Trabajado</h1>
            <div class="img-mapa">
                <img src="..\..\assets\Imagenes\misc\mapa-clientes.jpeg" id="img-mapa">
            </div>

            <div class="parallax2">
                <div id="dentro2">
                </div>
            </div>

            <div class="espacio-certi"></div>
            <h1>Certificaciones</h1>
            <app-slider></app-slider>
            <!--
            <div id="certificaciones">
                <div id="certi">
                <div id="linea1">
                    <div id="div-axis">
                        <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                    </div>
                    <div id="div-dahua">
                        <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                    </div>
                    <div id="div-hikvision">
                        <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                    </div>
                    <div id="div-milestone">
                        <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                    </div>
                    <div id="div-lenel">
                        <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                    </div>
                    <div id="div-radwin">
                        <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                    </div>
                    <div id="div-tripp-lite">
                        <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                    </div>
                </div>
                </div>
                
            </div>
        -->
            <div class="cuadro">
                <div class="cuadro-info">
                    <div>
                        <img src="..\..\assets\Imagenes\home\t-bell-home.png" width="300px">
                        <div>
                            <i class="fas fa-caret-down"></i>
                            <h2>CULTURA ORGANIZACIONAL</h2>
                            <a class="leermas" [routerLink]="['/cultura-organizacional']">Leer más</a>
                        </div>
                    </div>
                    <div>
                        <img src="..\..\assets\Imagenes\home\certificate-home.png" width="300px">
                        <div>
                            <i class="fas fa-caret-down"></i>
                            <h2>CERTIFICADOS Y SEGURIDAD INDUSTRIAL</h2>
                            <a class="leermas" [routerLink]="['/certificados-y-seguridad-industrial']">Leer más</a>
                        </div>
                    </div>
                    <div>
                        <img src="..\..\assets\Imagenes\home\star-home.png" width="300px">
                        <div>
                            <i class="fas fa-caret-down"></i>
                            <h2>CASOS DE EXITO</h2>
                            <a class="leermas" [routerLink]="['/casos-de-exito']">Leer más</a>
                        </div>
                    </div>
                    <div>
                        <img src="..\..\assets\Imagenes\home\blog-home.png" width="300px">
                        <div>
                            <i class="fas fa-caret-down"></i>
                            <h2>BLOG/ARTICULOS</h2>
                            <a class="leermas" [routerLink]="['/blog']">Leer más</a>
                        </div>
                    </div>
                </div>
            </div>

            <!--<h1>EN DONDE TRABAJAMOS</h1>
            <img src="..\..\assets\Imagenes\misc\mapa-clientes.jpeg" id="img-mapa">-->

            <!--
            <p>
                T-Bell Telecom es una empresa dedicada al desarrollo, implementación y soporte de soluciones integrales
                de telecomunicaciones, redes de voz y datos, seguridad y tecnologías de información; integrando profesionales
                expertos en cada una de estas áreas
            </p>
            <div id="quso">
                <div id="quso-mi">
                    <h2>Misión</h2>
                    <p>
                        Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional,
                    preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor,
                    distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano
                    y de la aplicación y administración de tecnología certificada por nuestros proveedores,
                    para permitirle a nuestros clientes lograr sus objetivos
                    </p>
                </div>
                <div id="quso-vi">
                    <h2>Visión</h2>
                    <p>Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios.
                        Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades
                    </p>
                </div>
                <div>
                    <h2>Valores</h2>
                    <h3>Responsabilidad:</h3>
                    <p>
                        Somos responsables al cumplir con los compromisos y deberes, tanto en el trabajo como con la sociedad
                    </p>
                    <h3>Disciplina:</h3>
                    <p>
                        Respetamos las políticas y reglamentos de nuestra empresa (y la de nuestros clientes)
                    </p>
                    <h3>Honestidad:</h3>
                    <p>
                        Es un compromiso actuar con honestidad a la hora de trabajar y otorgar el servicio a nuestros clientes y ser congruentes con nuestros hechos y palabras.
                    </p>
                    <h3>Respeto:</h3>
                    <p>
                        Reconocemos que todas las personas dentro y fuera de la empresa deben ser tratadas sin distingo
                    </p>
                    <h3>Además</h3>
                    <ul>
                        <li>Trabajo en equipo</li>
                        <li>Actitud de Servicio</li>
                        <li>Calidad</li>
                    </ul>
                </div>
            </div>
            -->
        </div><!--CIERRE QUIENES SOMOS-->
        <!--
        <div class="parallax2">
            <div id="dentro2">
            </div>
        </div>
        
        <div id="certificaciones">
            <h1>Certificaciones</h1>
            <div id="certi">
            <div id="linea1">
                <div id="div-axis">
                    <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                </div>
                <div id="div-dahua">
                    <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                </div>
                <div id="div-hikvision">
                    <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                </div>
                <div id="div-milestone">
                    <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                </div>
                <div id="div-lenel">
                    <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                </div>
                <div id="div-radwin">
                    <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                </div>
                <div id="div-tripp-lite">
                    <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                </div>
            </div>
            <div id="linea2">
                <div id="div-panduit">
                    <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                </div>
                <div id="div-cisco">
                    <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                </div>
                <div id="div-extreme">
                    <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                </div>
                <div id="div-avaya">
                    <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
                </div>
                <div id="div-internel">
                    <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
                </div>
                <div id="div-siklu">
                    <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
                </div>
                <div id="div-total-ground">
                    <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                </div>
            </div>
            </div>

            <div id="certi2">
                <div id="line1">
                    <div id="div-axis">
                        <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                    </div>
                    <div id="div-dahua">
                        <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                    </div>
                </div>
                <div id="line2">
                    <div id="div-hikvision">
                        <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                    </div>
                    <div id="div-milestone">
                        <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                    </div>
                </div>
                <div id="line3">
                    <div id="div-lenel">
                        <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                    </div>
                    <div id="div-radwin">
                        <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                    </div>
                </div>
                <div id="line4">
                    <div id="div-tripp-lite">
                        <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                    </div>
                    <div id="div-panduit">
                        <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                    </div>
                </div>
                <div id="line5">
                    <div id="div-cisco">
                        <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                    </div>
                    <div id="div-extreme">
                        <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                    </div>
                </div>
                <div id="line6">
                    <div id="div-avaya">
                        <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
                    </div>
                    <div id="div-internel">
                        <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
                    </div>
                </div>
                <div id="line7">
                    <div id="div-siklu">
                        <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
                    </div>
                    <div id="div-total-ground">
                        <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                    </div>
                </div>
                
            </div>
        </div> CIERRE CERTIFICACIONES
        -->
        <div class="industrial">
            <!--
            <h1>Industrial Security</h1>
            <div>
                <a href='http://www.isnetworld.com'>
                    <img alt='ISN Contractor Safety Management' src='http://www.isnetworld.com/images/memberCeLogo_small.png' />
                </a>
            </div>
            -->
            <div class="seguridad-industrial">
                <div class="titulo-oculto-movil">
                    <h1>Seguridad Industrial</h1>                    
                </div>
                <div>
                    <img src="../../assets/Imagenes/seg-indus.jpeg" alt="seguridad">
                </div>
                <div id="texto-industrial">
                    <h1>Seguridad Industrial</h1>
                    <div>
                        <p>
                            Nos especializamos en atender a clientes industriales y de Infraestructura Crítica, por lo que contamos con un Departamento de Seguridad Industrial, quienes verifican y se aseguran de que los trabajos que realizamos cumplan con las más estrictas normas internacionales en materia de Seguridad Industrial.    
                            <a href='http://www.isnetworld.com' target="_blank">
                                <img alt='ISN Contractor Safety Management' src='http://www.isnetworld.com/images/memberCeLogo_small.png' />
                            </a>
                        </p>
                    </div>
                    <p>
                        Además, somos miembro activo de ISNetworld, que es una plataforma de gestión de contratistas que ayuda a gestionar los riesgos, a apoyar la sustentabilidad y a fortalecer las relaciones. Ayuda a reducir las duplicaciones innecesarias asociadas con los procesos de cualificación tradicionales.
                    </p>
                    <p>
                        El equipo de Revisión y Verificación (RAVS) de ISN verifica y evalúa nuestra información de salud, seguridad y medio ambiente para asegurarse que cumple con las regulaciones internacionales y los requisitos específicos de cada cliente.
                    </p>
                    <ul>
                        Entre la información que tenemos auditada por ISN destaca:
                        <li>Certificados de seguro</li>
                        <li>Documentación del IMSS</li>
                        <li>Manuales de procedimientos y Politicas de Seguridad, Salud y Medio Ambiente</li>
                        <li>Información de Entrenamiento y Capacitación</li>
                        <li>Rastreo de Horas Trabajadas e Incidentes Mensuales</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--<div id="ubicacion">
            <h1>En donde trabajamos</h1>
            <div id="mapa">
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.0582211107767!2d-98.19567708509975!3d19.017155987121768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc0966034380d%3A0x439eef3fdfc4b144!2sT-Bell%20Telecom!5e0!3m2!1ses!2smx!4v1616715231008!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                
                <img src="..\..\assets\Imagenes\misc\mapa-clientes.jpeg">
            </div>
        </div>-->
        <!--<div id="contacto">
            <h1>Contacto</h1>
            <div id="contacto1">
                <div>
                    <p>ventascorporativas@t-bell.com.mx</p>
                </div>
                <div>
                    <p>+52 800-7-2355-11</p>
                </div>
            </div>
        </div>CIERRE CONTACTO-->
        <app-new-footer></app-new-footer>
    </div><!--CIERRE CUERPO-->
</div><!--CIERRE PAGINA PRINCIPAL-->