<app-navigation-bar></app-navigation-bar>
<div id="escuelas-seguridad">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-educacion">Educación</a> - <a href="#/e-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/e-seguridad">Seguridad</a> > <a href="#/e-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>--><!--CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Education</h1>
        </div>
        <div>
            <div>
                <h3>Electronic Security</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV & Voice</h3>
                <p>
                    Closed-circuit television (server, VMS, cameras) to keep under surveillance any area. Some models can incluide speakers, which can be use to give alerts in case of an emergency, give announcements and give warnings.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Perimeter security</h3>
                <p>
                    Keeps safe your company sorroundings through prowling cameras, geo fence, and so on.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Access Control</h3>
                <p>
                    Allow or restrict access to only authorized students, staff, and visitors.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Biometric reader</h3>
                <p>
                    Authenticates a person's identity through fingerprint or face reader, maximizing the tranquility and security of the students.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>