<app-navigation-bar></app-navigation-bar>
<div id="corporativos-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-corporativos">Corporativos</a> - <a href="#/c-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/c-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Corporate</h1>
        </div>
        <div>
            <div>
                <h3>Telecommunications Infrastruture</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Data Center</h3>
                <p>
                    Store a big amount of data, that can be crucial within the services.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>MDF / IDF</h3>
                <p>
                    help to extend the communications connections between equipments, voice and data (LAN).
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Copper / Fiber Wiring</h3>
                <p>
                    It helps to have a better consistency in terms of the data communication infrastructure, covering a greater distance in a shorter time.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>PTP / PMP microwave</h3>
                <p>
                    Connect even the most difficult to access networks, wirelessly having greater range and stability.
                    Thus achieving communication between the different venues.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="salas">
            <div id="imagen">
            </div>
            <div id="texto">
                <h3>Smart Boardroom</h3>
                <p>
                    Enhance the remote conference experience.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>