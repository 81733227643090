<app-navegador-n></app-navegador-n>
<div id="industria-seguridad">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industria</a> - <a href="#/i-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/i-seguridad">Seguridad</a> > <a href="#/i-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industria</h1>
        </div>
        <div>
            <div>
                <h3>Seguridad Electrónica</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV y Voceo</h3>
                <p>
                    Circuito cerrado de televisión (Servidor, VMS, Cámaras). Pueden tener integrados altavoces que sirven para dar alertas en caso de contingencias, dar anuncios, hacer sonar alarmas y/o advertencias.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Seguridad Perimetral</h3>
                <p>
                    Mantiene seguros los alrededores de sus instalaciones a través de cámaras de merodeo, geo cercas, etc.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Control de Acceso</h3>
                <p>
                    Permite o restringe el acceso a un área determinada. Ayuda a controlar los accesos a zonas restringidas o donde el personal sea limitado.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Lectores Biométricos (huella, palma, ojos, facial)</h3>
                <p>
                    Autentica la identidad de una persona a través de lectores de huella, facial o de retina, maximizando la seguridad del área.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <div id="socios">
        <div id="subti">
            <h1>Socios Tecnológicos</h1>
        </div>
        <div id="logos">
            <div id="uno">
                <div id="panduit">
                    <img src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                </div>
                <div id="cisco">
                    <img src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                </div>
                <div id="extreme">
                    <img src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                </div>
                <div id="dos">
                    <img src="..\..\assets\Imagenes\certi-color\c-rockwell.png">
                    
                </div>
                <div id="tres">
                    <img src="..\..\assets\Imagenes\certi-color\c-hirschmann.png">
                </div>
                <div id="total-g">
                    <img src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                </div>
                <div id="allen">
                    <img src="..\..\assets\Imagenes\certi-color\c-allen.png">
                </div>
                <div id="planet">
                    <img src="..\..\assets\Imagenes\certi-color\c-planet.png">
                </div>
            </div>
        </div>
    </div><!--CIERRE SOCIOS-->
    <app-new-footer></app-new-footer>
</div>