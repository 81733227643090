import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ce-corporativos',
  templateUrl: './ce-corporativos.component.html',
  styleUrls: ['./ce-corporativos.component.css']
})
export class CeCorporativosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
