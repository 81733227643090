import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-seguridad',
  templateUrl: './c-seguridad.component.html',
  styleUrls: ['./c-seguridad.component.css']
})
export class CSeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
