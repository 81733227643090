<!--<app-navegador-n></app-navegador-n>
<div class="titulo">
    <h1>Instituciones</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones1.jpg" alt="Instituciones">
    </div>
    <div class="texto">
        <h2>Instituciones Educativas</h2>
        <p>
            Enlace de diferentes campus universitarios mediante el manejo de comunicaciones punto a punto o punto multipunto a través de enlaces de alta capacidad.
            Se incluyen los estudios de línea de vista e instalación de torres arriostrada o autosoportada.
            Mayor eficiencia en procesos educativos, de investigación y divulgación científica mediante el incremento en la capacidad de salida hacia la Red Mundial de Internet.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <h2>
            Instituciones Cientificas
        </h2>
        <p>
            Rapidez y seguridad en el manejo de la información proveniente del Radio Telescopio Milimétrico más grande del mundo, mediante la Instalación de infraestructura de red de Telecomunicaciones IP.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones2.jpg" alt="Instituciones">
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Instituciones3.jpg" alt="Instituciones">
    </div>
    <div class="texto">
        <h2>
            Instituciones Educativas y de Salud
        </h2>
        <p>
            Extensión de la red de telecomunicaciones a preparatorias, hospital y campus en otras ciudades mediante enlaces de microondas de alta capacidad, punto-punto o punto-multipunto. Realizamos desde los estudios de línea de vista, hasta la instalación de torres arriostradas o autosoportadas y equipo de microondas.
        </p>
    </div>
</div>
<app-new-footer></app-new-footer>
-->


<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\instituciones 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\instituciones 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\instituciones 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Instituciones</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Trabajamos con responsabilidad para ofrecerles a nuestros clientes los mejores <br>
            servicios para la implementación de infraestructura y seguridad dentro de los campus, <br>
            colaborando con la educación, la investigación y el bienestar de cada estudiante, maestro y auxiliar.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/uagro.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/inaoe.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/hospital-universitario.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/buap.png">
                </div>
            </div>
            <small>* Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>
        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Instituciones Educativas</h2>
                    <p>
                        Enlace de diferentes campus
                        universitarios mediante el manejo de
                        comunicaciones punto a punto o
                        punto multipunto a través de
                        enlaces de alta capacidad.
                        Se incluyen los estudios de línea de vista e instalación de torres arriostrada o autosoportada.
                        Mayor eficiencia en procesos educativos, de investigación y divulgación científica mediante el 
                        incremento en la capacidad de salida hacia la Red Mundial de Intern.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-institucion-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Instituciones Cientificas</h2>
                    <p>
                        Rapidez y seguridad en el
                        manejo de la información
                        proveniente del Radio
                        telescopio milimétrico más
                        grande del mundo, mediante
                        la instalación de
                        infraestructura de red de
                        telecomunicaciones ip.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-institucion-2.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Instituciones de Salud</h2>
                    <p>
                        Extensión de la red de
                        telecomunicaciones a preparatorias,
                        hopital y campus en otras cuidades
                        mediante enlaces de microondas de alta
                        capacidad, punto-punto o punto-multipunto.
                        Realizamos desde los estudios de linea de vista,
                        hasta la instalación de torres arriostradas o
                        autosuportadas y equipo de microondas.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-institucion-3.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="uno">
                <div class="imagen">
                    <h2>Instituciones Educativas</h2>
                    <div class="texto">
                        <p>
                            Enlace de diferentes campus
                            universitarios mediante el manejo de
                            comunicaciones punto a punto o
                            punto multipunto a través de
                            enlaces de alta capacidad.
                            Se incluyen los estudios de línea de vista e instalación de torres arriostrada o autosoportada.
                            Mayor eficiencia en procesos educativos, de investigación y divulgación científica mediante el 
                            incremento en la capacidad de salida hacia la Red Mundial de Intern.
                        </p>
                    </div>
                </div>
            </div>

            <div class="dos">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Instituciones Cientificas</h2>
                    <div class="texto">
                        <p>
                            Rapidez y seguridad en el
                            manejo de la información
                            proveniente del Radio
                            telescopio milimétrico más
                            grande del mundo, mediante
                            la instalación de
                            infraestructura de red de
                            telecomunicaciones ip.
                        </p>
                    </div>
                </div>
            </div>

            <div class="tres">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Instituciones de Salud</h2>
                    <div class="texto">
                        <p>
                            Extensión de la red de
                            telecomunicaciones a preparatorias,
                            hopital y campus en otras cuidades
                            mediante enlaces de microondas de alta
                            capacidad, punto-punto o punto-multipunto.
                            Realizamos desde los estudios de linea de vista,
                            hasta la instalación de torres arriostradas o
                            autosuportadas y equipo de microondas.
                        </p>
                    </div>
                </div>
            </div>

        </div>--><!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>