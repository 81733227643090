<footer>
    <h1>Contacto</h1>
    <div id="pie">
    <div id="d-datos">
        <!--<h3>Contáctanos</h3>-->
        <ul id="datos">
            <!--<li><img src="..\..\assets\Imagenes\rs\posicion.png" width="25px"/>Delphos 4338, Los Pilares, 72560 Puebla, Pue.</li>-->
            <li><img src="..\..\assets\Imagenes\rs\b-email.png"/><p><a href="mailto:ventascorporativas@t-bell.com.mx">ventascorporativas@t-bell.com.mx</a></p></li>
            <li><img src="..\..\assets\Imagenes\rs\b-telefono.png"><p>+52 800-7-2355-11</p></li>
        </ul>
    </div>
    <div id="d-redes">
        <h3>Síguenos en nuestras redes sociales</h3>
        <ul id="redes"><!--SÍGUENOS EN NUESTRAS REDES SOCIALES-->
            <li><a class="redsocial" href="https://twitter.com/TbellTelecom"><img width="30px" src="..\..\assets\Imagenes\rs\twitter2.png"></a></li>
            <li><a class="redsocial" href="https://es-la.faceboook.com/T-Bell-Telecom/"><img width="30px" src="..\..\assets\Imagenes\rs\facebook2.png"></a></li>
            <li><a href="https://www.linkedin.com/company/t-bell-telecom"><img width="30px" src="..\..\assets\Imagenes\rs\linkedin.png"></a></li>
        </ul>
    </div>
    </div>
    <small>© 2021 T-Bell Telecom.</small>
</footer>