<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\RESIDENCIAS 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\residencias 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\residencias 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Residencias</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Ofrecemos diversas soluciones y  servicios integrales a residencias para efectuar sus servicios, 
            con el propósito de mejorar la estadía de cada uno de sus residentes, otorgándoles mayor comodidad y seguridad.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/cocoyoc2.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/adamant.png">
                </div>
            </div>

            <small>
                * Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>

        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Residencia Lomas de Cocoyoc</h2>
                    <p>
                        Realizamos la instalación de infraestructura de comunicaciones, integramos el control de acceso 
                        y video vigilancia en el residencial, el cual cuenta con más de 5000 casas, considerado como 
                        fraccionamiento único en su tipo y de los más grandes del país.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-residencia-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Adamant II <br>(Torre Residencial)</h2>
                    <p>s
                        Suministramos, instalamos, configuramos y pusimos  en marcha  los sistemas de CCTV, sonido ambiental, 
                        control de acceso, red WiFi, red de TV, y detección de fuego en el edificio 
                        residencial “Adamant II” en la ciudad de Puebla, con el propósito de brindar a sus residentes 
                        mayor seguridad, tranquilidad y comodidad.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-residencia-2.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="uno">
                <div class="imagen">
                    <h2>Residencia Lomas de Cocoyoc</h2>
                    <div class="texto">
                        <p>
                            Realizamos la instalación de infraestructura de comunicaciones, integramos el control de acceso 
                            y video vigilancia en el residencial, el cual cuenta con más de 5000 casas, considerado como 
                            fraccionamiento único en su tipo y de los más grandes del país.
                        </p>
                    </div>
                </div>
            </div>

            <div class="dos">
                <div class="imagen">
                    <h2>Adamant II <br>(Torre Residencial)</h2>
                    <div class="texto">
                        <p>
                            Suministramos, instalamos, configuramos y pusimos  en marcha  los sistemas de CCTV, sonido ambiental, 
                            control de acceso, red WiFi, red de TV, y detección de fuego en el edificio 
                            residencial “Adamant II” en la ciudad de Puebla, con el propósito de brindar a sus residentes 
                            mayor seguridad, tranquilidad y comodidad.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        --><!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>