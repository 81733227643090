<app-navegador-wb></app-navegador-wb>
<div id="contacto">
    <!--<h2>Llama al</h2>
    <p>Tels: (222) 2 98 04 54 / 264 02 23</p>
    <h2>Encuentranos en</h2>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d943.0153230732881!2d-98.19330465217529!3d19.01702063744925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc0966034380d%3A0x439eef3fdfc4b144!2sT-Bell%20Telecom!5e0!3m2!1ses!2smx!4v1606931956151!5m2!1ses!2smx" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
    <!--<div id="imagen-titulo">
    </div>-->
    <div id="contenido">

        <div class="video">

            <div class="videos">
                <div>
                    <video src="..\..\assets\Videos\contacto 1.mp4" autoplay="true" loop="true" muted="true"></video>
                </div>
                <div>
                    <video src="..\..\assets\Videos\contacto 2.mp4" autoplay="true" loop="true" muted="true"></video>
                </div>
                <div>
                    <video src="..\..\assets\Videos\contacto 3.mp4" autoplay="true" loop="true" muted="true"></video>
                </div>
            </div><!--FIN VIDEOS-->
    
            <div class="tit">
                <h1>Contacto</h1>
            </div><!--FIN TIT-->
    
        </div><!--FIN VIDEO-->


        <div class="ventas">
            <h1>VENTAS</h1>
            <div class="ventas1">
                <a href="mailto:ventascorporativas@t-bell.com.mx">
                    <img src="..\..\assets\Imagenes\rs\email.png" width="50px" height="50px">
                    <p>ventascorporativas@t-bell.com.mx</p>
                </a>
            </div>
            <div class="ventas2">
                <a href="tel:+5212222980454">
                    <img src="..\..\assets\Imagenes\rs\telefono.png" width="50px" height="50px">
                    <p>222 298 0454</p>
                </a>
            </div>
        </div><!-- FIN VENTAS -->


    	<div id="imagen-titulo">
    	<div id="soporte" class="soporte">
    		<h1>Soporte Técnico</h1>
    		<p>Si ya eres cliente de T-Bell Telecom y requieres ayuda de soporte técnico da click <a href="https://tbell.freshdesk.com/support/home">aqui</a></p>
    	</div>
    	</div>

        <div class="ventas">
            <h1>VENTAS</h1>
            <div class="ventas1">
                <a href="https://faceboook.com/T-Bell-Telecom/" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\facebook2.png" width="50px" height="50px">
                    <p>T-Bell Telecom</p>
                </a>
            </div>
            <div class="ventas2">
                <a href="https://twitter.com/TbellTelecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\twitter2.png" width="50px" height="50px">
                    <p>@TbellTelecom</p>
                </a>
            </div>
            <div class="ventas1">
                <a href="https://www.linkedin.com/company/t-bell-telecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\linkedin2.png" width="50px" height="50px">
                    <p>T-Bell Telecom</p>
                </a>
            </div>
            <div class="ventas1">
                <a href="https://api.whatsapp.com/send?phone=5212223017051&text=¿Qué servicios ofrecen?" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\whatsapp2.png" width="50px" height="50px">
                    <p>WhatsApp</p>
                </a>
            </div>
        </div><!-- FIN REDES -->

    </div>
</div>
<app-new-footer></app-new-footer>