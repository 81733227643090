<app-navigation-bar></app-navigation-bar>
<div id="industria-logistica">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industry</a> - <a href="#/industry-unified-comm">Unified Communications</a> < <a href="#/industry-logistics">Logistics</a> > <a href="#/industry-remote-colaboration">Remote Colaboration</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industry</h1>
        </div>
        <div>
            <div>
                <h3>Logistics</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Vehicles and containers tracking</h3>
                <p>
                    Get a better control of the fleet and transportation logistics.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Smart lockers</h3>
                <p>
                    It sends an alarm together with the location on real time, once the locker has been open, close and/or violated.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Driving analytics</h3>
                <p>
                    It monitors the behavior of the personnel that handles the transport, reports when detecting distractions or drowsiness of the driver. Generates reports of mileage, fuel consumption and unscheduled stops.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>