<app-navegador></app-navegador>
<div id="contenedor">
    <h1>¿Quiénes somos?</h1>
    <div id="mivi">
        <div id="mision">
            <h2>Misión</h2>
            <img src="..\..\assets\Imagenes\rs\mision.png" width="30%">
            <p>Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional, preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor, distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano y de la aplicación y administración de tecnología certificada por nuestros proveedores, para permitirle a nuestros clientes lograr sus objetivos</p>
        </div>
        <div id="vision">
            <h2>Visión</h2>
            <img src="..\..\assets\Imagenes\rs\vision.png" width="30%">
            <p>Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios. Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades.</p>
        </div>
    </div>
</div>
<app-piepag></app-piepag>