<app-navegador-n></app-navegador-n>
<div id="corporativos-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-corporativos">Corporativos</a> - <a href="#/c-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/c-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Corporativos</h1>
        </div>
        <div>
            <div>
                <h3>Infraestructura de Telecomunicaciones</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Data Center</h3>
                <p>
                    Almacena una gran cantidad de datos dentro de la organización que pueden ser críticos para los servicios.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>MDF / IDF</h3>
                <p>
                    Ayuda a extender el alcance de las comunicaciones entre equipos, voz y datos (LAN). Manteniendo la estetica del lugar.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Cableado Cobre / Fibra</h3>
                <p>
                    Ayuda a tener una major consistencia en cuanto a la infraestructura de la comunicación de datos, abarcando una mayor distancia en un menor tiempo.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Microondas PTP / PMP</h3>
                <p>
                    Conecta hasta las redes de más difícil acceso, de manera inalámbrica teniendo mayor un mayor alcance y estabilidad. Logrando asi la comunicación entre las diferentes sedes.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="salas">
            <div id="imagen">
            </div>
            <div id="texto">
                <h3>Salas de juntas inteligentes</h3>
                <p>
                    Mejora la experiencia en conferencias realizadas de manera remota.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>