<app-navigation-bar></app-navigation-bar>
<div id="critica-seguridad">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-infraestructura-critica">Infraestructura Critica</a> - <a href="#/ic-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/ic-seguridad">Seguridad</a> > <a href="#/ic-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Critical Infrastructure</h1>
        </div>
        <div>
            <div>
                <h3>Electronic Security</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV & Voice</h3>
                <p>
                    Closed-circuit television (server, VMS, cameras) to keep under surveillance any area. Some models can include speakers, which can be use to give alerts in case of an emergency, give announcements and give warnings.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Radar</h3>
                <p>
                    Detect intrusions effectively and have the CCTV system record important events.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Perimeter security</h3>
                <p>
                    Keeps safe your company surroundings through prowling cameras, geo fence, and so on.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Access readers</h3>
                <p>
                    System that allows or restricts access to a certain area, Helps to control access to restricted areas or where personnel are limited.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="biometricos">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Biometric reader</h3>
                <p>
                    Authenticates a person's identity through fingerprint or face reader; helping to reduce infection sources. It can also restring the access to a certain area.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>