<app-navigation-bar></app-navigation-bar>
<div id="critica-comunicacion">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-infraestructura-critica">Infraestructura Critica</a> - <a href="#/ic-seguridad">Seguridad</a> < <a href="#/ic-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Critical Infrastructure</h1>
        </div>
        <div>
            <div>
                <h3>Unified Communications</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>IP Voice</h3>
                <p>
                    Communicate announcements, notifications and alarms with better quality.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>