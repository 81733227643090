<app-navegador-n></app-navegador-n>
<div id="industria-logistica">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industria</a> - <a href="#/i-com-uni">Comunicaciones Unificadas</a> < <a href="#/i-logistica">Logistica</a> > <a href="#/i-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industria</h1>
        </div>
        <div>
            <div>
                <h3>Logística</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Rastreo GPS de Vehículos y contenedores</h3>
                <p>
                    Control de la flotillas y logística en cuanto a transporte.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Candados Inteligentes</h3>
                <p>
                    Manda una señal de alarma junto con la ubicación en tiempo real al centro de vigilancia en caso de que el candado ha sido abierto, cerrado y/o violado.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Analíticos de manejo</h3>
                <p>
                    Monitorea el comportamiento del personal que maneja el transporte, reporta al detectar distracciones o somnolencia del conductor.
                    Genera reportes de kilometraje recorrido, consumo de combustible y paradas no programadas.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>