<!--
<footer>
    <h1>Contact</h1>
    <div id="pie">
    <div id="d-datos">
        --------<h3>Contáctanos</h3>
        <ul id="datos">
            --------<li><img src="..\..\assets\Imagenes\rs\posicion.png" width="25px"/>Delphos 4338, Los Pilares, 72560 Puebla, Pue.</li>
            <li><img src="..\..\assets\Imagenes\rs\b-email.png"/><p><a href="mailto:ventascorporativas@t-bell.com.mx">ventascorporativas@t-bell.com.mx</a></p></li>
            <li><img src="..\..\assets\Imagenes\rs\b-telefono.png"><p>+52 800-7-2355-11</p></li>
        </ul>
    </div>
    <div id="d-redes">
        <h3>Follow us on our social networks</h3>
        <ul id="redes">--SÍGUENOS EN NUESTRAS REDES SOCIALES--
            <li><a class="redsocial" href="https://twitter.com/TbellTelecom"><img width="30px" src="..\..\assets\Imagenes\SVG\twitter.svg"></a></li>
            <li><a class="redsocial" href="https://es-la.faceboook.com/T-Bell-Telecom/"><img width="30px" src="..\..\assets\Imagenes\SVG\facebook.svg"></a></li>
            <li><a href="https://www.linkedin.com/company/t-bell-telecom"><img width="30px" src="..\..\assets\Imagenes\SVG\linkedin2.svg"></a></li>
        </ul>
    </div>
    </div>
    <small>© 2021 T-Bell Telecom. All rights reserved.</small>
</footer>
-->
<div class="footer">
    <footer>
        <div class="container-footer-all">
        <div class="container-footer">
            <!--
            <div class="columna1">
                <h1>Menu</h1>
                <div class="row">
                    <a href="#/home">Home</a>
                </div>
                <div class="row">
                    <a href="#/about-us">About us</a>
                </div>
                <div class="row">
                    <a href="https://t-bell.mercadoshops.com.mx/" target="_blank">Store</a>
                </div>
                <div class="row">
                    <a href="#/contact">Contact</a>
                </div>
                !--
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat vero eum incidunt nam explicabo. Est a aliquid quis debitis atque molestias iure, necessitatibus sunt illum sapiente expedita adipisci, porro quia!
                </p>
                --
            </div>
            -->
            <div class="columna2">
                <h1>Social Media</h1>
                <div class="fila">
                    <a href="https://faceboook.com/T-Bell-Telecom/" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\facebook2.png" alt="facebook">
                    </a>
                    <label for="">Follow us on Facebook</label>
                </div>
                <div class="fila">
                    <a href="https://twitter.com/TbellTelecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\twitter2.png" alt="twitter">
                    </a>
                    <label for="">Follow us on Twitter</label>
                </div>
                <div class="fila">
                    <a href="https://www.linkedin.com/company/t-bell-telecom" target="_blank">
                    <img src="..\..\assets\Imagenes\rs\linkedin2.png" alt="linkedin">
                    </a>
                    <label for="">Follow us on LinkedIn</label>
                </div>
            </div>
            <div class="columna3">
                <h1>Contact</h1>
                <div class="fila2">
                    <a href="mailto:ventascorporativas@t-bell.com.mx">
                        <img src="..\..\assets\Imagenes\rs\b-email.png" alt="email">
                    </a>
                    <label for="">ventascorporativas@t-bell.com.mx</label>
                </div>
                <div class="fila2">
                    <a href="https://api.whatsapp.com/send?phone=5212223017051&text=¿Qué servicios ofrecen?" target="_blank">
                        <img src="..\..\assets\Imagenes\rs\whatsapp-logo.png" alt="telefono">
                    </a>
                    <label for="">WhatsApp</label>
                </div>
                <!--
                <div class="fila2">
                    <img src="..\..\assets\Imagenes\rs\b-telefono.png" alt="telefono">
                    <label for="">+52 800-7-2355-11</label>       
                </div>
                -->
            </div>
        </div>
        </div>
        <!---->
        <div class="container-derechos">
            <div class="footer-copy">
                <div class="copyright">
                    <small>© 2021 T-Bell Telecom</small>
                </div>
                <!--
                <div class="idiomas">
                    <small><a href="#/principal">Spanish</a> | <a href="#/home">English</a></small>
                </div>
                -->
            </div>
        </div>
    </footer>
</div>