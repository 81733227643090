<div class="slider">
    <ul>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i1.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i2.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i3.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i4.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i5.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i6.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/ingles/i7.jpeg" alt="">
        </li>
    </ul>
</div>