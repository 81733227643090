<app-navegador-n></app-navegador-n>
<div id="industria-colab">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industria</a> - <a href="#/i-logistica">Logistica</a> < <a href="#/i-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industria</h1>
        </div>
        <div>
            <div>
                <h3>Colaboración Remota</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Salas de juntas</h3>
                <p>
                    Mejora la experiencia en conferencias realizadas de manera remota.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Audio ambiental</h3>
                <p>
                    Armoniza cualquier espacio con música de forma en que llegue a todos los rincones por igual.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Automatización de espacios</h3>
                <p>
                    Facilite el manejo de el equipo, para agilizar su uso.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>