<!--<app-navegador-n></app-navegador-n>
<div class="titulo">
    <h1>Gobierno</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Gobierno1.jpg" alt="Gobierno">
    </div>
    <div class="texto">
        <p>
            Para mejorar la seguridad y transparencia en la impartición de justicia, Jueces y Magistrados de la Suprema Corte de Justicia de la Nación hacen uso de Sistemas de telecomunicaciones IP de Voz y Datos y gozan de la seguridad que les brinda un sistema de Videovigilancia CCTV.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Trabajos en conjunto con el INAH para la instalación de infraestructura de red, apegándonos a los lineamientos del cuidado de edificios históricos.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Gobierno2.jpg" alt="Gobierno">
    </div>
</div>
<app-new-footer></app-new-footer>
-->

<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\gobierno 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\gobierno 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\gobierno 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Gobierno</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Trabajando de la mano con diferentes instituciones gubernamentales, integramos <br>
            proyectos de infraestructura de red, equipo activo, y telefonía ip además de brindarles <br>
            sistemas de Videovigilancia que garantizan su seguridad.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/suprema-corte.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/inah.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/comision-nacional.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/cenace.png">
                </div>
            </div>
        </div>

        
        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Consejo de la judicatura federal - puebla</h2>
                    <p>
                        Para mejorar la seguridad y transparencia en la impartición de justicia; 
                        Jueces y Magistrados de la Suprema Corte de Justicia de la Nación 
                        hacen uso de Sistemas de telecomunicaciones IP de Voz y Datos, 
                        además de un sistema de Videovigilancia CCTV que les brinda tranquilidad y seguridad.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-gobierno-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Comisión de derechos humanos</h2>
                    <p>s
                        Trabajamos en la instalación de infraestructura de red, 
                        apegándonos a los lineamientos del cuidado de edificios históricos protegidos por el INAH.<br>

                        Integramos e instalamos suministros de infraestructura de red como: 
                        servidores, switches, firewall, entre otros.<br>

                        Además de darle mantenimiento y reparación de algunos otros componentes.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-gobierno-2.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Edificio del centro nacional del control de energía (CENACE)</h2>
                    <p>
                        Instalamos Infraestructura de red, aplicando canalizaciones y nodos de red para Voz/Datos 
                        con el objetivo de eficientizar el control operativo del sistema eléctrico nacional.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-gobierno-3.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            --
            <div class="morena">
                <div class="imagen">
                    <h2>Suprema Corte de <br>Justicia de la Nación</h2>
                    <div class="texto">
                        <p>
                            Para mejorar la seguridad y
                            transparencia en la impartición de
                            justicia, Jueces y magistrados de la
                            suprema corte de justicia de la nacion
                            hacen uso de sistemas de
                            telecomunicaciones ip de voz y datos y
                            gozan de la seguridad que les brinda un
                            sistema de videovigilancia.
                        </p>
                    </div>
                </div>
            </div>

            <div class="bimbo">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Instituto Nacional de Antropología e Historia</h2>
                    <div class="texto">
                        <p>
                            Trabajamos en conjunto con
                            el INAH para la instalación de
                            infraestructura de red,
                            apegándonos a los
                            lineamientos del cuidado de
                            edificios históricos.
                        </p>
                    </div>
                </div>
            </div>
        --
            <div class="morena">
                <div class="imagen">
                    <h2>Consejo de la judicatura federal - puebla</h2>
                    <div class="texto">
                        <p>
                            Para mejorar la seguridad y transparencia en la impartición de justicia; 
                            Jueces y Magistrados de la Suprema Corte de Justicia de la Nación 
                            hacen uso de Sistemas de telecomunicaciones IP de Voz y Datos, 
                            además de un sistema de Videovigilancia CCTV que les brinda tranquilidad y seguridad.
                        </p>
                    </div>
                </div>
            </div>

            <div class="bimbo">
                <div class="imagen">
                    <h2>Comisión de derechos humanos</h2>
                    <div class="texto">
                        <p>
                            Trabajamos en la instalación de infraestructura de red, 
                            apegándonos a los lineamientos del cuidado de edificios históricos protegidos por el INAH.<br>

                            Integramos e instalamos suministros de infraestructura de red como: 
                            servidores, switches, firewall, entre otros.<br>

                            Además de darle mantenimiento y reparación de algunos otros componentes. 

                        </p>
                    </div>
                </div>
            </div>

            <div class="tres">
                <div class="imagen">
                    <h2>Edificio del centro nacional del control de energía (CENACE)</h2>
                    <div class="texto">
                        <p>
                            Instalamos Infraestructura de red, aplicando canalizaciones y nodos de red para Voz/Datos 
                            con el objetivo de eficientizar el control operativo del sistema eléctrico nacional.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        --><!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>