import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-piepag',
  templateUrl: './piepag.component.html',
  styleUrls: ['./piepag.component.css']
})
export class PiepagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
