<app-navegador></app-navegador>
<div id="pagina">
	<div id="cuerpo">
		<div id="cuadros">
			<div id="vacio">
				<h1>Infraestructura de Telecomunicaciones</h1>
			</div>
			<div id="linea1">
				<div>
					<div id="imagen"></div>
					<h3>Industria</h3>
					<div id="boton">
						<a href="#/i-infraestructura">Ver más...</a>
					</div>
					<!---->
				</div>
				<div>
					<div id="imagen2"></div>
					<h3>Infraestructura Critica</h3>
					<div id="boton">
						<a href="">Ver más...</a>
					</div>
				</div>
			</div>
			<div id="linea2">
				<div>
					<div id="imagen3"></div>
					<h3>Ciudades Inteligentes</h3>
					<div id="boton">
						<a href="#/i-infraestructura">Ver más...</a>
					</div>
				</div>
				<div>
					<div id="imagen4"></div>
					<h3>Corporativos</h3>
					<div id="boton">
						<a href="">Ver más...</a>
					</div>
				</div>
				<div>
					<div id="imagen5"></div>
					<h3>Escuelas</h3>
					<div id="boton">
						<a href="">Ver más...</a>
					</div>
				</div>
			</div>
		</div>	
	</div>
</div>