<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\infra est crit 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INF. CRITICA 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INF. CRITICA 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Infraestructura Crítica</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Estamos preparados y capacitados para llevar a cabo todo tipo de requerimiento de infraestructura de red y CCTV 
            para todos los activos que son esenciales en su funcionamiento, 
            teniendo en cuenta todos las normas necesarias para trabajar en esta área.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/cfe.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/valero.png">
                </div>
            </div>

            <small>
                * Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>

        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>CFE - Peñitas</h2>
                    <p>
                        Llevamos a cabo la aplicación de Fibra Óptica 
                        en la Planta Externa de CFE que abarca 28 Kilómetros de cableado.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-critica-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Terminal de refinados Valero</h2>
                    <p>s
                        Colocamos 45 km de Fibra Óptica en Planta Externa, 
                        así como también integramos CCTV para monitorear vías férreas.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-critica-2.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="uno">
                <div class="imagen">
                    <h2>CFE - Peñitas</h2>
                    <div class="texto">
                        <p>
                            Llevamos a cabo la aplicación de Fibra Óptica 
                            en la Planta Externa de CFE que abarca 28 Kilómetros de cableado.
                        </p>
                    </div>
                </div>
            </div>

            <div class="dos">
                <div class="imagen">
                    <h2>Terminal de refinados valero</h2>
                    <div class="texto">
                        <p>
                            Colocamos 45 km de Fibra Óptica en Planta Externa, 
                            así como también integramos CCTV para monitorear vías férreas.
                        </p>
                    </div>
                </div>
            </div>

        </div>--><!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>