<app-navegador-n></app-navegador-n>
<div id="escuelas-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-educacion">Educación</a> - <a href="#/e-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/e-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Educación</h1>
        </div>
        <div>
            <div>
                <h3>Infraestructura de Telecomunicaciones</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>MDF / IDF</h3>
                <p>
                    Ayuda a extender el alcance de las comunicaciones entre equipos, voz y datos (LAN).
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Cableado Cobre / Fibra</h3>
                <p>
                    Ayuda a tener una mejor consistencia en cuanto a la infraestructura de la comunicación de datos, abarcando una mayor distancia en menor tiempo.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>