<div id="pagina">
	<div id="barra">
		<nav>
			<img src="..\..\assets\Imagenes\logostbell\T-Bell 2016 Invertido transparente.png" onclick="location.href='#/principal'">
			<h1>T-Bell Telecom</h1>	
		</nav>
	</div>
	<div id="cuerpo">
		<div id="formulario">
			<form method="POST">
				<h2>Inicio de sesión</h2>
				<label for="POST-usuario">Usuario:</label>
				<input id="POST-usuario" name="usuario" type="text" class="clase-input">
				<label for="POST-contra">Contraseña:</label>
				<input id="POST-contra" name="contrasena" type="password" class="clase-input">
				<input type="submit" value="Ingresar" class="boton" onclick="location.href='#/verificacion'">
			</form>
		</div>
	</div>
</div>