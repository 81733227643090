<app-navegador-n></app-navegador-n>
<div id="critica-seguridad">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-infraestructura-critica">Infraestructura Critica</a> - <a href="#/ic-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/ic-seguridad">Seguridad</a> > <a href="#/ic-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Infraestructura Critica</h1>
        </div>
        <div>
            <div>
                <h3>Seguridad Electrónica</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV y Voceo</h3>
                <p>
                    Circuito cerrado de televisión (Servidor, VMS, Cámaras). Pueden estar integrados altavoces que sirven para dar alertas en caso de contingencias, dar anuncios, hacer sonar alarmas y/o advertencias.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Radar</h3>
                <p>
                    Detecte intrusiones de manera efectiva y haga que el sistema de CCTV graben los sucesos importantes.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Seguridad Perimetral</h3>
                <p>
                    Mantiene seguros los alrededores de sus instalaciones a través de cámaras de merodeo, geo cercas, etc.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Lectores de Acceso</h3>
                <p>
                    Sistema que permite o restringe el acceso a un área determinada, Ayuda a controlar los accesos a zonas restringidas o donde el personal sea limitado.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="biometricos">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Lectores Biométricos (huella, palma, ojos, facial)</h3>
                <p>
                    Autentica la identidad de una personas a través de lectores de huella, facial o de retina, maximizando la seguridad del área.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>