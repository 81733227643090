import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industry-remote-collaboration',
  templateUrl: './industry-remote-collaboration.component.html',
  styleUrls: ['./industry-remote-collaboration.component.css']
})
export class IndustryRemoteCollaborationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
