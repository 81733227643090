import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qh-infraestructura-telecom',
  templateUrl: './qh-infraestructura-telecom.component.html',
  styleUrls: ['./qh-infraestructura-telecom.component.css']
})
export class QhInfraestructuraTelecomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
