<app-navegador-wb></app-navegador-wb>
<div class="base">
    <div class="video">
        <div class="videos">
            <div>
                <video src="..\..\assets\Videos\CORPORATIVOS A.mp4" autoplay="true" loop="true" muted="true"></video>
            </div>
            <div>
                <video src="..\..\assets\Videos\CORPORATIVOS B.mp4" autoplay="true" loop="true" muted="true"></video>
            </div>
            <div>
                <video src="..\..\assets\Videos\CORPORATIVOS C.mp4" autoplay="true" loop="true" muted="true"></video>
            </div>
        </div>
        <div class="tit">
            <h1>Corporativos</h1>
        </div>
    </div>
    <div class="circulos">
        <a href="#infraestructura">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (11).png">
        </a>
        <a href="#seguridad">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (1).png">
        </a>
        <a href="#comunicacion">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (2).png">
        </a>
        <p>
			<img src="..\..\assets\Imagenes\nuevas\vacio.png">
		</p>
		<p>
			<img src="..\..\assets\Imagenes\nuevas\vacio.png">
		</p>
    </div>

    <div class="titulo-infraestructura" id="infraestructura">
        <h1>infraestructura de telecomunicaciones</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-infraestructura-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-3.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Sala de juntas <br>inteligentes</h1>
                            <p>
                                Integra la  mejor tecnologia para sus <br>
                                reuniones, Mejorando la experiencia <br>
                                en conferencias realizadas de manera <br>
                                remota.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-infraestructura-5">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-2.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Data Center</h1>
                            <p>
                                Almacena una gran cantidad de <br>
                                datos dentro de la organización <br>
                                que pueden ser críticos para los <br>
                                servicios.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-11.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>MDF / IDF</h1>
                            <p>
                                Ayuda a extender el alcance de las <br>
                                comunicaciones entre equipos, voz <br>
                                y datos (LAN), Manteniendo la <br>
                                estetica del lugar.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-8.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Cableado de cobre / fibra</h1>
                            <p>
                                Ayuda a tener una major consistencia <br>
                                en cuanto a la infraestructura de la <br>
                                comunicación de datos, abarcando una <br>
                                mayor distancia en un menor tiempo.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-5">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-5">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-9.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Microondas PTM / PMP</h1>
                            <p>
                                Conecta hasta las redes de más <br>
                                difícil acceso, de manera <br>
                                inalámbrica teniendo un mayor <br>
                                alcance y estabilidad, Logrando asi <br>
                                la comunicación entre las <br>
                                diferentes sedes.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR INFRAESTRUCTURA-->

    <div class="titulo-seg-elec" id="seguridad">
        <h1>Seguridad Electrónica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-seguridad-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-10.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>CCTV y Voceo</h1>
                            <p>
                                Circuito cerrado de televisión <br>
                                (servidor, VMS, cámaras) para la <br>
                                vigilancia de algún punto. Pueden estar <br>
                                integrados altavoces que sirven para <br>
                                dar alertas en caso de contingencias, <br>
                                dar anuncios, hacer sonar alarmas y/o <br>
                                advertencias.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-4.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de acceso</h1>
                            <p>
                                Sistema que permite o restringe el <br>
                                acceso a un área determinada. <br>
                                Ayuda a controlar los accesos a <br>
                                zonas restringidas o donde el <br>
                                personal sea limitado
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-6.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Seguridad perimetral</h1>
                            <p>
                                Mantiene seguros los alrededores <br>
                                de sus instalaciones a través de <br>
                                cámaras de merodeo, geo cercas, <br>
                                etc.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-7.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Lectores Biométricos</h1>
                            <p>
                                Autentica la identidad de una <br>
                                persona a través de lectores de <br>
                                huella o faciales, maximizando la <br>
                                seguridad del área.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-5">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-5">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-12.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>control de visitas</h1>
                            <p>
                                Agilice el acceso de sus visitantes sin <br>
                                dejar de lado la restricción del área <br>
                                al visitar; de tal manera en que sus <br>
                                colaboradores se sientan seguros <br>
                                ante la presencia de personas <br>
                                externas.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR SEGURIDAD-->

    <div class="titulo-com-uni" id="comunicacion">
        <h1>Comunicación unificada</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-comunicacion-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-1.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Conmutadores IP</h1>
                            <p>
                                Es el sistema para la red telefónica, <br>
                                enfocado a empresas para <br>
                                interconectar sus propias <br>
                                comunicaciones, internas y externas.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-comunicacion-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\corporativos\corporativo-5.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Voceo IP</h1>
                            <p>
                                Comunica anuncios, <br>
                                notificaciones y alarmas con <br>
                                una mayor fidelidad.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COMUNICACION-->

    <div class="espacio-footer"></div>

<!--
    <div id="infraestructura" class="infraestructura">
        <div class="infraestructura-titulo">
            <h1>infraestructura de telecomunicaciones</h1>
        </div>
        <div class="infraestructura-subtitulo">
            <h2>Sala de juntas inteligentes</h2>
            <div class="vacio1"></div>
        </div>
        <div class="salas">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\11.png">
            <p>
                integra la  mejor tecnologia para sus <br>
                reuniones, Mejorando la experiencia <br>
                en conferencias realizadas de manera <br>
                remota.
            </p>
        </div>
        <div class="infraestructura-subtitulo2">
            <div class="vacio2"></div>
            <h2>Data Center</h2>
        </div>
        <div class="data">            
            <p>
                Almacena una gran cantidad de <br>
                datos dentro de la organización <br>
                que pueden ser críticos para los <br>
                servicios.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\12.png">
        </div>
        <div class="infraestructura-subtitulo3">
            <h2>MDF / IDF</h2>
            <div class="vacio1"></div>
        </div>
        <div class="salas">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\13.png">
            <p>
                Ayuda a extender el alcance de las <br>
                comunicaciones entre equipos, voz <br>
                y datos (LAN), Manteniendo la <br>
                estetica del lugar.
            </p>
        </div>
        <div class="infraestructura-subtitulo4">
            <div class="vacio2"></div>
            <h2>Cableado de cobre / fibra</h2>
        </div>
        <div class="data">            
            <p>
                Ayuda a tener una major consistencia <br>
                en cuanto a la infraestructura de la <br>
                comunicación de datos, abarcando una <br>
                mayor distancia en un menor tiempo.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\14.png">
        </div>
        <div class="infraestructura-subtitulo5">
            <h2>Microondas PTM / PMP</h2>
            <div class="vacio1"></div>
        </div>
        <div class="salas">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\15.png">
            <p>
                Conecta hasta las redes de más <br>
                difícil acceso, de manera <br>
                inalámbrica teniendo un mayor <br>
                alcance y estabilidad, Logrando asi <br>
                la comunicación entre las <br>
                diferentes sedes.
            </p>
        </div>
    </div>
    -->
    <!--SEGURIDAD-->
    <!--
    <div id="seguridad" class="seguridad">
        <div class="seguridad-titulo">
            <h1>Seguridad Electrónica</h1>
        </div>
        <div class="seguridad-subtitulo1">
            <div class="vacio3"></div>
            <h2>CCTV y Voceo</h2>
        </div>
        <div class="derecha">
            <p>
                Circuito cerrado de televisión <br>
                (servidor, VMS, cámaras) para la <br>
                vigilancia de algún punto. Pueden estar <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas y/o <br>
                advertencias.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\16.png">
        </div>
        <div class="seguridad-subtitulo2">            
            <h2>Control de acceso</h2>
            <div class="vacio4"></div>
        </div>
        <div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\17.png">
            <p>
                Sistema que permite o restringe el <br>
                acceso a un área determinada. <br>
                Ayuda a controlar los accesos a <br>
                zonas restringidas o donde el <br>
                personal sea limitado
            </p>
        </div>
        <div class="seguridad-subtitulo3">      
            <div class="vacio3"></div>
            <h2>Seguridad perimetral</h2>
        </div>
        <div class="derecha">
            <p>
                Mantiene seguros los alrededores <br>
                de sus instalaciones a través de <br>
                cámaras de merodeo, geo cercas, <br>
                etc.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\18.png">
        </div>
        <div class="seguridad-subtitulo3">
            <h2>Lectores biométricos</h2>
            <div class="vacio4"></div>
        </div>
        <div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\19.png">
            <p>
                Autentica la identidad de una <br>
                persona a través de lectores de <br>
                huella o faciales, maximizando la <br>
                seguridad del área.
            </p>
        </div>
        <div class="seguridad-subtitulo3">      
            <div class="vacio3"></div>
            <h2>Control de visitas</h2>
        </div>
        <div class="derecha">
            <p>
                Agilice el acceso de sus visitantes sin <br>
                dejar de lado la restricción del área <br>
                al visitar; de tal manera en que sus <br>
                colaboradores se sientan seguros <br>
                ante la presencia de personas <br>
                externas.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\20.png">
        </div>
    </div>--><!--CIERRE SEGURIDAD-->

    <!--
    <div id="comunicacion" class="comunicacion">
        <div class="comunicacion-titulo">
            <h1>Comunicación Unificada</h1>
        </div>
        <div class="comunicacion-subtitulo1">
            <h2>Conmutadores IP</h2>
            <div class="vacio5"></div>
        </div>
        <div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\corporativos\21.png">
            <p>
                Es el sistema para la red telefónica, <br>
                enfocado a empresas para <br>
                interconectar sus propias <br>
                comunicaciones, internas y externas.
            </p>
        </div>
        <div class="comunicacion-subtitulo1">      
            <div class="vacio6"></div>
            <h2>Voceo IP</h2>
        </div>
        <div class="derecha">
            <p>
                Comunica anuncios, <br>
                notificaciones y alarmas con <br>
                una mayor fidelidad.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\corporativos\22.png">
        </div>
    </div>
    -->

    <app-new-footer></app-new-footer>
</div>