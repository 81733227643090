<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">
    <!--<div class="titulo">
        <h1>Industria Alimenticia</h1>
    </div>
    <div>
        <div class="texto-b">
            <p>Mayor eficiencia y control en el análisis de los procesos mediante la Instalación y mantenimiento de cámaras de seguridad en líneas de producción. Todo ello bajo las estrictas normas de seguridad e higiene que la industria exige.</p>        
        </div>
    </div>
    <div class="division">
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Alimenticia.jpg" alt="">
        </div>
        <div class="texto">
            <p>Gran control de calidad y monitoreo del proceso mediante una red de telecomunicaciones IP. Se realizó el acondicionamiento de cuarto de equipos, incluyendo aterrizaje de tierras físicas, aire acondicionado de precisión, enlaces de espejo, UPS y tierras físicas.</p>
        </div>
    </div>
    <div class="division">
        <div class="texto-f">
            <p>Desarrollo de infraestructura de telecomunicaciones y seguridad en la industria alimenticia, cuidando las estrictas normas de higiene en la producción de los alimentos.</p>        
        </div>
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Alimenticia2.jpg" alt="">
        </div>
    </div>
    <div class="division">
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Alimenticia3.jpg" alt="">
        </div>
        <div class="texto">
            <p>Seguridad en el proceso productivo mediante la instalación de equipos de monitoreo, control de acceso y sensores de humedad o humo para los cuartos de equipos.</p>        
        </div>
    </div>
    <div class="division">
        <div class="texto-f">
            <p>Mayor control de los procesos productivos, mediante una red de Voz, Datos y Video. Todas las instalaciones son realizadas bajo los más estrictos estándares de calidad e higiene.</p>        
        </div>
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Alimenticia4.jpg" alt="">
        </div>
    </div>
    <div class="division1">
        <div class="imagen1">
            <img src="../../assets/Imagenes/exito/sub/Alimenticia5.jpg" alt="">
        </div>
    </div>-->
    <!--<div class="video">
        <div class="videos">
            <video src="..\..\assets\Videos\I. ALIMENTARIA 1.mp4" autoplay="true" loop="true" muted="true"></video>
            <video src="..\..\assets\Videos\I. ALIMENTARIA 2.mp4" autoplay="true" loop="true" muted="true"></video>
            <video src="..\..\assets\Videos\I. ALIMENTARIA 3.mp4" autoplay="true" loop="true" muted="true"></video>
        </div>
        <div class="tit">
            <h1>INSDUSTRIA ALIMENTICIA</h1>
        </div>
    </div>-->
    <div class="video">
        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\I. ALIMENTARIA 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\I. ALIMENTARIA 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\I. ALIMENTARIA 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div>
        <div class="tit">
            <h1>INSDUSTRIA ALIMENTICIA</h1>
        </div>
    </div>
    <div class="info">
        <p>
            Ayudamos a mejorar la eficiencia y control en el análisis de los procesos mediante la <br>
            instalación y mantenimiento de cámaras de seguridad en líneas de producción. Todo ello <br>
            bajo las estrictas normas de seguridad e higiene que la industria exige.
        </p>
        <div class="clientes">
            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>
            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/bimbo.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/la-morena.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/mondelez.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/tamariz.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/alpura.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/gonac.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/kraft.png">
                </div>
            </div>
            <small>* Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>
        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>La Morena</h2>
                    <p>
                        En Grupo Morena incorporamos infraestructura de telecomunicaciones y seguridad, 
                        cuidando las estrictas normas de higiene en la producción de los alimentos.
                        Equipamos e instalamos la infraestructura dentro de la nueva nave de 
                        grupo La Morena de diversos sistemas de seguridad y telecomunicaciones.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Bimbo</h2>
                    <p>
                        Mediante una red de telecomunicaciones IP, 
                        logramos poner en marcha la fase de control de calidad y monitoreo de sus procesos productivos.
                        <br>
                        <br>
                        Realizamos el acondicionamiento de cuarto de equipos, 
                        incluyendo aterrizaje de tierras físicas, aire acondicionado de precisión, enlaces de espejo y UPS.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-2.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Maulec</h2>
                    <p>
                        Incorporamos mayor control de los procesos productivos, mediante una red de Voz, Datos y Video. 
                        Todas las instalaciones son realizadas bajo los más estrictos estándares de calidad e higiene.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-3.png">
                </div>
            </div>

        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Mondelez Int.</h2>
                    <p>
                        Llevamos a cabo el proyecto de instalación de infraestructura y equipamiento de red, 
                        para los centros de distribución.
                        <br>
                        Aplicamos los métodos y medidas necesarias para la renovación de equipamiento e 
                        infraestructura de red en el Área de Productividad, sin realizar un paro de producción.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-4.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Alpura</h2>
                    <p>
                        Integramos la seguridad en el proceso productivo mediante la instalación de equipos de monitoreo, 
                        control de acceso y sensores de humedad y humo para los cuartos de equipos.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-5.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Kraft Food</h2>
                    <p>
                        Realizamos la aplicación de infraestructura de red industrial consistente en 
                        back bone de fibra óptica así como también la colocación de  IDF industriales.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-alimentaria-6.png">
                </div>
            </div>

        </div>
        
        <!--
        <div class="cuadros">
            <div class="morena">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\morena.png">--
                    <h2>La Morena</h2>
                    <div class="texto">
                        <p>
                            En Grupo Morena incorporamos infraestructura de telecomunicaciones y seguridad, 
                            cuidando las estrictas normas de higiene en la producción de los alimentos.
                            Equipamos e instalamos la infraestructura dentro de la nueva nave de 
                            grupo La Morena de diversos sistemas de seguridad y telecomunicaciones.
                        </p>
                    </div>
                </div>
            </div>
            <div class="bimbo">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Bimbo</h2>
                    <div class="texto">
                        <p>
                            Mediante una red de telecomunicaciones IP, 
                            logramos poner en marcha la fase de control de calidad y monitoreo de sus procesos productivos.
                            <br>
                            <br>
                            Realizamos el acondicionamiento de cuarto de equipos, 
                            incluyendo aterrizaje de tierras físicas, aire acondicionado de precisión, enlaces de espejo y UPS.
                        </p>
                    </div>
                </div>
            </div>
            <div class="tres">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Maulec</h2>
                    <div class="texto">
                        <p>
                            Incorporamos mayor control de los procesos productivos, mediante una red de Voz, Datos y Video. 
                            Todas las instalaciones son realizadas bajo los más estrictos estándares de calidad e higiene.
                        </p>
                    </div>
                </div>
            </div>

        </div>-->
        <!--            FIN CUADROS             -->
        
        <!--
        <div class="cuadros">
            <div class="cuatro">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\morena.png">--
                    <h2>Mondelez Int</h2>
                    <div class="texto">
                        <p>
                            Llevamos a cabo el proyecto de instalación de infraestructura y equipamiento de red, 
                            para los centros de distribución.
                            <br>
                            Aplicamos los métodos y medidas necesarias para la renovación de equipamiento e 
                            infraestructura de red en el Área de Productividad, sin realizar un paro de producción.
                        </p>
                    </div>
                </div>
            </div>
            <div class="cinco">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Alpura</h2>
                    <div class="texto">
                        <p>
                            Integramos la seguridad en el proceso productivo mediante la instalación de equipos de monitoreo, 
                            control de acceso y sensores de humedad y humo para los cuartos de equipos.
                        </p>
                    </div>
                </div>
            </div>
            <div class="seis">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Kraft Food</h2>
                    <div class="texto">
                        <p>
                            Realizamos la aplicación de infraestructura de red industrial consistente en 
                            back bone de fibra óptica así como también la colocación de  IDF industriales.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        -->      <!--            FIN CUADROS 2           -->

    </div>
</div>
<app-new-footer></app-new-footer>
