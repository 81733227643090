<app-navigation-bar></app-navigation-bar>
<div id="escuelas-comunicacion">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-educacion">Educación</a> - <a href="#/e-seguridad">Seguridad</a> < <a href="#/e-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Education</h1>
        </div>
        <div>
            <div>
                <h3>Unified Communications</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>IP Commutator</h3>
                <p>
                    System for the telephone network, focused on interconnecting your internal and external communications.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>IP Voice</h3>
                <p>
                    Communicate announcements, notifications and alarms with a better quality.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Emergency posts</h3>
                <p>
                    Allows students and staff to notify the monitoring center of any incident.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Virtual classrooms</h3>
                <p>
                    Give classes at a distance, with properly equipped rooms for face-to-face and virtual assistance. <br>
                    Audio, Video, Interactive whiteboards ... Everything you need to make your classes a success.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>