<div class="container-redes">
	<!--<a href="#/principal">
		<img src="..\..\assets\Imagenes\rs\icono-idioma.png">
	</a>-->
  <!--
	<a href="https://api.whatsapp.com/send?phone=521&text=¿Qué servicios ofrecen?" target="_blank">
		<img src="..\..\assets\Imagenes\rs\icono-whatsapp.png" title="Envianos un mensaje por Whatsapp">
	</a>
  -->
	<a href="http://m.me/TBellTelecom" target="_blank">
		<img src="..\..\assets\Imagenes\rs\icono-messenger.png" title="Envianos un mensaje a nuestra página de Facebook">
	</a>
</div>
<!-- Messenger plugin de chat Code -->
    <div id="fb-root"></div>
      <script>
        window.fbAsyncInit = function() {
          FB.init({
            xfbml            : true,
            version          : 'v10.0'
          });
        };

        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      </script>

      <!-- Your plugin de chat code -->
      <div class="fb-customerchat"
        attribution="biz_inbox"
        page_id="1894660244190489">
      </div>