<app-navegador-n></app-navegador-n>
<div class="cuerpo">
    <div class="encabezado">
        <!--<app-slider></app-slider>-->
        <video src="../../assets/Videos/industria 4.0 redes industriales.mp4" autoplay="true" loop="true" muted="true"></video>
    </div>
    <div class="titulo">
        <h1>Nuestras soluciones y productos</h1>
    </div>
    <div class="cuadros">
        <div class="v1">
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\1.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\3.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\5.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\7.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\9.png">
            </div>
        </div>
        <div class="v2">
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\2.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\4.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\6.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\8.png">
            </div>
            <div class="imagenes">
                <img src="..\..\assets\Imagenes\productos\10.png">
            </div>
        </div>
    </div>
    <div class="catalogo">
        <!--<iframe src="https://docs.google.com/viewer?srcid=1-vBh5_5LgtBHQNwc9ddoekjJpZ2vg52H&pid=explorer&efh=false&a=v&chrome=false&embedded=true" width="100%" height="680px" frameborder="0"></iframe>-->
        <div class="pdf">
            <!--<embed src="..\..\assets\Documentos\Cátalogo T-Bell Telecom.pdf#page=1&toolbar=0&navpanes=0&scrollbar=0" type="application/pdf">-->
            <img src="..\..\assets\Imagenes\Catalogo-Portada.png">
            <h1>Descargar <br>Catalogo</h1>
            <a href="..\..\assets\Documentos\Cátalogo T-Bell Telecom.pdf" download="Cátalogo T-Bell Telecom.pdf">
                <div class="descarga">
                    Descarga aquí
                </div>
            </a>
        </div>
        <div class="pdf">
            <!--<embed src="..\..\assets\Documentos\Cátalogo T-Bell Telecom.pdf#page=1&toolbar=0&navpanes=0&scrollbar=0" type="application/pdf">-->
            <img src="..\..\assets\Imagenes\Catalogo-Portada.png">
            <h1>Download <br>catalogue</h1>
            <a href="..\..\assets\Documentos\catalogo en ingles t bell.pdf" download="Catalogue T-Bell Telecom.pdf">
                <div class="descarga">
                    Download here
                </div>
            </a>
        </div>
    </div>
    <app-new-footer></app-new-footer>
</div>