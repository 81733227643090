<app-navegador-n></app-navegador-n>
<div id="contenedor">
    <h1>¿Quiénes somos?</h1>
    <!--
    <div id="columna">
    	<div id="mivi">
        	<div id="mision">
            	<h2>Misión</h2>
            	<img src="..\..\assets\Imagenes\rs\mision.png" width="30%">
            	<p>Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional, preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor, distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano y de la aplicación y administración de tecnología certificada por nuestros proveedores, para permitirle a nuestros clientes lograr sus objetivos</p>
        	</div>
        	<div id="vision">
            	<h2>Visión</h2>
            	<img src="..\..\assets\Imagenes\rs\vision.png" width="30%">
            	<p>Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios. Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades.</p>
        	</div>
        </div>
        <div id="valores">
        	<h2>Valores</h2>
        	<img src="..\..\assets\Imagenes\rs\valor.png" width="15%">
        	<h3>Responsabilidad:</h3>
            <p>
                Somos responsables al cumplir con los compromisos y deberes, tanto en el trabajo como con la sociedad
            </p>
            <h3>Disciplina:</h3>
            <p>
                Respetamos las políticas y reglamentos de nuestra empresa (y la de nuestros clientes)
            </p>
            <h3>Honestidad:</h3>
            <p>
                Es un compromiso actuar con honestidad a la hora de trabajar y otorgar el servicio a nuestros clientes y ser congruentes con nuestros hechos y palabras.
            </p>
            <h3>Respeto:</h3>
            <p>
            Reconocemos que todas las personas dentro y fuera de la empresa deben ser tratadas sin distingo
            </p>
            <h3>Además</h3>
            <ul>
                <li>Trabajo en equipo</li>
                <li>Actitud de Servicio</li>
                <li>Calidad</li>
            </ul>
        </div>
    </div>
    -->
    <div id="cuerpo">
        <div id="info">
            <div id="mision">
                <div id="imagen1">
                    <!--<img src="..\..\assets\Imagenes\rs\mision.png" width="30%">-->    
                </div>
                <div id="texto">
                    <div class="subtitulo"><h2>Misión</h2></div>
                    <p>Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional, preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor, distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano y de la aplicación y administración de tecnología certificada por nuestros proveedores, para permitirle a nuestros clientes lograr sus objetivos</p>
                </div>
            </div>
            <div id="vision">
                <div id="texto">
                    <div class="subtitulo"><h2>Visión</h2></div>  
                    <p>Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios. Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades.</p>
                </div>
                <div id="imagen2">
                    <!--<img src="..\..\assets\Imagenes\rs\vision.png" width="30%">-->
                </div>
            </div>
            <div id="valor">
                <div id="imagen3">
                    <!--<img src="..\..\assets\Imagenes\rs\valor.png" width="30%">-->
                </div>
                <div id="texto">
                    <div class="subtitulo"><h2>Valores</h2></div>
                    <h3>Responsabilidad:</h3>
                    <p>
                        Somos responsables al cumplir con los compromisos y deberes, tanto en el trabajo como con la sociedad
                    </p>
                    <h3>Disciplina:</h3>
                    <p>
                        Respetamos las políticas y reglamentos de nuestra empresa (y la de nuestros clientes)
                    </p>
                    <h3>Honestidad:</h3>
                    <p>
                        Es un compromiso actuar con honestidad a la hora de trabajar y otorgar el servicio a nuestros clientes y ser congruentes con nuestros hechos y palabras.
                    </p>
                    <h3>Respeto:</h3>
                    <p>
                        Reconocemos que todas las personas dentro y fuera de la empresa deben ser tratadas sin distingo
                    </p>
                    <h3>Además</h3>
                    <ul>
                        <li>Trabajo en equipo</li>
                        <li>Actitud de Servicio</li>
                        <li>Calidad</li>
                    </ul>
                </div>  
            </div>
        </div>

        <div id="info2">
            <div id="mision">
                <div id="imagen1">
                </div>
                <div id="texto">
                    <div class="subtitulo"><h2>Misión</h2></div>
                    <p>Ser un grupo líder de soluciones integrales de redes y telecomunicaciones a nivel nacional, preocupándonos por la superación de nuestro personal para proporcionar a nuestros clientes servicios de gran valor, distinguiéndonos por la calidad y rapidez con que atendemos a sus necesidades, a través de nuestro talento humano y de la aplicación y administración de tecnología certificada por nuestros proveedores, para permitirle a nuestros clientes lograr sus objetivos</p>
                </div>
            </div>
            <div id="vision">
                <div id="imagen2">
                </div>
                <div id="texto">
                    <div class="subtitulo"><h2>Visión</h2></div>  
                    <p>Posicionarnos en el mercado nacional gracias a la calidad, innovación y tiempo de entrega de nuestros productos y servicios. Queremos tener el reconocimiento de nuestros clientes al brindar siempre la mejor solución a sus necesidades.</p>
                </div>
            </div>
            <div id="valor">
                <div id="imagen3">
                </div>
                <div id="texto">
                    <div class="subtitulo"><h2>Valores</h2></div>
                    <h3>Responsabilidad:</h3>
                    <p>
                        Somos responsables al cumplir con los compromisos y deberes, tanto en el trabajo como con la sociedad
                    </p>
                    <h3>Disciplina:</h3>
                    <p>
                        Respetamos las políticas y reglamentos de nuestra empresa (y la de nuestros clientes)
                    </p>
                    <h3>Honestidad:</h3>
                    <p>
                        Es un compromiso actuar con honestidad a la hora de trabajar y otorgar el servicio a nuestros clientes y ser congruentes con nuestros hechos y palabras.
                    </p>
                    <h3>Respeto:</h3>
                    <p>
                        Reconocemos que todas las personas dentro y fuera de la empresa deben ser tratadas sin distingo
                    </p>
                    <h3>Además</h3>
                    <ul>
                        <li>Trabajo en equipo</li>
                        <li>Actitud de Servicio</li>
                        <li>Calidad</li>
                    </ul>
                </div>  
            </div>
        </div>
    </div>
</div>
<app-new-footer></app-new-footer>