import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-seguridad',
  templateUrl: './e-seguridad.component.html',
  styleUrls: ['./e-seguridad.component.css']
})
export class ESeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
