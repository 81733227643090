<app-navegador-n></app-navegador-n>
<div class="cuerpo">
    <!--<h1 class="titulo">
        Casos de éxito
    </h1>
    <div class="cuadros">
        <div class="columna1">
            <div class="fila1">
                <a href="#/ce-industria-alimenticia">
                    <img src="../../assets/Imagenes/exito/IndustriaAlimenticia.png" alt="Alimenticia">
                </a>
                <h2>Industria Alimenticia</h2>
            </div>
            <div class="fila1">
                <a href="#/ce-corporativos">
                    <img src="../../assets/Imagenes/exito/Corporativos.png" alt="Corporativos">
                </a>                
                <h2>Corporativos</h2>
            </div>
        </div>
        <div class="columna2">
            <div class="fila2">
                <a href="#/ce-industria-automotriz">
                    <img src="../../assets/Imagenes/exito/IndustriaAutomotriz.png" alt="IndustriaAutomotriz">
                </a>
                <h2>Industria Automotriz</h2>
            </div>
            <div class="fila2">
                <a href="#/ce-gobierno">
                    <img src="../../assets/Imagenes/exito/Gobierno.png" alt="Gobierno">
                </a>
                <h2>Gobierno</h2>
            </div>
            <div class="fila2">
                --<a href="#/ce-infraestructura-critica">--
                    <img src="../../assets/Imagenes/exito/critica-sinfondo.png" alt="Critica">
                --</a>--
                <h2>Infraestructura Critica</h2>
            </div>
        </div>
        <div class="columna3">
            <div class="fila3">
                <a href="#/ce-comercio-servicios">
                    <img src="../../assets/Imagenes/exito/Comercio.png" alt="Comercio">
                </a>
                <h2>Comercio y servicios</h2>
            </div>
            <div class="fila3">
                <a href="#/ce-instituciones">
                    <img src="../../assets/Imagenes/exito/Instituciones.png" alt="Instituciones">
                </a>
                <h2>Instituciones</h2>
            </div>
        </div>
    </div>

    <div class="cuadros-movil">
        <div class="columna">
            <div class="fila">
                <a href="#/ce-industria-alimenticia">
                    <img src="../../assets/Imagenes/exito/IndustriaAlimenticia.png" alt="Alimenticia">
                </a>
                <h2>Industria Alimenticia</h2>
            </div>
            <div class="fila">
                <a href="#/ce-corporativos">
                    <img src="../../assets/Imagenes/exito/Corporativos.png" alt="Corporativos">
                </a>
                <h2>Corporativos</h2>
            </div>
            <div class="fila">
                <a href="#/ce-industria-automotriz">
                    <img src="../../assets/Imagenes/exito/IndustriaAutomotriz.png" alt="IndustriaAutomotriz">
                </a>
                <h2>Industria Automotriz</h2>
            </div>
            <div class="fila">
                <a href="#/ce-gobierno">
                    <img src="../../assets/Imagenes/exito/Gobierno.png" alt="Gobierno">
                </a>
                <h2>Gobierno</h2>
            </div>
            <div class="fila">
                --<a href="#/ce-infraestructura-critica">--
                    <img src="../../assets/Imagenes/exito/critica-sinfondo.png" alt="Critica">
                --</a>--
                <h2>Infraestructura Critica</h2>
            </div>
            <div class="fila">
                <a href="#/ce-comercio-servicios">
                    <img src="../../assets/Imagenes/exito/Comercio.png" alt="Comercio">
                </a>
                <h2>Comercio y servicios</h2>
            </div>
            <div class="fila">
                <a href="#/ce-instituciones">
                    <img src="../../assets/Imagenes/exito/Instituciones.png" alt="Instituciones">
                </a>
                <h2>Instituciones</h2>
            </div>
        </div>
    </div>-->
    <div class="video">
        <video src="..\..\assets\Videos\video casos de exito.mp4" autoplay="true" loop="true" muted="true"></video>
    </div>
    <div class="casos-de-exito">
        <div class="titulo">
            <h1>CASOS DE EXITO</h1>
        </div>
        <div class="circulos">
            <div class="v1">
                <div class="i1">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\industria-alimentaria.png">
                    <a [routerLink]="['/ce-industria-alimenticia']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\industria-alimentaria-txt.png">
                    </a>
                </div>
                <div class="i2">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\gobierno.png">
                    <a [routerLink]="['/ce-gobierno']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\gobierno-txt.png">
                    </a>
                </div>
                <div class="i2">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\residencias.png">
                    <a [routerLink]="['/ce-residencias']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\residencias-txt.png">
                    </a>
                </div>
            </div>
            <div class="v2">
                <div class="i3">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\industria-automotriz.png">
                    <a [routerLink]="['/ce-industria-automotriz']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\industria-automotriz-txt.png">
                    </a>
                </div>
                <div class="i4">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\instituciones.png">
                    <a [routerLink]="['/ce-instituciones']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\instituciones-txt.png">
                    </a>
                </div>
                <div class="i7">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\circulo-vacio.png">
                </div>
            </div>
            <div class="v3">
                <div class="i5">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\comercio-y-servicios.png">
                    <a [routerLink]="['/ce-comercio-servicios']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\comercio-y-servicios-txt.png">
                    </a>
                </div>
                <div class="i6">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\corporativos.png">
                    <a [routerLink]="['/ce-corporativos']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\corporativos-txt.png">
                    </a>
                </div>
                <div class="i6">
                    <img src="..\..\assets\Imagenes\casos-de-exito\r\infraestructura-critica.png">
                    <a [routerLink]="['/ce-infraestructura-critica']">
                        <img src="..\..\assets\Imagenes\casos-de-exito\r\infraestructura-critica-txt.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-new-footer></app-new-footer>