import { Component, OnInit } from '@angular/core';
import { CargarScriptsService } from '../cargar-scripts.service';

@Component({
  selector: 'app-nav-prueba',
  templateUrl: './nav-prueba.component.html',
  styleUrls: ['./nav-prueba.component.css']
})
export class NavPruebaComponent implements OnInit {

  constructor(private _CargaScripts:CargarScriptsService) {
    _CargaScripts.Carga(["barra"]);
  }

  ngOnInit(): void {
  }

}
