<app-navigation-bar></app-navigation-bar>
<div id="industria-seguridad">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industry</a> - <a href="#/industry-telecomm-infrastructure">Telecommunications infrastructure</a> < <a href="#/industry-electronic-security">Electronic Security</a> > <a href="#/industry-unified-comm">Unified Communications</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industry</h1>
        </div>
        <div>
            <div>
                <h3>Electronic Security</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV & Voice</h3>
                <p>
                    Closed-circuit television (server, VMS, cameras) to keep under surveillance any area. Some models can incluide speakers, which can be use to give alerts in case of an emergency, give announcements and give warnings.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Perimeter security</h3>
                <p>
                    Keeps safe your company sorroundings through prowling cameras, geo fence, and so on.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Access control</h3>
                <p>
                    This sistem allows or restricts the access to a specific area. It can also helps to control the access to restricted areas or where the personnel is limited.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Biometric reader</h3>
                <p>
                    Authenticates a person's identity through fingerprint or face reader; helping to reduce infection sources. It can also restring the access to a certain area.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <div id="socios">
        <div id="subti">
            <h1>Technology Partners</h1>
        </div>
        <div id="logos">
            <div id="uno">
                <div id="panduit">
                    <img src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                </div>
                <div id="cisco">
                    <img src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                </div>
                <div id="extreme">
                    <img src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                </div>
                <div id="dos">
                    <img src="..\..\assets\Imagenes\certi-color\c-rockwell.png">
                    
                </div>
                <div id="tres">
                    <img src="..\..\assets\Imagenes\certi-color\c-hirschmann.png">
                </div>
                <div id="total-g">
                    <img src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                </div>
                <div id="allen">
                    <img src="..\..\assets\Imagenes\certi-color\c-allen.png">
                </div>
                <div id="planet">
                    <img src="..\..\assets\Imagenes\certi-color\c-planet.png">
                </div>
            </div>
        </div>
    </div><!--CIERRE SOCIOS-->
    <app-footer-page></app-footer-page>
</div>