import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daohang-lan',
  templateUrl: './daohang-lan.component.html',
  styleUrls: ['./daohang-lan.component.css']
})
export class DaohangLanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
