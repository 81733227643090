<app-navigation-bar></app-navigation-bar>
<div id="industria-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industry</a> - <a href="#/industry-telecomm-infrastructure">Telecommunications Infrastructure</a> > <a href="#/industry-electronic-security">Electronic Security</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industry</h1>
        </div>
        <div>
            <div>
                <h3>Telecommunications Infrastructure</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Data Center</h3>
                <p>
                    Store a big amount of data, that can be crucial within the services.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Industrial Distribution Frame (IDF)</h3>
                <p>
                    At the industry, an IDF can help to extend the communications connections between equipments, voice and data (LAN).
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Industrial Wiring</h3>
                <p>
                    Create communication between equipments. This wire has some specific characteristics, for example, it can be for interiors or exteriors.
                </p>
            </div>
            <div id="imagen2"></div>
        </div>
        <div id="switches">
            <div id="texto">
                <h3>NAT Switches / Translators</h3>
                <p>
                    Access point that helps spread the network wirelessly, allowing greater speed and coverage.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="access-point">
            <div id="imagen">
            </div>
            <div id="texto">
                <h3>Industrial AP</h3>
                <p>
                    Access point that helps to propagate the network in a wireless way. This allows having a better coverage and higher speed.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>