import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ci-infraestructura',
  templateUrl: './ci-infraestructura.component.html',
  styleUrls: ['./ci-infraestructura.component.css']
})
export class CiInfraestructuraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
