import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-com-uni',
  templateUrl: './ic-com-uni.component.html',
  styleUrls: ['./ic-com-uni.component.css']
})
export class IcComUniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
