<!--
<header>
    <div class="menu_bar">
        <a href="#" class="bt-menu"><span class="icon-list2"></span>Menu</a>
    </div>

    <nav>
        <ul>
            <li><a href=""><span class="icon-house"></span>Inicio</a></li>
            <li><a href=""><span class="icon-suitcase"></span>Trabajos</a></li>
            <li><a href=""><span class="icon-rocket"></span>Proyectos</a></li>
            <li><a href=""><span class="icon-earth"></span>Servicios</a></li>
            <li><a href=""><span class="icon-mail"></span>Contactos</a></li>
        </ul>
    </nav>
</header>

<script>
    $(document).ready(main);

var contador = 1;

function main(){
	$('.menu_bar').click(function(){
		// $('nav').toggle(); 

		if(contador == 1){
			$('nav').animate({
				left: '0'
			});
			contador = 0;
		} else {
			contador = 1;
			$('nav').animate({
				left: '-100%'
			});
		}

	});

};
</script>
-->

<header class="header">
	<nav class="nav">
		<a href="#/principal" class="logo nav-link" aria-label="logo"><img class="logo-img" src="..\..\assets\Imagenes\logostbell\T-Bell 2016 Invertido transparente.png" alt=""></a>
		<button class="nav-toggle" aria-label="Abrir menú">
			<i class="fas fa-bars"></i>
		</button>
		<ul class="nav-menu">
			<li class="nav-menu-item">
				<a href="#/quienes-somos" class="nav-menu-link nav-link">Quiénes somos</a>
			</li>
			<li class="nav-menu-item">
				<a href="#/que-hacemos" class="nav-menu-link nav-link">Que hacemos</a>
			</li>
			<li class="nav-menu-item">
		  		<a href="#/donde-trabajamos" class="nav-menu-link nav-link">Donde trabajamos</a>
			</li>
			<li class="nav-menu-item">
		  		<a href="#/blog" class="nav-menu-link nav-link">Blog</a>
			</li>
			<li class="nav-menu-item">
		  		<a href="https://t-bell.mercadoshops.com.mx/" class="nav-menu-link nav-link nav-menu-link_active" target="_blank">Tienda</a>
			</li>
			<li class="nav-menu-item">
				<a href="#/contacto" class="nav-menu-link nav-link">Contacto</a>
			</li>
	  	</ul>
	</nav>
	<!--
	<script>
		const navToggle = document.querySelector(".nav-toggle");
		const navMenu = document.querySelector(".nav-menu");
	
		navToggle.addEventListener("click", () => {
		  navMenu.classList.toggle("nav-menu_visible");
	
		  if (navMenu.classList.contains("nav-menu_visible")) {
			navToggle.setAttribute("aria-label", "Cerrar menú");
		  } else {
			navToggle.setAttribute("aria-label", "Abrir menú");
		  }
		});
	</script>
	-->
  </header>