
<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Business And Services</h1>
</div>
<div class="texto">
    <p>
        Centralization of the integration operation of various branches with the parent company, through the implementation of network infrastructure projects, active equipment and IP telephony.
    </p>
</div>
<div class="imagen">
    <img src="../../assets/Imagenes/exito/sub/ComercioYServicios.jpg" alt="Comercio">
</div>
<app-footer-page></app-footer-page>