<app-navegador-n></app-navegador-n>
<!--<app-navegador></app-navegador>
<div id="sic">
    <div id="info">
    	<div id="izquierda">
    		<div id="vacio"></div>
        	<h1>En Donde Trabajamos</h1>
        	--<h3>
            	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut diam non libero lacinia dapibus et eu augue. Etiam nec vulputate quam. Donec ultrices, elit vel rutrum pharetra, elit leo tincidunt enim, nec fringilla ipsum felis sed nunc. Nulla pharetra rhoncus tellus, vel molestie magna finibus eu. Nam sed quam augue. Sed euismod lacus eu aliquet pretium. Quisque vel ex neque. Maecenas quis pharetra lectus. Proin lacinia sit amet mi sit amet rhoncus.
        	</h3>-
        	<h3>
                <a href="#/dt-corporativos">Corporativos</a> - 
        		<a href="#/dt-industria">Industria</a> - 
        		<a href="#/dt-infraestructura-de-telecom">Infraestructura critica</a> - 
        		<a href="#/dt-ciudades-inteligentes">Ciudadesd Inteligentes</a> - 
        		<a href="#/dt-escuelas">Educación</a>
        	</h3>
    	</div>
    </div>
</div>-->
<div class="base">
	<div class="video">
        <video src="..\..\assets\Videos\donde trabajamos.mp4" autoplay="true" loop="true" muted="true"></video>
    </div>
	<div class="lines2">
		<div class="linea1-guinda"></div>
		<div class="linea2-azul"></div>
	</div>
	<div class="dt-titulo">
		<h1>¿Dónde Trabajamos?</h1>
	</div>
	<div class="sub1">
		<div class="h1">
			<div class="imagenes">
				<a [routerLink]="['/dt-corporativos']">
					<img src="..\..\assets\Imagenes\nuevas\4.png">
					<h1>Corporativos</h1>
				</a>
			</div>
			<div class="imagenes">
				<a [routerLink]="['/dt-industria']">
					<img src="..\..\assets\Imagenes\nuevas\5.png">
					<h1>Industria</h1>
				</a>
			</div>
			<div class="imagenes">
				<a [routerLink]="['/dt-educacion']">
					<img src="..\..\assets\Imagenes\nuevas\3.png">
					<h1>Educación</h1>
				</a>
			</div>
		</div>
		<div class="h2">
			<div class="imagenes">
				<a [routerLink]="['/dt-infraestructura-critica']">
					<img src="..\..\assets\Imagenes\nuevas\6.png">
					<h1>Infraestructura Critica</h1>
				</a>
			</div>
			<div class="imagenes">
				<a [routerLink]="['/dt-ciudades-inteligentes']">
					<img src="..\..\assets\Imagenes\nuevas\2.png">
					<h1>Ciudades Inteligentes</h1>
				</a>				
			</div>
		</div>
	</div>
	<app-new-footer></app-new-footer>
</div>