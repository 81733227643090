<app-navegador-n></app-navegador-n>
<div id="industria-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industria</a> - <a href="#/i-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/i-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industria</h1>
        </div>
        <div>
            <div>
                <h3>Infraestructura de Telecomunicaciones</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Data Center</h3>
                <p>
                    Almacena una gran cantidad de datos dentro de la organización que pueden ser críticos para los servicios.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Industrial Distribution Frame (IDF)</h3>
                <p>
                    Extiende el alcance de las comunicaciones entre equipos, voz y datos (LAN).
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Cableado Industrial</h3>
                <p>
                    Medio por el cual se propagan los datos y se crea la comunicación entre equipos, contando con ciertos atributos específicos (interiores - exteriores).
                </p>
            </div>
            <div id="imagen2"></div>
        </div>
        <div id="switches">
            <div id="texto">
                <h3>Switches / Traductores NAT</h3>
                <p>
                    Los sistemas de control con configuraciones idénticas se pueden comunicar a través de redes Ethernet sin necesidad de reconfigurar los parámetros de red utilizando un dispositivo traductor de direcciones de red (NAT) para asignar direcciones IP de la subred Empresarial a direcciones IP en la red de control.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="access-point">
            <div id="imagen">
            </div>
            <div id="texto">
                <h3>AP Industriales</h3>
                <p>
                    Punto de acceso que ayuda a propagar la red de manera inalámbrica, lo que permite mayor velocidad y cobertura.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>