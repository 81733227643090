<app-navigation-bar></app-navigation-bar>
<div id="critica-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-infraestructura-critica">Infraestructura Critica</a> - <a href="#/ic-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/ic-seguridad">Seguridad</a>
        </p>
    </div>--CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Critical Infrastructure</h1>
        </div>
        <div>
            <div>
                <h3>Telecommunications Infrastructure</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Data Center</h3>
                <p>
                    Store a big amount of data, that can be crucial within the services.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>MDF / IDF</h3>
                <p>
                    Can help to extend the communications connections between equipments, voice and data (LAN).
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Copper / fiber wiring</h3>
                <p>
                    Helps to enhance the data communications infrastructure, covering longest distances in less time.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Microwave frequency E band</h3>
                <p>
                    Intercommunicate, even the most difficult networks to reach, in a wireless way.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>