import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-infraestructura',
  templateUrl: './c-infraestructura.component.html',
  styleUrls: ['./c-infraestructura.component.css']
})
export class CInfraestructuraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
