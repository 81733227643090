import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qh-colaboracion-remota',
  templateUrl: './qh-colaboracion-remota.component.html',
  styleUrls: ['./qh-colaboracion-remota.component.css']
})
export class QhColaboracionRemotaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
