import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-critical-infrastructure-electronic-security',
  templateUrl: './critical-infrastructure-electronic-security.component.html',
  styleUrls: ['./critical-infrastructure-electronic-security.component.css']
})
export class CriticalInfrastructureElectronicSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
