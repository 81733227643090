<!--
<header>
<div id="encabezado">
<nav id="nav">
    <ul class="container">
        <li><a href="#/home"><img id="logo" src="..\..\assets\Imagenes\tbell.png">Home</a></li>
        <li><a href="#pprincipal">Inicio</a></li>
        <li><a href="#/about-us">About us</a></li>
        <li><a href="#/what-we-do">What we do</a>
            <ul>
                <li><a href="#/qh-infraestructura-de-telecom">Telecommunications Infrastructure</a>
                    <ul>
                        <li><a href="#/i-infraestructura">Industry</a></li>
                        <li><a href="#/ic-infraestructura">Critical Infrastructure</a></li>
                        <li><a href="#/ci-infraestructura">Smart Cities</a></li>
                        <li><a href="#/c-infraestructura">Corporate</a></li>
                        <li><a href="#/e-infraestructura">Schools</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-seguridad">Electronic Security</a>
                    <ul>
                        <li><a href="#/i-seguridad">Industry</a></li>
                        <li><a href="#/ic-seguridad">Critical Infrastructure</a></li>
                        <li><a href="#/ci-seguridad">Smart Cities</a></li>
                        <li><a href="#/c-seguridad">Corporate</a></li>
                        <li><a href="#/e-seguridad">Schools</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-comunicaciones-unificadas">Unified Communications</a>
                    <ul>
                        <li><a href="#/i-com-uni">Industry</a></li>
                        <li><a href="#/ic-com-uni">Critical Infrastructure</a></li>
                        <li><a href="#/ci-com-uni">Smart Cities</a></li>
                        <li><a href="#/c-com-uni">Corporate</a></li>
                        <li><a href="#/e-com-uni">Schools</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-logistica">Logistics</a>
                    <ul class="logi-cola">
                        <li><a href="#/i-logistica">Industry</a></li>
                        <li><a href="#/ci-logistica">Smart Cities</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-colaboracion-remota">Remote Colaboration</a>
                    <ul class="logi-cola">
                        <li><a href="#/i-colab-remota">Industry</a></li>
                        <li><a href="#/ci-colab-remota">Smart Cities</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="#/where-we-work">Where we work</a>
            <ul>
                <li><a href="#/dt-corporativos">Corporate</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/corporate-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                        <li><a href="#/corporate-electronic-security">Electronic Security</a></li>
                        <li><a href="#/corporate-unified-comm">Unified Communications</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-industria">Industry</a>
                    <ul class="para-quien">
                        <li><a href="#/industry-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                        <li><a href="#/industry-electronic-security">Electronic Security</a></li>
                        <li><a href="#/industry-unified-comm">Unified Communications</a></li>
                        <li><a href="#/industry-logistics">Logistics</a></li>
                        <li><a href="#/industry-remote-collaboration">Remote Collaboration</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-infraestructura-critica">Critical Infrastructure</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/critical-infrastructure-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                        <li><a href="#/critical-infrastructure-electronic-security">Electronic Security</a></li>
                        <li><a href="#/critical-infrastructure-unified-comm">Unified Communications</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-ciudades-inteligentes">Smart Cities</a>
                    <ul class="para-quien">
                        <li><a href="#/smart-cities-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                        <li><a href="#/smart-cities-electronic-security">Electronic Security</a></li>
                        <li><a href="#/smart-cities-unified-comm">Unified Communications</a></li>
                        <li><a href="#/smart-cities-logistics">Logistics</a></li>
                        <li><a href="#/smart-cities-remote-collaboration">Remote Collaboration</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-escuelas">Education</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/education-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                        <li><a href="#/education-electronic-security">Electronic Security</a></li>
                        <li><a href="#/education-unified-comm">Unified Communications</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="#/blog">Blog</a></li>
        <li><a href="https://t-bell.mercadoshops.com.mx/" id="link-tienda">Store</a></li>
        <li><a href="#/contact">Contact</a></li>
        <li><a href="#/language">language</a>
            <ul class="para-quien">
                <li><a href="#/principal">Spanish / Español</a></li>
                <li><a href="#/home">English / Inglés</a></li>
            </ul>
        </li>
    </ul>
</nav>
</div>
</header>
-->
<div class="base">
    <div class="menu-idioma">
        <ul>
            <li>
                <i class="fas fa-language"></i>
                <i class="fas fa-caret-down"></i>
                <!--Language-->
                <ul>
                    <li>
                        <a href="#/principal">MX</a>
                    </li>
                    <li>
                        <a href="#/home">ENG</a>
                    </li>
                    <!--<li>CH</li>-->
                </ul>
            </li>
        </ul>
    </div>
    <div class="menu1080">
        <div class="contenedor1080">
            <div class="logo1080">
                <a href="#/home" class="logo" aria-label="logo">
                    <img class="logo-img" src="../../assets/Imagenes/logostbell/T-Bell-2016-Guinda-png.png" alt="logo">
                </a>
            </div>

            <div class="enlaces1080">
                <ul class="ul1080">
                    <li><a href="#/home">Home</a></li>
                    <li><a href="#/about-us">About Us</a></li>
                    <li id="ul-que"><a href="#/whatwedo">What we do </a>
                        <i class="fas fa-caret-down"></i>
                        <ul class="sub-ul1080">
                            <li id="li-sub1"><a href="#/telecommunicationsinfrastructure">Telecommunications Infrastructure</a>
                                <ul class="subsub1">
                                    <li><a href="#/industry-telecomm-infrastructure">Industry</a></li>
                                    <li><a href="#/critical-infrastructure-telecomm-infrastructure">Critical Infrastructure</a></li>
                                    <li><a href="#/smart-cities-telecomm-infrastructure">Smart Cities</a></li>
                                    <li><a href="#/corporate-telecomm-infrastructure">Corporate</a></li>
                                    <li><a href="#/education-telecomm-infrastructure">Education</a></li>
                                </ul>
                            </li>
                            <li id="li-sub2"><a href="#/electronicsecurity">Electronic Security</a>
                                <ul class="subsub2">
                                    <li><a href="#/industry-electronic-security">Industry</a></li>
                                    <li><a href="#/critical-infrastructure-electronic-security">Critical Infrastructure</a></li>
                                    <li><a href="#/smart-cities-electronic-security">Smart Cities</a></li>
                                    <li><a href="#/corporate-electronic-security">Corporate</a></li>
                                    <li><a href="#/education-electronic-security">Education</a></li>
                                </ul>
                            </li>
                            <li id="li-sub3"><a href="#/unifiedcommunications">Unified Communications</a>
                                <ul class="subsub3">
                                    <li><a href="#/industry-unified-comm">Industry</a></li>
                                    <li><a href="#/critical-infrastructure-unified-comm">Critical Infrastructure</a></li>
                                    <li><a href="#/smart-cities-unified-comm">Smart Cities</a></li>
                                    <li><a href="#/corporate-unified-comm">Corporate</a></li>
                                    <li><a href="#/education-unified-comm">Education</a></li>
                                </ul>
                            </li>
                            <li id="li-sub4"><a href="#/logistics">Logistics</a>
                                <ul class="subsub4">
                                    <li><a href="#/industry-logistics">Industry</a></li>
                                    <li><a href="#/smart-cities-logistics">Smart Cities</a></li>
                                </ul>
                            </li>
                            <li id="li-sub5"><a href="#/remotecollaboration">Remote Colaboration</a>
                                <ul class="subsub5">
                                    <li><a href="#/industry-remote-collaboration">Industry</a></li>
                                    <li><a href="#/smart-cities-remote-collaboration">Smart Cities</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li id="ul-donde"><a href="#/wherewework">Where we work </a>
                        <i class="fas fa-caret-down"></i>
                        <ul class="dub-ul1080">
                            <li id="li-dub1"><a href="#/corporate">Corporate</a>
                                <ul class="dubdub1">
                                    <li><a href="#/corporate-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                                    <li><a href="#/corporate-electronic-security">Electronic Security</a></li>
                                    <li><a href="#/corporate-unified-comm">Unified Communications</a></li>
                                </ul>
                            </li>
                            <li id="li-dub2"><a href="#/industry">Industry</a>
                                <ul class="dubdub2">
                                    <li><a href="#/industry-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                                    <li><a href="#/industry-electronic-security">Electronic Security</a></li>
                                    <li><a href="#/industry-unified-comm">Unified Communications</a></li>
                                    <li><a href="#/industry-logistics">Logistics</a></li>
                                    <li><a href="#/industry-remote-collaboration">Remote Collaboration</a></li>
                                </ul>
                            </li>
                            <li id="li-dub3"><a href="#/criticalinfrastructure">Critical Infrastructure</a>
                                <ul class="dubdub3">
                                    <li><a href="#/critical-infrastructure-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                                    <li><a href="#/critical-infrastructure-electronic-security">Electronic Security</a></li>
                                    <li><a href="#/critical-infrastructure-unified-comm">Unified Communications</a></li>
                                </ul>
                            </li>
                            <li id="li-dub4"><a href="#/smartcities">Smart Cities</a>
                                <ul class="dubdub4">
                                    <li><a href="#/smart-cities-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                                    <li><a href="#/smart-cities-electronic-security">Electronic Security</a></li>
                                    <li><a href="#/smart-cities-unified-comm">Unified Communications</a></li>
                                    <li><a href="#/smart-cities-logistics">Logistics</a></li>
                                    <li><a href="#/smart-cities-remote-collaboration">Remote Collaboration</a></li>
                                </ul>
                            </li>
                            <li id="li-dub5"><a href="#/educacion">Education</a>
                                <ul class="dubdub5">
                                    <li><a href="#/education-telecomm-infrastructure">Telecommunications Infrastructure</a></li>
                                    <li><a href="#/education-electronic-security">Electronic Security</a></li>
                                    <li><a href="#/education-unified-comm">Unified Communications</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><a href="#/success-stories">Success Stories</a></li>
                    <li><a href="#/blog-f-en">Blog</a></li>
                    <li><a href="#/contact">Contact</a></li>
                </ul>
            </div>
            <div class="tienda1080">
                <button>
                    <a class="btn-menu-shop" id="btn-menu-shop" href="https://t-bell.mercadoshops.com.mx/" target="_blank">
                        <i class="fas fa-shopping-cart"></i>
                    </a>
                </button>
                <ul class="i1080ul">
                    <li>
                        <i class="fas fa-globe-americas"></i>
                        <ul>
                            <li><a href="">MX</a></li>
                            <li><a href="">ENG</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <nav class="menu" id="menu">
        <div class="contenedor contenedor-botones-menu">
            <button class="btn-menu-barras" id="btn-menu-barras">
                <i class="fas fa-bars"></i>
            </button>
            <a href="#/home" class="logo nav-link" aria-label="logo">
                <img class="logo-img" src="../../assets/Imagenes/logostbell/T-Bell-2016-Guinda-png.png" alt="logo">
            </a>
            <!--
            <div class="botones-menu-sl">
                <button>
                    <i class="fas fa-globe"></i>
                    <i class="fas fa-language"></i>
                    <ul class="ul-idiomas">
                        <li>MX</li>
                        <li>ENG</li>
                        <li>CH</li>
                    </ul>
                </button>
                -->
                <button>
                    <a class="btn-menu-shop" id="btn-menu-shop" href="https://t-bell.mercadoshops.com.mx/" target="_blank">
                        <i class="fas fa-shopping-cart"></i>
                    </a>
                </button>
            <!--</div>-->
        </div>
        <div class="contenedor-enlaces-nav">
            <button id="btn-menu-cerrar" class="btn-menu-cerrar">
                <i class="fas fa-times"></i>
            </button>
            <div class="enlaces">
                <a href="#/home">Home</a>
                <a href="#/about-us">About Us</a>
            </div>
            <div class="btn-botones" id="btn-hacemos">
                <p>What we do</p>
                <i class="fas fa-caret-right"></i>
            </div>
            <div class="btn-botones" id="btn-donde">
                <p>Where we work</p>
                <i class="fas fa-caret-right"></i>
            </div>
            <div class="enlaces">
                <a href="#/success-stories">Success Stories</a>
                <a href="#/blog-f-en">Blog</a>
                <a href="#/contact">Contact</a>
            </div>
            <div class="btn-botones" id="btn-idioma">
                <p>Language</p>
                <i class="fas fa-caret-right"></i>
            </div>
        </div>
        <div class="contenedor contenedor-grid">
            <div class="grid" id="grid">
                <div class="categorias">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Back
                    </button>
                    <h3 class="subtitulo">Where we work</h3>
                    <a href="#/corporate" data-categoria="corporativos" id="a-corporativo">
                        Corporate <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subcorporativos" data-categoria="corporativos" id="ir-corporativos">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Corporate</h3>
                            <a href="#/corporate-telecomm-infrastructure">Telecommunications Infrastructure</a>
                            <a href="#/corporate-electronic-security">Electronic Security</a>
                            <a href="#/corporate-unified-comm">Unified Communications</a>
                        </div>
                    </div>
                    <a href="#/industry" data-categoria="industria" id="a-industria">
                        Industry <i class="fas fa-angle-right"></i>                            
                    </a>
                    <div class="subindustria" data-categoria="industria" id="ir-industria">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Industry</h3>
                            <a href="#/industry-telecomm-infrastructure">Telecommunications Infrastructure</a>
                            <a href="#/industry-electronic-security">Electronic Security</a>
                            <a href="#/industry-unified-comm">Unified Communications</a>
                            <a href="#/industry-logistics">Logistics</a>
                            <a href="#/industry-remote-collaboration">Remote Colaboration</a>
                        </div>
                    </div>
                    <a href="#/criticalinfrastructure" data-categoria="infraestructura-critica" id="a-critica">
                        Critical Infrastructure <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subcritica" data-categoria="infraestructura-critica" id="ir-critica">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Critical Infrastructure</h3>
                            <a href="#/critical-infrastructure-telecomm-infrastructure">Telecommunications Infrastructure</a>
                            <a href="#/critical-infrastructure-electronic-security">Electronic Security</a>
                            <a href="#/critical-infrastructure-unified-comm">Unified Communications</a>
                        </div>
                    </div>
                    <a href="#/smartcities" data-categoria="ciudades-inteligentes" id="a-ciudades">
                        Smart Cities <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subciudades" data-categoria="ciudades-inteligentes" id="ir-ciudades">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Smart Cities</h3>
                            <a href="#/smart-cities-telecomm-infrastructure">Telecommunications Infrastructure</a>
                            <a href="#/smart-cities-electronic-security">Electronic Security</a>
                            <a href="#/smart-cities-unified-comm">Unified Communications</a>
                            <a href="#/smart-cities-logistics">Logistics</a>
                            <a href="#/smart-cities-remote-collaboration">Remote Collaboration</a>
                        </div>
                    </div>
                    <a href="#education" data-categoria="educacion" id="a-educacion">
                        Education <i class="fas fa-angle-right"></i>
                    </a>
                    <div class="subeducacion" data-categoria="educacion" id="ir-educacion">
                        <div class="enlaces-subcategoria">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Education</h3>
                            <a href="#/education-telecomm-infrastructure">Telecommunications Infrastructure</a>
                            <a href="#/education-electronic-security">Electronic Security</a>
                            <a href="#/education-unified-comm">Unified Communications</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="contenedor contenedor-idiomas">
            <div class="idiomas" id="idiomas">
                <div class="lista">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Back
                    </button>
                    <h3 class="subtitulo">Language</h3>
                    <a href="#/principal">
                        Español
                    </a>
                    <a href="#/home">
                        English
                    </a>
                </div>
            </div>
        </div>

        <div class="contenedor contenedor-que">
            <div class="que" id="que">
                <div class="que-hacemos">
                    <button class="btn-regresar">
                        <i class="fas fa-arrow-left"></i>
                        Back
                    </button>
                    <h3 class="subtitulo">What we do</h3>
                    <a href="#/telecommunicationsinfrastructure" id="a-infra-telecom">
                        Telecommunications Infrastructure <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/electronicsecurity" id="a-seguridad">
                        Electronic Security <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/unifiedcommunications" id="a-comunicaciones">
                        Unified Communications <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/logistics" id="a-logistica">
                        Logistics <i class="fas fa-angle-right"></i>
                    </a>
                    <a href="#/remotecollaboration" id="a-colaboracion">
                        Remote Collaboration <i class="fas fa-angle-right"></i>
                    </a>

                    <div class="queinfraestructura" id="ir-infra-telecom">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Telecommunications Infrastructure</h3>
                            <a href="#/corporate-telecomm-infrastructure">Corporate</a>
                            <a href="#/industry-telecomm-infrastructure">Industry</a>
                            <a href="#/critical-infrastructure-telecomm-infrastructure">Critical Infrastructure</a>
                            <a href="#/smart-cities-telecomm-infrastructure">Smart Cities</a>
                            <a href="#/education-telecomm-infrastructure">Education</a>
                        </div>
                    </div>
                    <div class="queseguridad" id="ir-seguridad">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Electronic Security</h3>
                            <a href="#/corporate-electronic-security">Corporate</a>
                            <a href="#/industry-electronic-security">Industry</a>
                            <a href="#/critical-infrastructure-electronic-security">Critical Infrastructure</a>
                            <a href="#/smart-cities-electronic-security">Smart Cities</a>
                            <a href="#/education-electronic-security">Education</a>
                        </div>
                    </div>
                    <div class="quecomunicaciones" id="ir-comunicaciones">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Unified Communications</h3>
                            <a [routerLink]="['/corporate-unified-comm']">Corporate</a>
                            <a [routerLink]="['/industry-unified-comm']">Industry</a>
                            <a [routerLink]="['/critical-infrastructure-unified-comm']">Critical Infrastructure</a>
                            <a [routerLink]="['/smart-cities-unified-comm']">Smart Cities</a>
                            <a [routerLink]="['/education-unified-comm']">Education</a>
                        </div>
                    </div>
                    <div class="quelogistica" id="ir-logistica">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Logistics</h3>
                            <a href="#/industry-logistics">Industry</a>
                            <a href="#/smart-cities-logistics">Smart Cities</a>
                        </div>
                    </div>
                    <div class="quecolaboracion" id="ir-colaboracion">
                        <div class="enlaces-que-hacemos">
                            <button class="btn-regresar">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </button>
                            <h3 class="subtitulo">Remote Collaboration</h3>
                            <a href="#/industry-remote-collaboration">Industry</a>
                            <a href="#/smart-cities-remote-collaboration">Smart Cities</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </nav>
    <div class="linea-divisora"></div>
</div>