import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-telecomm-infrastructure',
  templateUrl: './corporate-telecomm-infrastructure.component.html',
  styleUrls: ['./corporate-telecomm-infrastructure.component.css']
})
export class CorporateTelecommInfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
