import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ce-instituciones',
  templateUrl: './ce-instituciones.component.html',
  styleUrls: ['./ce-instituciones.component.css']
})
export class CeInstitucionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
