<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Food Industry</h1>
</div>
<div>
    <div class="texto-b">
        <p>
            Greater efficiency and control in the analysis of processes through the installation and maintenance of security cameras in production lines. All this under the strict safety and hygiene standards that the industry requires.
        </p>
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Alimenticia.jpg" alt="">
    </div>
    <div class="texto">
        <p>
            Great quality control and process monitoring through an IP telecommunications network. Equipment room conditioning was performed, including physical ground landing, precision air conditioning, mirror links, UPS, and physical grounds.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Development of telecommunications and security infrastructure in the food industry, taking care of strict hygiene standards in food production.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Alimenticia2.jpg" alt="">
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Alimenticia3.jpg" alt="">
    </div>
    <div class="texto">
        <p>
            Safety in the production process through the installation of monitoring equipment, access control and humidity or smoke sensors for the equipment rooms.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Greater control of production processes, through a voice, data and video network. All installations are carried out under the strictest quality and hygiene standards.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Alimenticia4.jpg" alt="">
    </div>
</div>
<div class="division1">
    <div class="imagen1">
        <img src="../../assets/Imagenes/exito/sub/Alimenticia5.jpg" alt="">
    </div>
</div>
<app-footer-page></app-footer-page>