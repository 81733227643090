import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//BARRA SERVICIO SCRIPT
import { CargarScriptsService } from './cargar-scripts.service';

import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NavegadorComponent } from './navegador/navegador.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PiepagComponent } from './piepag/piepag.component';
import { QsComponent } from './qs/qs.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { QueHacemosComponent } from './que-hacemos/que-hacemos.component';
import { DondeTrabajamosComponent } from './donde-trabajamos/donde-trabajamos.component';
import { QhInfraestructuraTelecomComponent } from './qh-infraestructura-telecom/qh-infraestructura-telecom.component';
import { QhSeguridadComponent } from './qh-seguridad/qh-seguridad.component';
import { QhComunicacionesUnificadasComponent } from './qh-comunicaciones-unificadas/qh-comunicaciones-unificadas.component';
import { QhLogisticaComponent } from './qh-logistica/qh-logistica.component';
import { QhColaboracionRemotaComponent } from './qh-colaboracion-remota/qh-colaboracion-remota.component';
import { DtIndustriaComponent } from './dt-industria/dt-industria.component';
import { DtInfraestructuraCriticaComponent } from './dt-infraestructura-critica/dt-infraestructura-critica.component';
import { DtCiudadesInteligentesComponent } from './dt-ciudades-inteligentes/dt-ciudades-inteligentes.component';
import { PrincipalComponent } from './principal/principal.component';
import { IInfraestructuraComponent } from './i-infraestructura/i-infraestructura.component';
import { ISeguridadComponent } from './i-seguridad/i-seguridad.component';
import { IComUniComponent } from './i-com-uni/i-com-uni.component';
import { ILogisticaComponent } from './i-logistica/i-logistica.component';
import { IColabRemotaComponent } from './i-colab-remota/i-colab-remota.component';
import { IcInfraestructuraComponent } from './ic-infraestructura/ic-infraestructura.component';
import { IcSeguridadComponent } from './ic-seguridad/ic-seguridad.component';
import { IcComUniComponent } from './ic-com-uni/ic-com-uni.component';
import { CiInfraestructuraComponent } from './ci-infraestructura/ci-infraestructura.component';
import { CiSeguridadComponent } from './ci-seguridad/ci-seguridad.component';
import { CiComUniComponent } from './ci-com-uni/ci-com-uni.component';
import { CiLogisticaComponent } from './ci-logistica/ci-logistica.component';
import { CiColabRemotaComponent } from './ci-colab-remota/ci-colab-remota.component';
import { CInfraestructuraComponent } from './c-infraestructura/c-infraestructura.component';
import { CSeguridadComponent } from './c-seguridad/c-seguridad.component';
import { CComUniComponent } from './c-com-uni/c-com-uni.component';
import { EInfraestructuraComponent } from './e-infraestructura/e-infraestructura.component';
import { ESeguridadComponent } from './e-seguridad/e-seguridad.component';
import { EComUniComponent } from './e-com-uni/e-com-uni.component';
import { BlogComponent } from './blog/blog.component';
import { QhInfraestructuraDeTelecomComponent } from './qh-infraestructura-de-telecom/qh-infraestructura-de-telecom.component';
import { DtCorporativosComponent } from './dt-corporativos/dt-corporativos.component';
import { DtEscuelasComponent } from './dt-escuelas/dt-escuelas.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { VerificacionComponent } from './verificacion/verificacion.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { DaohangLanComponent } from './daohang-lan/daohang-lan.component';
import { HomeComponent } from './home/home.component';
import { FooterPageComponent } from './footer-page/footer-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { WhereWeWorkComponent } from './where-we-work/where-we-work.component';
import { IndustryElectronicSecurityComponent } from './industry-electronic-security/industry-electronic-security.component';
import { IndustryUnifiedCommComponent } from './industry-unified-comm/industry-unified-comm.component';
import { CriticalInfrastructureTelecommInfrastructureComponent } from './critical-infrastructure-telecomm-infrastructure/critical-infrastructure-telecomm-infrastructure.component';
import { CriticalInfrastructureElectronicSecurityComponent } from './critical-infrastructure-electronic-security/critical-infrastructure-electronic-security.component';
import { CriticalInfrastructureUnifiedCommComponent } from './critical-infrastructure-unified-comm/critical-infrastructure-unified-comm.component';
import { SmartCitiesTelecommInfrastructureComponent } from './smart-cities-telecomm-infrastructure/smart-cities-telecomm-infrastructure.component';
import { SmartCitiesElectronicSecurityComponent } from './smart-cities-electronic-security/smart-cities-electronic-security.component';
import { SmartCitiesUnifiedCommComponent } from './smart-cities-unified-comm/smart-cities-unified-comm.component';
import { CorporateTelecommInfrastructureComponent } from './corporate-telecomm-infrastructure/corporate-telecomm-infrastructure.component';
import { CorporateElectronicSecurityComponent } from './corporate-electronic-security/corporate-electronic-security.component';
import { CorporateUnifiedCommComponent } from './corporate-unified-comm/corporate-unified-comm.component';
import { EducationTelecommInfrastructureComponent } from './education-telecomm-infrastructure/education-telecomm-infrastructure.component';
import { EducationElectronicSecurityComponent } from './education-electronic-security/education-electronic-security.component';
import { EducationUnifiedCommComponent } from './education-unified-comm/education-unified-comm.component';
import { IndustryTelecommInfrastructureComponent } from './industry-telecomm-infrastructure/industry-telecomm-infrastructure.component';
import { IndustryLogisticsComponent } from './industry-logistics/industry-logistics.component';
import { IndustryRemoteCollaborationComponent } from './industry-remote-collaboration/industry-remote-collaboration.component';
import { SmartCitiesLogisticsComponent } from './smart-cities-logistics/smart-cities-logistics.component';
import { SmartCitiesRemoteCollaborationComponent } from './smart-cities-remote-collaboration/smart-cities-remote-collaboration.component';
import { BotonWhatsappComponent } from './boton-whatsapp/boton-whatsapp.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NavPruebaComponent } from './nav-prueba/nav-prueba.component';
import { NewFooterComponent } from './new-footer/new-footer.component';
import { NavegadorNComponent } from './navegador-n/navegador-n.component';
import { SliderComponent } from './slider/slider.component';
import { SliderEnComponent } from './slider-en/slider-en.component';
import { BlogFEnComponent } from './blog-f-en/blog-f-en.component';
import { CasosDeExitoComponent } from './casos-de-exito/casos-de-exito.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { CeIndustriaAlimenticiaComponent } from './ce-industria-alimenticia/ce-industria-alimenticia.component';
import { CeIndustriaAutomotrizComponent } from './ce-industria-automotriz/ce-industria-automotriz.component';
import { CeComercioServiciosComponent } from './ce-comercio-servicios/ce-comercio-servicios.component';
import { CeCorporativosComponent } from './ce-corporativos/ce-corporativos.component';
import { CeGobiernoComponent } from './ce-gobierno/ce-gobierno.component';
import { CeInstitucionesComponent } from './ce-instituciones/ce-instituciones.component';
import { CeInfraestructuraCriticaComponent } from './ce-infraestructura-critica/ce-infraestructura-critica.component';
import { SsFoodIndustryComponent } from './ss-food-industry/ss-food-industry.component';
import { SsAutomotiveIndustryComponent } from './ss-automotive-industry/ss-automotive-industry.component';
import { SsBusinessServicesComponent } from './ss-business-services/ss-business-services.component';
import { SsCorporateComponent } from './ss-corporate/ss-corporate.component';
import { SsGovernmentComponent } from './ss-government/ss-government.component';
import { SsInstitutionsComponent } from './ss-institutions/ss-institutions.component';
import { SsCriticalInfrastructureComponent } from './ss-critical-infrastructure/ss-critical-infrastructure.component';
import { CulturaOrganizacionalComponent } from './cultura-organizacional/cultura-organizacional.component';
import { CertificadosYSeguridadIndustrialComponent } from './certificados-y-seguridad-industrial/certificados-y-seguridad-industrial.component';
import { NavegadorWbComponent } from './navegador-wb/navegador-wb.component';
import { SolucionesYProductosComponent } from './soluciones-y-productos/soluciones-y-productos.component';
import { DtEducacionComponent } from './dt-educacion/dt-educacion.component';
import { CeResidenciasComponent } from './ce-residencias/ce-residencias.component';

const rutas: Routes =[
  {path: '', redirectTo: '/principal', pathMatch: 'full'},
  {path: 'navegador', component: NavegadorComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'piepag', component: PiepagComponent},
  {path: 'qs', component: QsComponent},
  {path: 'quienes-somos', component: QuienesSomosComponent},
  //{path: 'que-hacemos', component: QueHacemosComponent},
  {path: 'donde-trabajamos', component: DondeTrabajamosComponent},
  //{path: 'qh-infraestructura-telecom', component: QhInfraestructuraTelecomComponent},
  //{path: 'qh-seguridad', component: QhSeguridadComponent},
  //{path: 'qh-comunicaciones-unificadas', component: QhComunicacionesUnificadasComponent},
  //{path: 'qh-logistica', component: QhLogisticaComponent},
  //{path: 'qh-colaboracion-remota', component: QhColaboracionRemotaComponent},
  {path: 'dt-industria', component: DtIndustriaComponent},
  {path: 'dt-infraestructura-critica', component: DtInfraestructuraCriticaComponent},
  {path: 'dt-ciudades-inteligentes', component: DtCiudadesInteligentesComponent},
  {path: 'principal', component: PrincipalComponent},
  {path: 'i-infraestructura', component: IInfraestructuraComponent},
  {path: 'i-seguridad', component: ISeguridadComponent},
  {path: 'i-com-uni', component: IComUniComponent},
  {path: 'i-logistica', component: ILogisticaComponent},
  {path: 'i-colab-remota', component: IColabRemotaComponent},
  {path: 'ic-infraestructura', component: IcInfraestructuraComponent},
  {path: 'ic-seguridad', component: IcSeguridadComponent},
  {path: 'ic-com-uni', component: IcComUniComponent},
  {path: 'ci-infraestructura', component: CiInfraestructuraComponent},
  {path: 'ci-seguridad', component: CiSeguridadComponent},
  {path: 'ci-com-uni', component: CiComUniComponent},
  {path: 'ci-logistica', component: CiLogisticaComponent},
  {path: 'ci-colab-remota', component: CiColabRemotaComponent},
  {path: 'c-infraestructura', component: CInfraestructuraComponent},
  {path: 'c-seguridad', component: CSeguridadComponent},
  {path: 'c-com-uni', component: CComUniComponent},
  {path: 'e-infraestructura', component: EInfraestructuraComponent},
  {path: 'e-seguridad', component: ESeguridadComponent},
  {path: 'e-com-uni', component: EComUniComponent},
  {path: 'blog', component: BlogComponent},
  //{path: 'qh-infraestructura-de-telecom', component: QhInfraestructuraDeTelecomComponent},
  {path: 'dt-corporativos', component: DtCorporativosComponent},
  //{path: 'dt-escuelas', component: DtEscuelasComponent},
  {path: 'admin-login', component: AdminLoginComponent},
  {path: 'verificacion', component: VerificacionComponent},
  {path: 'navigation-bar', component: NavigationBarComponent},
  {path: 'daohang-lan', component: DaohangLanComponent},
  {path: 'home', component: HomeComponent},
  {path: 'footer-page', component: FooterPageComponent},
  {path: 'about-us', component: AboutUsComponent},
  //{path: 'what-we-do', component: WhatWeDoComponent},
  //{path: 'where-we-work', component: WhereWeWorkComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'industry-telecomm-infrastructure', component: IndustryTelecommInfrastructureComponent},
  {path: 'industry-electronic-security', component: IndustryElectronicSecurityComponent},
  {path: 'industry-unified-comm', component: IndustryUnifiedCommComponent},
  {path: 'industry-logistics', component: IndustryLogisticsComponent},
  {path: 'industry-remote-collaboration', component: IndustryRemoteCollaborationComponent},
  {path: 'smart-cities-telecomm-infrastructure', component: SmartCitiesTelecommInfrastructureComponent},
  {path: 'smart-cities-electronic-security', component: SmartCitiesElectronicSecurityComponent},
  {path: 'smart-cities-unified-comm', component: SmartCitiesUnifiedCommComponent},
  {path: 'smart-cities-logistics', component: SmartCitiesLogisticsComponent},
  {path: 'smart-cities-remote-collaboration', component: SmartCitiesRemoteCollaborationComponent},
  {path: 'critical-infrastructure-telecomm-infrastructure', component: CriticalInfrastructureTelecommInfrastructureComponent},
  {path: 'critical-infrastructure-electronic-security', component: CriticalInfrastructureElectronicSecurityComponent},
  {path: 'critical-infrastructure-unified-comm', component: CriticalInfrastructureUnifiedCommComponent},
  {path: 'corporate-telecomm-infrastructure', component: CorporateTelecommInfrastructureComponent},
  {path: 'corporate-electronic-security', component: CorporateElectronicSecurityComponent},
  {path: 'corporate-unified-comm', component: CorporateUnifiedCommComponent},
  {path: 'education-telecomm-infrastructure', component: EducationTelecommInfrastructureComponent},
  {path: 'education-electronic-security', component: EducationElectronicSecurityComponent},
  {path: 'education-unified-comm', component: EducationUnifiedCommComponent},
  {path: 'boton-whatsapp', component: BotonWhatsappComponent},
  {path: 'galeria', component: GaleriaComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'nav-prueba', component: NavPruebaComponent},
  {path: 'new-footer', component: NewFooterComponent},
  {path: 'navegador-n', component: NavegadorNComponent},
  {path: 'slider', component: SliderComponent},
  {path: 'slider-en', component: SliderEnComponent},
  {path: 'blog-f-en', component: BlogFEnComponent},
  {path: 'casos-de-exito', component: CasosDeExitoComponent},
  {path: 'ce-industria-alimenticia', component: CeIndustriaAlimenticiaComponent},
  {path: 'ce-industria-automotriz', component: CeIndustriaAutomotrizComponent},
  {path: 'ce-comercio-servicios', component: CeComercioServiciosComponent},
  {path: 'ce-corporativos', component: CeCorporativosComponent},
  {path: 'ce-gobierno', component: CeGobiernoComponent},
  {path: 'ce-instituciones', component: CeInstitucionesComponent},
  {path: 'ce-infraestructura-critica', component: CeInfraestructuraCriticaComponent},
  {path: 'success-stories', component: SuccessStoriesComponent},
  {path: 'ss-food-industry', component: SsFoodIndustryComponent},
  {path: 'ss-automotive-industry', component: SsAutomotiveIndustryComponent},
  {path: 'ss-business-services', component: SsBusinessServicesComponent},
  {path: 'ss-corporate', component: SsCorporateComponent},
  {path: 'ss-government', component: SsGovernmentComponent},
  {path: 'ss-institutions', component: SsInstitutionsComponent},
  {path: 'ss-critical-infrastructure', component: SsCriticalInfrastructureComponent},
  {path: 'cultura-organizacional', component: CulturaOrganizacionalComponent},
  {path: 'certificados-y-seguridad-industrial', component: CertificadosYSeguridadIndustrialComponent},
  {path: 'navegador-wb', component: NavegadorWbComponent},
  {path: 'soluciones-y-productos', component: SolucionesYProductosComponent},
  {path: 'dt-educacion', component: DtEducacionComponent},
  {path: 'ce-residencias', component: CeResidenciasComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    NavegadorComponent,
    ContactoComponent,
    PiepagComponent,
    QsComponent,
    QuienesSomosComponent,
    QueHacemosComponent,
    DondeTrabajamosComponent,
    QhInfraestructuraTelecomComponent,
    QhSeguridadComponent,
    QhComunicacionesUnificadasComponent,
    QhLogisticaComponent,
    QhColaboracionRemotaComponent,
    DtIndustriaComponent,
    DtInfraestructuraCriticaComponent,
    DtCiudadesInteligentesComponent,
    PrincipalComponent,
    IInfraestructuraComponent,
    ISeguridadComponent,
    IComUniComponent,
    ILogisticaComponent,
    IColabRemotaComponent,
    IcInfraestructuraComponent,
    IcSeguridadComponent,
    IcComUniComponent,
    CiInfraestructuraComponent,
    CiSeguridadComponent,
    CiComUniComponent,
    CiLogisticaComponent,
    CiColabRemotaComponent,
    CInfraestructuraComponent,
    CSeguridadComponent,
    CComUniComponent,
    EInfraestructuraComponent,
    ESeguridadComponent,
    EComUniComponent,
    BlogComponent,
    QhInfraestructuraDeTelecomComponent,
    DtCorporativosComponent,
    DtEscuelasComponent,
    AdminLoginComponent,
    VerificacionComponent,
    NavigationBarComponent,
    DaohangLanComponent,
    HomeComponent,
    FooterPageComponent,
    AboutUsComponent,
    ContactComponent,
    WhatWeDoComponent,
    WhereWeWorkComponent,
    IndustryElectronicSecurityComponent,
    IndustryUnifiedCommComponent,
    CriticalInfrastructureTelecommInfrastructureComponent,
    CriticalInfrastructureElectronicSecurityComponent,
    CriticalInfrastructureUnifiedCommComponent,
    SmartCitiesTelecommInfrastructureComponent,
    SmartCitiesElectronicSecurityComponent,
    SmartCitiesUnifiedCommComponent,
    CorporateTelecommInfrastructureComponent,
    CorporateElectronicSecurityComponent,
    CorporateUnifiedCommComponent,
    EducationTelecommInfrastructureComponent,
    EducationElectronicSecurityComponent,
    EducationUnifiedCommComponent,
    IndustryTelecommInfrastructureComponent,
    IndustryLogisticsComponent,
    IndustryRemoteCollaborationComponent,
    SmartCitiesLogisticsComponent,
    SmartCitiesRemoteCollaborationComponent,
    BotonWhatsappComponent,
    GaleriaComponent,
    GalleryComponent,
    NavPruebaComponent,
    NewFooterComponent,
    NavegadorNComponent,
    SliderComponent,
    SliderEnComponent,
    BlogFEnComponent,
    CasosDeExitoComponent,
    SuccessStoriesComponent,
    CeIndustriaAlimenticiaComponent,
    CeIndustriaAutomotrizComponent,
    CeComercioServiciosComponent,
    CeCorporativosComponent,
    CeGobiernoComponent,
    CeInstitucionesComponent,
    CeInfraestructuraCriticaComponent,
    SsFoodIndustryComponent,
    SsAutomotiveIndustryComponent,
    SsBusinessServicesComponent,
    SsCorporateComponent,
    SsGovernmentComponent,
    SsInstitutionsComponent,
    SsCriticalInfrastructureComponent,
    CulturaOrganizacionalComponent,
    CertificadosYSeguridadIndustrialComponent,
    NavegadorWbComponent,
    SolucionesYProductosComponent,
    DtEducacionComponent,
    CeResidenciasComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      rutas,
      {enableTracing: true, useHash: true}
    )
  ],
  providers: [
    CargarScriptsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
