<app-navigation-bar></app-navigation-bar>
<div id="ciudades-logistica">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-com-uni">Comunicaciones Unificadas</a> < <a href="#/ci-logistica">Logistica</a> > <a href="#/ci-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Smart Cities</h1>
        </div>
        <div>
            <div>
                <h3>Logistics</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Speed control</h3>
                <p>
                    Keep vehicle's speed under surveillance in the city. This can help to reduce car accidents.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Flow control</h3>
                <p>
                    Watch that vehicles are going in the right way of the street/avenue; otherwise an alarm is send to the control center.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Tumult detection</h3>
                <p>
                    Detects when an agglomeration is beginning. This can help to avoid chaos, tumults, etc.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>