<app-navegador-n></app-navegador-n>
<div id="ciudades-infraestructura">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/ci-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Ciudades Inteligentes</h1>
        </div>
        <div>
            <div>
                <h3>Infraestructura de Telecomunicaciones</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Fibra OSP</h3>
                <p>
                    Ideales para la instalación de edificios, campus y conductos subeterráneos. Diseñados para tolerar ambientes externos con velocidades de hasta 10 GB.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Microondas PTP/PMP</h3>
                <p>
                    Intercomunica hasta las redes de más difícil acceso, de manera inalámbrica teniendo mayor alcance y estabilidad.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>