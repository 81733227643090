<app-navigation-bar></app-navigation-bar>
<div id="ciudades-colab">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-logistica">Logistica</a> < <a href="#/ci-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Smart Cities</h1>
        </div>
        <div>
            <div>
                <h3>Remote Collaboration</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Meeting room</h3>
                <p>
                    Enhance remote conferences experiences.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Interactive classroom</h3>
                <p>
                    Enhance student's learning experience.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>