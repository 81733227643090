<app-navigation-bar></app-navigation-bar>
<div id="corporativos-seguridad">
    <div id="titulo">
        <div>
            <h1>Corporate</h1>
        </div>
        <div>
            <div>
                <h3>Electronic Security</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV & Voice</h3>
                <p>
                    Closed-circuit television (server, VMS, cameras) to keep under surveillance any area. Some models can incluide speakers, which can be use to give alerts in case of an emergency, give announcements and give warnings.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Perimeter security</h3>
                <p>
                    Keeps safe your company sorroundings through prowling cameras, geo fence, and so on.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Access Control</h3>
                <p>
                    This sistem allows or restricts the access to a specific area. It can also helps to control the access to restricted areas or where the personnel is limited.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Visit Control</h3>
                <p>
                    Speed up the access of your visitors without leaving aside the restriction of the area to visit; in such a way that your collaborators feel safe in the presence of external people.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="lectores">
            <div id="imagen">
            </div>
            <div id="texto">
                <h3>Biometric reader</h3>
                <p>
                    Authenticates a person's identity through fingerprint or face reader, maximizing the security of the area.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>