import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-com-uni',
  templateUrl: './i-com-uni.component.html',
  styleUrls: ['./i-com-uni.component.css']
})
export class IComUniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
