import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qh-logistica',
  templateUrl: './qh-logistica.component.html',
  styleUrls: ['./qh-logistica.component.css']
})
export class QhLogisticaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
