<app-navigation-bar></app-navigation-bar>
<div id="contenedor">
    <h1>About Us</h1>
    <div id="cuerpo">
        <div id="info">
            <div id="mision">
                <div id="imagen1">
                    <!--<img src="..\..\assets\Imagenes\rs\mision.png" width="30%">-->    
                </div>
                <div class="texto" id="texto-mision">
                    <div class="subtitulo"><h2>Mission</h2></div>
                    <p>
                        To become a national-wide leader in telecommunications solutions. We ensure our personnel improvement, in order to provide our clients a grate-valued service. We are distinguished for our quality and rapidity in which we attend to our client's necessities. Through our workforce talent and application of certified technology given by the suppliers, we let our clients to achieve their objectives.
                    </p>
                </div>
            </div>
            <div id="vision">
                <div class="texto" id="texto-vision">
                    <div class="subtitulo"><h2>Vision</h2></div>  
                    <p>
                        To position us in the national market, because of our quality, innovation, and our services and products delivery time. We want to get our client's recognition through our solutions to their necessities.
                    </p>
                </div>
                <div id="imagen2">
                    <!--<img src="..\..\assets\Imagenes\rs\vision.png" width="30%">-->
                </div>
            </div>
            <div id="valor">
                <div id="imagen3">
                    <!--<img src="..\..\assets\Imagenes\rs\valor.png" width="30%">-->
                </div>
                <div class="texto" id="texto-valor">
                    <div class="subtitulo"><h2>Values</h2></div>
                    <h3>Responsability:</h3>
                    <p>
                        We are responsible with our compromises and duties.
                    </p>
                    <h3>Discipline:</h3>
                    <p>
                        We respect our and our client's policies and regulations.
                    </p>
                    <h3>Honesty:</h3>
                    <p>
                        It is our compromise to be honest while working and providing service to our clients. We are coherent with our words and our work.
                    </p>
                    <h3>Respect:</h3>
                    <p>
                        We recognize that everybody should be treated equally.
                    </p>
                    <h3>Other values</h3>
                    <ul>
                        <li>Teamwork</li>
                        <li>Service attitude</li>
                        <li>Quality</li>
                    </ul>
                </div>  
            </div>
        </div>
    </div>
</div>
<app-footer-page></app-footer-page>