<app-navigation-bar></app-navigation-bar>
<div id="industria-comunicaciones">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-industria">Industry</a> - <a href="#/industry-electronic-security">Electronic Security</a> < <a href="#/industry-unified-comm">Unified Communications</a> > <a href="#/industry-logistic">Logistic</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Industry</h1>
        </div>
        <div>
            <div>
                <h3>Unified Communications</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>IP Commutator</h3>
                <p>
                    System for telephonic network, mainly focused to enterprises to interconnect their own inner and/or outer communications.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>IP Voice</h3>
                <p>
                    Communicate announcements, notifications and alarms with a better quality.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>