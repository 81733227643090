<app-navegador></app-navegador>
<div id="sic">
    <div id="info">
    	<div id="izquierda">
    		<div id="vacio"></div>
        	<h1>Que Hacemos</h1>
        	<!--<h3>
            	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut diam non libero lacinia dapibus et eu augue. Etiam nec vulputate quam. Donec ultrices, elit vel rutrum pharetra, elit leo tincidunt enim, nec fringilla ipsum felis sed nunc. Nulla pharetra rhoncus tellus, vel molestie magna finibus eu. Nam sed quam augue. Sed euismod lacus eu aliquet pretium. Quisque vel ex neque. Maecenas quis pharetra lectus. Proin lacinia sit amet mi sit amet rhoncus.
        	</h3>-->
        	<h3>
        		<a href="#/qh-infraestructura-de-telecom">Infraestructura de Telecomunicaciones</a> - 
        		<a href="#/qh-seguridad">Seguridad Electrónica</a> - 
        		<a href="#/qh-comunicaciones-unificadas">Comunicaciones Unificadas</a> - 
        		<a href="#/qh-logistica">Logística</a> - 
        		<a href="#/qh-colaboracion-remota">Colaboración Remota</a>
        	</h3>
    	</div>
    </div>
</div>