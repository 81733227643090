<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Corporate</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Corporativos.jpg" alt="">
    </div>
    <div class="texto">
        <p>
            Automation of training or videoconference rooms, from lighting, sound, projection, smartboard, tabletop plates and videoconference equipment.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Wireless network coverage studies for the management of applications using hand held or wireless telephone.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Corporativos2.jpg" alt="">
    </div>
</div>
<app-footer-page></app-footer-page>