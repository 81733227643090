<app-navegador-n></app-navegador-n>
<div id="ciudades-logistica">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-com-uni">Comunicaciones Unificadas</a> < <a href="#/ci-logistica">Logistica</a> > <a href="#/ci-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Ciudades Inteligentes</h1>
        </div>
        <div>
            <div>
                <h3>Logística</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Control de Velocidad</h3>
                <p>
                    Vigila la velocidad del transporte dentro de la ciudad, reduciendo accidentes automovilísticos.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Control de flujo</h3>
                <p>
                    Vigila que los automóviles sigan el sentido de la calle y/o avenida, en caso contrario, se manda una alarma al centro de control.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Detección de tumultos</h3>
                <p>
                    Detecta cuando se aglomeran personas en un lugar para así poder evitar disturbios, tumultos, etc.
                </p>
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>