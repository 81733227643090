<app-navigation-bar></app-navigation-bar>
<div id="ciudades-infraestructura">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-infraestructura">Infraestructura de Telecomunicaciones</a> > <a href="#/ci-seguridad">Seguridad</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Smart Cities</h1>
        </div>
        <div>
            <div>
                <h3>Telecommunications Infrastructure</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>OSP Fiber</h3>
                <p>
                    These are ideals for installing in buildings, campus and underground channels. They are design to tolerate extreme weather. Their speed can reach 10 GB.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>PTP/PMP Microwaves</h3>
                <p>
                    Intercommunicate, even the most dificult networks to reach, in a wireless way.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>