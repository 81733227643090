import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-com-uni',
  templateUrl: './e-com-uni.component.html',
  styleUrls: ['./e-com-uni.component.css']
})
export class EComUniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
