<app-navegador-n></app-navegador-n>
<div id="ciudades-colab">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-logistica">Logistica</a> < <a href="#/ci-colab-remota">Colaboración Remota</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Ciudades Inteligentes</h1>
        </div>
        <div>
            <div>
                <h3>Colaboración Remota</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Salas de juntas</h3>
                <p>
                    Mejora la experiencia en conferencias realizadas de manera remota.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Aulas interactivas</h3>
                <p>
                    Mejora la experiencia educativa de los estudiantes, durante su aprendizaje.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>