<app-navegador-n></app-navegador-n>
<div id="corporativos-comunicacion">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-corporativos">Corporativos</a> - <a href="#/c-seguridad">Seguridad</a> < <a href="#/c-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Corporativos</h1>
        </div>
        <div>
            <div>
                <h3>Comunicaciones Unificadas</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>Conmutadores IP</h3>
                <p>
                    Es el sistema para la red telefónica, enfocado a empresas para interconectar sus propias comunicaciones, internas y externas.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Voceo IP</h3>
                <p>
                    Comunica anuncios, notificaciones y alarmas con una mayor fidelidad.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>