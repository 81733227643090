<!--<app-navegador-n></app-navegador-n>
<div class="cuerpo">
    <div class="titulo">
        <h1>Corporativos</h1>
    </div>
    <div class="division">
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Corporativos.jpg" alt="">
        </div>
        <div class="texto">
            <p>
                Automatización de salas de capacitación o videoconferencia, desde iluminación, sonido, proyección, smartboard, placas de mesa y equipo de videoconferencia.
            </p>
        </div>
    </div>
    <div class="division">
        <div class="texto-f">
            <p>
                Estudios de cobertura de red inalámbrica para el manejo de aplicaciones utilizando hand held o telefónica inalámbrica.
            </p>
        </div>
        <div class="imagen">
            <img src="../../assets/Imagenes/exito/sub/Corporativos2.jpg" alt="">
        </div>
    </div>
</div>
<app-new-footer></app-new-footer>-->

<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\corporativo 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\corporativo 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\corporativo 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Corporativos</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Incorporamos dentro de los corporativos las mejores soluciones, desde la <br>
            infraestructura de red hasta seguridad y comunicación que permitirán tener un mejor <br>
            control en la operación de su empresa
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/mondelez.png">
                </div>
            </div>
        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Mondelēz int.</h2>
                    <p>
                        Automatización de salas de capacitación o videoconferencia, 
                        desde iluminación, sonido, proyección, smartboard, placas de mesa y equipo de videoconferencia.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-corporativo-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Mondelēz int.</h2>
                    <p>
                        Integramos estudios de cobertura de red inalámbrica para el manejo de 
                        aplicaciones utilizando hand held o telefonía inalámbrica.
                        <br>
                        <br>
                        Incorporamos infraestructura de red, colocando/integrando nodos en 
                        salas y estudios para mejorar la capacidad de acceso a la red.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-corporativo-2.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="morena">
                <div class="imagen">
                    <h2>Mondelēz int.</h2>
                    <div class="texto">
                        <p>
                            Automatización de salas de capacitación o videoconferencia, 
                            desde iluminación, sonido, proyección, smartboard, placas de mesa y equipo de videoconferencia.
                        </p>
                    </div>
                </div>
            </div>

            <div class="bimbo">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">-
                    <h2>Mondelēz Int.</h2>
                    <div class="texto">
                        <p>
                            Integramos estudios de cobertura de red inalámbrica para el manejo de 
                            aplicaciones utilizando hand held o telefonía inalámbrica.
                            <br>
                            <br>
                            Incorporamos infraestructura de red, colocando/integrando nodos en 
                            salas y estudios para mejorar la capacidad de acceso a la red.                    
                        </p>
                    </div>
                </div>
            </div>

        </div>
    -->
        <!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>