import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dt-ciudades-inteligentes',
  templateUrl: './dt-ciudades-inteligentes.component.html',
  styleUrls: ['./dt-ciudades-inteligentes.component.css']
})
export class DtCiudadesInteligentesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
