import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ss-government',
  templateUrl: './ss-government.component.html',
  styleUrls: ['./ss-government.component.css']
})
export class SsGovernmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
