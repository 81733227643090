<app-navigation-bar></app-navigation-bar>
<div class="cuerpo">
    <h1 class="titulo">
        Success Stories
    </h1>
    <div class="cuadros">
        <div class="columna1">
            <div class="fila1">
                <a href="#/ss-food-industry">
                    <img src="../../assets/Imagenes/exito/IndustriaAlimenticia.png" alt="Alimenticia">
                </a>
                <h2>Food Industry</h2>
            </div>
            <div class="fila1">
                <a href="#/ss-corporate">
                    <img src="../../assets/Imagenes/exito/Corporativos.png" alt="Corporativos">
                </a>                
                <h2>Corporate</h2>
            </div>
        </div>
        <div class="columna2">
            <div class="fila2">
                <a href="#/ss-automotive-industry">
                    <img src="../../assets/Imagenes/exito/IndustriaAutomotriz.png" alt="IndustriaAutomotriz">
                </a>
                <h2>Automotive Industry</h2>
            </div>
            <div class="fila2">
                <a href="#/ss-government">
                    <img src="../../assets/Imagenes/exito/Gobierno.png" alt="Gobierno">
                </a>
                <h2>Government</h2>
            </div>
            <div class="fila2">
                <!--<a href="#/ce-infraestructura-critica">-->
                    <img src="../../assets/Imagenes/exito/critica-sinfondo.png" alt="Critica">
                <!--</a>-->
                <h2>Critical Infrastructure</h2>
            </div>
        </div>
        <div class="columna3">
            <div class="fila3">
                <a href="#/ss-business-services">
                    <img src="../../assets/Imagenes/exito/Comercio.png" alt="Comercio">
                </a>
                <h2>Business And Services</h2>
            </div>
            <div class="fila3">
                <a href="#/ss-institutions">
                    <img src="../../assets/Imagenes/exito/Instituciones.png" alt="Instituciones">
                </a>
                <h2>Institutions</h2>
            </div>
        </div>
    </div>

    <div class="cuadros-movil">
        <div class="columna">
            <div class="fila">
                <a href="#/ss-food-industry">
                    <img src="../../assets/Imagenes/exito/IndustriaAlimenticia.png" alt="Alimenticia">
                </a>
                <h2>Food Industry</h2>
            </div>
            <div class="fila">
                <a href="#/ss-corporate">
                    <img src="../../assets/Imagenes/exito/Corporativos.png" alt="Corporativos">
                </a>
                <h2>Corporate</h2>
            </div>
            <div class="fila">
                <a href="#/ss-automotive-industry">
                    <img src="../../assets/Imagenes/exito/IndustriaAutomotriz.png" alt="IndustriaAutomotriz">
                </a>
                <h2>Automotive Industry</h2>
            </div>
            <div class="fila">
                <a href="#/ss-government">
                    <img src="../../assets/Imagenes/exito/Gobierno.png" alt="Gobierno">
                </a>
                <h2>Government</h2>
            </div>
            <div class="fila">
                <!--<a href="#/ss-critical-infrastructure">-->
                    <img src="../../assets/Imagenes/exito/critica-sinfondo.png" alt="Critica">
                <!--</a>-->
                <h2>Critical Infrastructure</h2>
            </div>
            <div class="fila">
                <a href="#/ss-business-services">
                    <img src="../../assets/Imagenes/exito/Comercio.png" alt="Comercio">
                </a>
                <h2>Business And Services</h2>
            </div>
            <div class="fila">
                <a href="#/ss-institutions">
                    <img src="../../assets/Imagenes/exito/Instituciones.png" alt="Instituciones">
                </a>
                <h2>Institutions</h2>
            </div>
        </div>
    </div>
</div>
<app-footer-page></app-footer-page>