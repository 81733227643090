<app-navigation-bar></app-navigation-bar>
<div class="titulo">
    <h1>Government</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Gobierno1.jpg" alt="Gobierno">
    </div>
    <div class="texto">
        <p>
            To improve security and transparency in the administration of justice, Judges and Magistrates of the "Suprema Corte de Justicia de la Nación" make use of Voice and Data IP telecommunications systems and enjoy the security provided by a CCTV Video Surveillance system.
        </p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>
            Work in conjunction with the INAH for the installation of network infrastructure, adhering to the guidelines for the care of historic buildings.
        </p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Gobierno2.jpg" alt="Gobierno">
    </div>
</div>
<app-footer-page></app-footer-page>