import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industry-unified-comm',
  templateUrl: './industry-unified-comm.component.html',
  styleUrls: ['./industry-unified-comm.component.css']
})
export class IndustryUnifiedCommComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
