<!--<app-navegador-n></app-navegador-n>
<div class="titulo">
    <h1>Comercio y Servicios</h1>
</div>
<div class="texto">
    <p>
        Centralización de la operación de Integración de diversas sucursales con la matriz, mediante la implementación de proyectos de infraestructura de red, equipo activo y telefonía IP.
    </p>
</div>
<div class="imagen">
    <img src="../../assets/Imagenes/exito/sub/ComercioYServicios.jpg" alt="Comercio">
</div>
<app-new-footer></app-new-footer>
-->

<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\COMERCIO 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\COMERCIO 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\COMERCIO 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Comercio Y Servicios</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Centralización de la operación de Integración de diversas sucursales con la matriz, <br>
            mediante la implementación de proyectos de infraestructura de red, equipo activo y telefonía IP.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/smi.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/napa.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/albany.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/castores.png">
                </div>
            </div>

            <small>
                * Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>

        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Napa</h2>
                    <p>
                        Centralizamos  las operaciones de integración en diversas sucursales con la matriz, 
                        mediante la implementación de proyectos de infraestructura de red, equipo activo y telefonía IP.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-comercio-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Grupo Castores</h2>
                    <p>
                        Trabajamos en la incorporación de infraestructura de red con más de 3,000 nodos, 
                        en equipamiento en almacenes y en patios de cliente. 
                        <br><br>
                        Anexamos sistemas de videovigilancia CCTV, ofreciendo suministro e instalación de 
                        cámaras, sensores, alarmas, y botones de pánico.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-comercio-2.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="uno">
                <div class="imagen">
                    <h2>Napa</h2>
                    <div class="texto">
                        <p>
                            Centralizamos  las operaciones de integración en diversas sucursales con la matriz, 
                            mediante la implementación de proyectos de infraestructura de red, equipo activo y telefonía IP.
                        </p>
                    </div>
                </div>
            </div>

            <div class="dos">
                <div class="imagen">
                    <h2>Grupo Castores</h2>
                    <div class="texto">
                        <p>
                            Trabajamos en la incorporación de infraestructura de red con más de 3,000 nodos, 
                            en equipamiento en almacenes y en patios de cliente. 
                            <br><br>
                            Anexamos sistemas de videovigilancia CCTV, ofreciendo suministro e instalación de 
                            cámaras, sensores, alarmas, y botones de pánico.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        --><!--FIN CUADROS-->
    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>