<!--
<div class="slider">
    <ul>
        <li>
            <img src="../../assets/Imagenes/banner/b9.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b3.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b10.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b4.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b5.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b13.jpeg" alt="">
        </li>
        <li>
            <img src="../../assets/Imagenes/banner/b11.jpeg" alt="">
        </li>
    </ul>
</div>
-->
<div class="slider">
    <ul>
        <li>
            <div id="div-axis">
                <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
            </div>
            <div id="div-dahua">
                <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
            </div>
            <div id="div-hikvision">
                <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
            </div>
            <div id="div-milestone">
                <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
            </div>
            <div id="div-lenel">
                <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
            </div>
            <div id="div-radwin">
                <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
            </div>
            <div id="div-tripp-lite">
                <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
            </div>
        </li>
        <li>
            <div id="div-panduit">
                <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
            </div>
            <div id="div-cisco">
                <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
            </div>
            <div id="div-extreme">
                <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
            </div>
            <div id="div-avaya">
                <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
            </div>
            <div id="div-internel">
                <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
            </div>
            <div id="div-siklu">
                <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
            </div>
            <div id="div-total-ground">
                <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
            </div>
        </li>
    </ul>
</div>