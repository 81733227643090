<router-outlet></router-outlet>
<header>
<div id="encabezado">
<nav id="nav">
    <ul class="container">
        <li><a href="#/principal"><!--<img id="logo" src="..\..\assets\Imagenes\tbell.png">-->Inicio</a></li>
        <!--<li><a href="#pprincipal">Inicio</a></li>-->
        <li><a href="#/quienes-somos">Quiénes somos</a></li>
        <li><a href="#/que-hacemos">Que hacemos</a>
            <ul>
                <li><a href="#/qh-infraestructura-de-telecom">Infraestructura de Telecom.</a>
                    <ul>
                        <li><a href="#/c-infraestructura">Corporativos</a></li>
                        <li><a href="#/i-infraestructura">Industria</a></li>
                        <li><a href="#/ic-infraestructura">Infraestructura Critica</a></li>
                        <li><a href="#/ci-infraestructura">Ciudades Inteligentes</a></li>
                        <li><a href="#/e-infraestructura">Educación</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-seguridadd">Seguridad Electrónica</a>
                    <ul>
                        <li><a href="#/c-seguridad">Corporativos</a></li>
                        <li><a href="#/i-seguridad">Industria</a></li>
                        <li><a href="#/ic-seguridad">Infraestructura Critica</a></li>
                        <li><a href="#/ci-seguridad">Ciudades Inteligentes</a></li>
                        <li><a href="#/e-seguridad">Educación</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-comunicaciones-unificadass">Comunicaciones Unificadas</a>
                    <ul>
                        <li><a href="#/c-com-uni">Corporativos</a></li>
                        <li><a href="#/i-com-uni">Industria</a></li>
                        <li><a href="#/ic-com-uni">Infraestructura Critica</a></li>
                        <li><a href="#/ci-com-uni">Ciudades Inteligentes</a></li>
                        <li><a href="#/e-com-uni">Educación</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-logisticaa">Logística</a>
                    <ul class="logi-cola">
                        <li><a href="#/i-logistica">Industria</a></li>
                        <li><a href="#/ci-logistica">Ciudades Inteligentes</a></li>
                    </ul>
                </li>
                <li><a href="#/qh-colaboracion-remotaa">Colaboración Remota</a>
                    <ul class="logi-cola">
                        <li><a href="#/i-colab-remota">Industria</a></li>
                        <li><a href="#/ci-colab-remota">Ciudades Inteligentes</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="#/donde-trabajamos">Donde trabajamos</a>
            <ul>
                <li><a href="#/dt-corporativoss">Corporativos</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/c-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/c-seguridad">Seguridad Electrónica</a></li>
                        <li><a href="#/c-com-uni">Comunicaciones Unificadas</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-industriaa">Industria</a>
                    <ul class="para-quien">
                        <li><a href="#/i-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/i-seguridad">Seguridad Electrónica</a></li>
                        <li><a href="#/i-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/i-logistica">Logística</a></li>
                        <li><a href="#/i-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-infraestructura-criticaa">Infraestructura Critica</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/ic-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/ic-seguridad">Seguridad Electrónica</a></li>
                        <li><a href="#/ic-com-uni">Comunicaciones Unificadas</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-ciudades-inteligentess">Ciudades Inteligentes</a>
                    <ul class="para-quien">
                        <li><a href="#/ci-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/ci-seguridad">Seguridad Electrónica</a></li>
                        <li><a href="#/ci-com-uni">Comunicaciones Unificadas</a></li>
                        <li><a href="#/ci-logistica">Logística</a></li>
                        <li><a href="#/ci-colab-remota">Colaboración Remota</a></li>
                    </ul>
                </li>
                <li><a href="#/dt-escuelass">Educación</a>
                    <ul class="para-quien" id="sub-espacio">
                        <li><a href="#/e-infraestructura">Infraestructura de Telecom.</a></li>
                        <li><a href="#/e-seguridad">Seguridad Electrónica</a></li>
                        <li><a href="#/e-com-uni">Comunicaciones Unificadas</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><a href="#/blog">Blog</a></li>
        <li><a href="https://t-bell.mercadoshops.com.mx/" id="link-tienda" target="_blank">Tienda</a></li>
        <!--<li><a href="https://tbell.freshdesk.com/support/home">Soporte</a></li>-->
        <li><a href="#/contacto">Contacto</a></li>
        <li><a href="#/lenguaje">Lenguaje</a>
            <ul class="para-quien">
                <li><a href="#/principal">Español</a></li>
                <li><a href="#/home">English</a></li>
            </ul>
        </li>
    </ul>
</nav>
</div>
</header>
<script>
    
</script>