import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qh-infraestructura-de-telecom',
  templateUrl: './qh-infraestructura-de-telecom.component.html',
  styleUrls: ['./qh-infraestructura-de-telecom.component.css']
})
export class QhInfraestructuraDeTelecomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
