import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-en',
  templateUrl: './slider-en.component.html',
  styleUrls: ['./slider-en.component.css']
})
export class SliderEnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
