<app-navegador-n></app-navegador-n>
<div id="ciudades-seguridad">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/ci-seguridad">Seguridad</a> > <a href="#/ci-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Ciudades Inteligentes</h1>
        </div>
        <div>
            <div>
                <h3>Seguridad Electrónica</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV y Voceo</h3>
                <p>
                    Brinda mayor seguridad a los ciudadanos y proporciona menor tiempo de respuesta ante una emergencia.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Video-analíticos</h3>
                <p>
                    Apoya al personal de seguridad para analizar comportamientos y actitudes en zonas difíciles de visualizar; agilizando su respuesta en caso de percance.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Postes de Emergencia</h3>
                <p>
                    Permite que los usuarios manden una señal de alarma en caso de emergencia.
                </p>
            </div>
            <div id="imagen2">
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>C5</h3>
                <p>
                    Centraliza el monitoreo de comunicación y seguridad de diferentes zonas.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="detec">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Detección de disparos</h3>
                <p>
                    Funciona para detectar frecuencias y diferenciar entre un ruido común y un disparo, mandando una señal de alarma.
                </p>
            </div>
        </div>
        <div id="luces">
            <div id="texto">
                <h3>Luces de Señalización de vehículos</h3>
                <p>
                    La más alta tecnología en sistemas de señalización audiovisual para vehículos de emergencia.
                    <br>
                    Contamos con los mejores productos como Torretas, Minitorretas, Estrobos, Sirenas, Bocinas, Controladores, Barras para Interior, Accesorios para Motocicletas y mucho más.
                </p>
            </div>
            <div id="imagen">
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>