import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-infraestructura',
  templateUrl: './ic-infraestructura.component.html',
  styleUrls: ['./ic-infraestructura.component.css']
})
export class IcInfraestructuraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
