import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dt-infraestructura-critica',
  templateUrl: './dt-infraestructura-critica.component.html',
  styleUrls: ['./dt-infraestructura-critica.component.css']
})
export class DtInfraestructuraCriticaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
