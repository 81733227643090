<app-navigation-bar></app-navigation-bar>
<div id="pagina-principal">
    <div id="cuerpo">
        <!--
        <div class="parallax">
            <div id="dentro">
                <img id="logo" src="..\..\assets\Imagenes\logostbell\T-Bell-2016-Guinda-png.png">
            </div>
        </div>--FIN DIV PARALLAX-->
        <app-slider-en></app-slider-en>
        <div id="quienes-somos">
            <h1>About Us</h1>
            <p>
                T-Bell Telecom is a company dedicated to the development, implementation and support of solutions for telecommunications, voice and data networks, security and information technologies; integrating expert professionals in each of these areas
            </p>
            <div id="quso">
                <div id="quso-mi">
                    <h2>Mission</h2>
                    <p>
                        To become a national-wide leader in telecommunications solutions. We ensure our personnel improvement, in order to provide our clients a grate-valued service. We are distinguished for our quality and rapidity in which we attend to our client's necessities. Through our workforce talent and application of certified technology given by the suppliers, we let our clients to achieve their objectives.
                    </p>
                </div>
                <div id="quso-vi">
                    <h2>Vision</h2>
                    <p>
                        To position us in the national market, because of our quality, innovation, and our services and products delivery time. We want to get our client's recognition through our solutions to their necessities.
                    </p>
                </div>
                <div>
                    <h2>Values</h2>
                    <h3>Responsibility:</h3>
                    <p>
                        We are responsible with our compromises and duties.
                    </p>
                    <h3>Discipline:</h3>
                    <p>
                        We respect our and our client's policies and regulations.
                    </p>
                    <h3>Honesty:</h3>
                    <p>
                        It is our compromise to be honest while working and providing service to our clients. We are coherent with our words and our work.
                    </p>
                    <h3>Respect:</h3>
                    <p>
                        We recognize that everybody should be treated equally.
                    </p>
                    <h3>Other values</h3>
                    <ul>
                        <li>Teamwork</li>
                        <li>Service attitude</li>
                        <li>Quality</li>
                    </ul>
                </div>
            </div>
        </div><!--CIERRE QUIENES SOMOS-->
        <div class="parallax2">
            <div id="dentro2">
            </div>
        </div>
        <div id="certificaciones">
            <h1>Certifications</h1>
            <div id="certi">
                <div id="linea1">
                    <div id="div-axis">
                        <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                    </div>
                    <div id="div-dahua">
                        <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                    </div>
                    <div id="div-hikvision">
                        <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                    </div>
                    <div id="div-milestone">
                        <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                    </div>
                    <div id="div-lenel">
                        <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                    </div>
                    <div id="div-radwin">
                        <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                    </div>
                    <div id="div-tripp-lite">
                        <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                    </div>
                </div>
                <div id="linea2">
                    <div id="div-panduit">
                        <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                    </div>
                    <div id="div-cisco">
                        <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                    </div>
                    <div id="div-extreme">
                        <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                    </div>
                    <div id="div-avaya">
                        <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
                    </div>
                    <div id="div-internel">
                        <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
                    </div>
                    <div id="div-siklu">
                        <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
                    </div>
                    <div id="div-total-ground">
                        <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                    </div>
                </div>
            </div>

            <div id="certi2">
                <div id="line1">
                    <div id="div-axis">
                        <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                    </div>
                    <div id="div-dahua">
                        <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                    </div>
                </div>
                <div id="line2">
                    <div id="div-hikvision">
                        <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                    </div>
                    <div id="div-milestone">
                        <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                    </div>
                </div>
                <div id="line3">
                    <div id="div-lenel">
                        <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                    </div>
                    <div id="div-radwin">
                        <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                    </div>
                </div>
                <div id="line4">
                    <div id="div-tripp-lite">
                        <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                    </div>
                    <div id="div-panduit">
                        <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                    </div>
                </div>
                <div id="line5">
                    <div id="div-cisco">
                        <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                    </div>
                    <div id="div-extreme">
                        <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                    </div>
                </div>
                <div id="line6">
                    <div id="div-avaya">
                        <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
                    </div>
                    <div id="div-internel">
                        <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
                    </div>
                </div>
                <div id="line7">
                    <div id="div-siklu">
                        <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
                    </div>
                    <div id="div-total-ground">
                        <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                    </div>
                </div>
                
            </div>
        </div><!--CIERRE CERTIFICACIONES-->
        <div class="industrial">
            <!--
            <h1>Industrial Security</h1>
            <div>
                <a href='http://www.isnetworld.com'>
                    <img alt='ISN Contractor Safety Management' src='http://www.isnetworld.com/images/memberCeLogo_small.png' />
                </a>
            </div>
            -->
            <div class="seguridad-industrial">
                <div class="titulo-oculto-movil">
                    <h1>Industrial Security</h1>                    
                </div>
                <div>
                    <img src="../../assets/Imagenes/seg-indus.jpeg" alt="seguridad">
                </div>
                <div id="texto-industrial">
                    <h1>Industrial Security</h1>
                    <div>
                        <p>
                            Nos especializamos en atender a clientes industriales y de Infraestructura Crítica, por lo que contamos con un Departamento de Seguridad Industrial, quienes verifican y se aseguran de que los trabajos que realizamos cumplan con las más estrictas normas internacionales en materia de Seguridad Industrial.    
                            <a href='http://www.isnetworld.com' target="_blank">
                                <img alt='ISN Contractor Safety Management' src='http://www.isnetworld.com/images/memberCeLogo_small.png' />
                            </a>
                        </p>
                    </div>
                    <p>
                        Además, somos miembro activo de ISNetworld, que es una plataforma de gestión de contratistas que ayuda a gestionar los riesgos, a apoyar la sustentabilidad y a fortalecer las relaciones. Ayuda a reducir las duplicaciones innecesarias asociadas con los procesos de cualificación tradicionales.
                    </p>
                    <p>
                        El equipo de Revisión y Verificación (RAVS) de ISN verifica y evalúa nuestra información de salud, seguridad y medio ambiente para asegurarse que cumple con las regulaciones internacionales y los requisitos específicos de cada cliente.
                    </p>
                    <ul>
                        Entre la información que tenemos auditada por ISN destaca:
                        <li>Certificados de seguro</li>
                        <li>Documentación del IMSS</li>
                        <li>Manuales de procedimientos y Politicas de Seguridad, Salud y Medio Ambiente</li>
                        <li>Información de Entrenamiento y Capacitación</li>
                        <li>Rastreo de Horas Trabajadas e Incidentes Mensuales</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="galeria"><!--INICIO GALERIA DE FOTOS-->
            <div id="titulo-galeria">
                <h1>Photo Gallery</h1>
            </div>
            <div id="fotos-galeria">
                <div id="g-linea1">
                    <img src="..\..\assets\Imagenes\fotos-tbell\camaras\25.jpeg">
                    <img src="..\..\assets\Imagenes\fotos-tbell\camaras\33.jpeg">
                </div>
                <div id="boton-galeria">
                    <div id="bgvermas">
                        <a href="#/gallery">See more...</a>
                    </div>
                </div>
            </div>
        </div><!--CIERRE GALERIA DE FOTOS-->
        <div id="ubicacion">
            <h1>Where we work</h1>
            <div id="mapa">
                <!--
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.0582211107767!2d-98.19567708509975!3d19.017155987121768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc0966034380d%3A0x439eef3fdfc4b144!2sT-Bell%20Telecom!5e0!3m2!1ses!2smx!4v1616715231008!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                -->
                <img src="..\..\assets\Imagenes\misc\mapa-clientes.jpeg">
            </div>
        </div>
        <!--<div id="contacto">
            <h1>Contacto</h1>
            <div id="contacto1">
                <div>
                    <p>ventascorporativas@t-bell.com.mx</p>
                </div>
                <div>
                    <p>+52 800-7-2355-11</p>
                </div>
            </div>
        </div>CIERRE CONTACTO-->
        <app-boton-whatsapp></app-boton-whatsapp>
        <app-footer-page></app-footer-page>
    </div><!--CIERRE CUERPO-->
</div><!--CIERRE PAGINA PRINCIPAL-->