<app-navegador-n></app-navegador-n>
<div id="escuelas-seguridad">
	<!--<div id="linea-nav">
        <p>
            <a href="#/dt-educacion">Educación</a> - <a href="#/e-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/e-seguridad">Seguridad</a> > <a href="#/e-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>--><!--CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Educación</h1>
        </div>
        <div>
            <div>
                <h3>Seguridad Electrónica</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV y Voceo</h3>
                <p>
                    Circuito cerrado de televisión (servidor, VMS, cámaras) para la vigilancia de algún punto en particular. Pueden estar integrados altavoces que sirven para dar alertas en caso de contingencias, dar anuncios, hacer sonar alarmas y/o advertencias.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Seguridad Perimetral</h3>
                <p>
                    Mantiene seguros los alrededores de sus instalaciones a través de cámaras de merodeo, geo cercas, etc.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Control de Acceso</h3>
                <p>
                    Permite o restringe el acceso a únicamente estudiantes, personal y visitantes autorizados.
                </p>
            </div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>Lectores Biométricos (huella, palma, ojos, facial)</h3>
                <p>
                    Autentica la identidad de una persona a través de lectores de huella o facial, maximizando la tranquilidad y seguridad de los estudiantes.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-new-footer></app-new-footer>
</div>