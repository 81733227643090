<app-navigation-bar></app-navigation-bar>
<div id="contacto">
    <div id="contenido">
    	<div id="imagen-titulo">
    	<div id="soporte" class="cuadro">
    		<h1>Technical Support</h1>
    		<p>If you are already a T-Bell Telecom customer and require technical support help, click <a href="https://tbell.freshdesk.com/support/home">here</a></p>
    	</div>
    	</div>
    </div>
</div>
<app-footer-page></app-footer-page>