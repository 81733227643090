<!--
<app-navegador></app-navegador>
<div id="cuerpo">
	<div id="titulo">
		<h1>Industria</h1>	
	</div>
</div>
-->
<app-navegador-wb></app-navegador-wb>
<div class="base">

	<!--                            INICIO VIDEO                                   -->
    <div class="video">
        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\INDUSTRIA A.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INDUSTRIA B.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\INDUSTRIA C.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div>
        <div class="tit">
            <h1>Industria</h1>
        </div>
    </div>
	<!--                            FIN VIDEO                                      -->


	<!--                            INICIO CIRCULOS                                -->
	<div class="circulos">
        <a href="#infraestructura">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (11).png">
        </a>
        <a href="#seguridad">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (1).png">
        </a>
        <a href="#comunicacion">
            <img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (2).png">
        </a>
        <a href="#logistica">
			<img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (3).png">
		</a>
        <a href="#colaboracion">
			<img src="..\..\assets\Imagenes\nuevas\TRABAJAMOS (4).png">
		</a>
    </div>
	<!--                           FIN CIRCULOS                                    -->


    <div class="titulo-infraestructura" id="infraestructura">
        <h1>infraestructura de telecomunicaciones</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-infraestructura-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-01.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Data center</h1>
                            <p>
                                Almacena una gran cantidad de datos <br>
                                dentro de la organización que pueden <br>
                                ser críticos para los servicios.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-infraestructura-5">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-02.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Industrial distribution frame (IDF)</h1>
                            <p>
                                Extiende el alcance de las <br>
                                comunicaciones entre <br>
                                equipos, voz y datos (LAN).
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-03.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Cableado industrial</h1>
                            <p>
                                Medio por el cual se propagan los <br>
                                datos y se crea la comunicación entre <br>
                                equipos, contando con ciertos <br>
                                atributos específicos (interiores - <br>
                                exteriores).
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-04.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>AP industriales</h1>
                            <p>
                                Punto de acceso que ayuda a <br>
                                propagar la red de manera <br>
                                inalámbrica, lo que permite <br>
                                mayor velocidad y cobertura
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-5">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-infraestructura-5">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-05.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Switches / traductores nat</h1>
                            <p>
                                Los sistemas de control con <br>
                                configuraciones idénticas se pueden <br>
                                comunicar a través de redes Ethernet <br>
                                sin necesidad de reconfigurar los <br>
                                parámetros de red utilizando un <br>
                                dispositivo traductor de direcciones de <br>
                                red (NAT) para asignar direcciones IP de <br>
                                la subred Empresarial a direcciones IP en <br>
                                la red de control.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-infraestructura-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-infraestructura-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR INFRAESTRUCTURA-->

    <div class="titulo-seg-elec" id="seguridad">
        <h1>Seguridad Electrónica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-seguridad-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-06.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>CCTV y Voceo</h1>
                            <p>
                                Circuito cerrado de televisión <br>
                                (servidor, VMS, cámaras) para la <br>
                                vigilancia de algún punto. Pueden estar <br>
                                integrados altavoces que sirven para <br>
                                dar alertas en caso de contingencias, <br>
                                dar anuncios, hacer sonar alarmas y/o <br>
                                advertencias.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-07.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Control de acceso</h1>
                            <p>
                                Sistema que permite o restringe el <br>
                                acceso a un área determinada. <br>
                                Ayuda a controlar los accesos a <br>
                                zonas restringidas o donde el <br>
                                personal sea limitado
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-08.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Seguridad perimetral</h1>
                            <p>
                                Mantiene seguros los alrededores <br>
                                de sus instalaciones a través de <br>
                                cámaras de merodeo, geo cercas, <br>
                                etc.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-4">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-seguridad-4">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-09.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Lectores Biométricos</h1>
                            <p>
                                Autentica la identidad de una <br>
                                persona a través de lectores de <br>
                                huella o faciales, maximizando la <br>
                                seguridad del área.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-seguridad-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-seguridad-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR SEGURIDAD-->

    <div class="titulo-com-uni" id="comunicacion">
        <h1>Comunicación unificada</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-comunicacion-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-10.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Conmutadores IP</h1>
                            <p>
                                Es el sistema para la red telefónica, <br>
                                enfocado a empresas para <br>
                                interconectar sus propias <br>
                                comunicaciones, internas y externas.
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-comunicacion-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-11.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Voceo IP</h1>
                            <p>
                                Sistema que permite o restringe el <br>
                                acceso a un área determinada. <br>
                                Ayuda a controlar los accesos a <br>
                                zonas restringidas o donde el <br>
                                personal sea limitado
                            </p>
                            <div class="linea-color" id="color-comunicacion"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-comunicacion-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>


    </div>  <!-- FIN CONTENEDOR COMUNICACION-->
    <div class="espacio-footer"></div>

    <div class="titulo-infraestructura" id="logistica">
        <h1>Logistica</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-logistica-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-12.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Rastreo GPS de vehiculos y contenedores</h1>
                            <p>
                                Control de las flotillas y <br>
                                logística en cuanto a <br>
                                transporte.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-logistica-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-logistica-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-13.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Candados inteligentes</h1>
                            <p>
                                Manda una señal de alarma junto <br>
                                con la ubicación en tiempo real al <br>
                                centro de vigilancia en caso de <br>
                                que el candado ha sido abierto, <br>
                                cerrado y/o violado.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-logistica-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-logistica-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-14.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Analíticos de manejo</h1>
                            <p>
                                Monitorea el comportamiento del <br>
                                personal que maneja el transporte, <br>
                                reporta al detectar distracciones o <br>
                                somnolencia del conductor. Genera <br>
                                reportes de kilometraje recorrido, <br>
                                consumo de combustible y paradas <br>
                                no programadas.
                            </p>
                            <div class="linea-color"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-logistica-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR LOGISTICA-->

    <div class="titulo-seg-elec" id="colaboracion">
        <h1>Colaboración Remota</h1>
    </div>
    <div class="contenedor-infraestructura">

        <div class="item-infraestructura" id="item-colab-1">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-15.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Sala de juntas</h1>
                            <p>
                                Mejora la experiencia en <br>
                                conferencias realizadas de <br>
                                manera remota.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="linea-color"></div>-->
            <div class="flechas">
                <a href="#item-colab-3">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-colab-2">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-colab-2">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-16.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Audio ambiental</h1>
                            <p>
                                Armoniza cualquier espacio con <br>
                                música de forma en que llegue a <br>
                                todos los rincones por igual.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-colab-1">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-colab-3">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

        <div class="item-infraestructura" id="item-colab-3">
            <div class="tarjeta">
                <div class="tarjeta-ext">
                    <div class="tarjeta-info">
                        <div class="tarjeta-imagen">
                            <img src="..\..\assets\Imagenes\nuevas\industria\industria-17.png">
                        </div>
                        <div class="tarjeta-texto">
                            <h1>Automatización de espacios</h1>
                            <p>
                                Facilite el manejo de el equipo, <br>
                                para agilizar su uso.
                            </p>
                            <div class="linea-color" id="color-seguridad"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flechas">
                <a href="#item-logistica-2">
                    <i class="fas fa-caret-left"></i>
                </a>
                <a href="#item-logistica-1">
                    <i class="fas fa-caret-right"></i>
                </a>
            </div>
        </div>

    </div>  <!-- FIN CONTENEDOR COLABORACION-->

    <div class="espacio-footer"></div>


	<!--                            INICIO INFRAESTRUCTURA                         -->
    <!--
	<div id="infraestructura" class="infraestructura">
		<div class="infraestructura-titulo">
            <h1>infraestructura de telecomunicaciones</h1>
        </div>
        <div class="infraestructura-subtitulo">
            <h2>Data Center</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\22.png">
            <p>
                Almacena una gran cantidad de datos <br>
				dentro de la organización que pueden <br>
				ser críticos para los servicios.
            </p>
        </div>

        <div class="infraestructura-subtitulo2">
            <div class="vacio2i"></div>
            <h2>Industrial distribution frame (IDF)</h2>
        </div>
		<div class="derecha">
            <p>
                Extiende el alcance de las <br>
                comunicaciones entre <br>
                equipos, voz y datos (LAN).
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\23.png">
        </div>

        <div class="infraestructura-subtitulo3">
            <h2>Cableado industrial</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\24.png">
            <p>
                Medio por el cual se propagan los <br>
                datos y se crea la comunicación entre <br>
                equipos, contando con ciertos <br>
                atributos específicos (interiores - <br>
                exteriores).
            </p>
        </div>

        <div class="infraestructura-subtitulo4">
            <div class="vacio2i"></div>
            <h2>Ap industriales</h2>
        </div>
		<div class="derecha">
            <p>
                Punto de acceso que ayuda a <br>
                propagar la red de manera <br>
                inalámbrica, lo que permite <br>
                mayor velocidad y cobertura
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\25.png">
        </div>

        <div class="infraestructura-subtitulo5">
            <h2>Switches / Traductores nat</h2>
            <div class="vacio"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\26.png">
            <p>
                Los sistemas de control con <br>
                configuraciones idénticas se pueden <br>
                comunicar a través de redes Ethernet <br>
                sin necesidad de reconfigurar los <br>
                parámetros de red utilizando un <br>
                dispositivo traductor de direcciones de <br>
                red (NAT) para asignar direcciones IP de <br>
                la subred Empresarial a direcciones IP en <br>
                la red de control.
            </p>
        </div>

	</div>
    -->
	<!--                            FIN INFRAESTRUCTURA                            -->

    <!--                            INICIO SEGURIDAD                               -->
    <!--
    <div id="seguridad" class="seguridad">

        <div class="seguridad-titulo">
            <h1>Seguridad Electrónica</h1>
        </div>

        <div class="seguridad-subtitulo">
            <div class="vacio-sd"></div>
            <h2>CCTV y Voceo</h2>
        </div>
		<div class="derecha">
            <p>
                Circuito cerrado de televisión <br>
                (Servidor, VMS, Cámaras). Pueden tener <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas <br>
                y/o advertencias.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\27.png">
        </div>

        <div class="seguridad-subtitulo2">
            <h2>Control de acceso</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\28.png">
            <p>
                Sistema que permite o restringe el <br>
                acceso a un área determinada. <br>
                Ayuda a controlar los accesos a <br>
                zonas restringidas o donde el <br>
                personal sea limitado
            </p>
        </div>

        <div class="seguridad-subtitulo3">
            <div class="vacio-sd"></div>
            <h2>Seguridad industrial</h2>
        </div>
		<div class="derecha">
            <p>
                Mantiene seguros los alrededores <br>
                de sus instalaciones a través de <br>
                cámaras de merodeo, geo cercas, <br>
                etc.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\29.png">
        </div>

        <div class="seguridad-subtitulo4">
            <h2>Lectores biométricos</h2>
            <div class="vacio-si"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\30.png">
            <p>
                Autentica la identidad de una <br>
                persona a través de lectores de <br>
                huella, faciales O RETINA <br>
                maximizando la seguridad del <br>
                área.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN SEGURIDAD                                  -->

    <!--                            INICIO COMUNICACION                            -->
    <!--
    <div id="comunicacion" class="comunicacion">
        
        <div class="comunicacion-titulo">
            <h1>Comunicación Unificada</h1>
        </div>

        <div class="comunicacion-subtitulo">
            <div class="vacio-cud"></div>
            <h2>conmutadores ip</h2>
        </div>
		<div class="derecha">
            <p>
                Circuito cerrado de televisión <br>
                (Servidor, VMS, Cámaras). Pueden tener <br>
                integrados altavoces que sirven para <br>
                dar alertas en caso de contingencias, <br>
                dar anuncios, hacer sonar alarmas <br>
                y/o advertencias.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\31.png">
        </div>

        <div class="comunicacion-subtitulo2">
            <h2>voceo ip</h2>
            <div class="vacio-cui"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\32.png">
            <p>
                Sistema que permite o restringe el <br>
                acceso a un área determinada. <br>
                Ayuda a controlar los accesos a <br>
                zonas restringidas o donde el <br>
                personal sea limitado
            </p>
        </div>

    </div>
    -->
    <!--                            FIN COMUNICACION                               -->

    <!--                            INICIO LOGISTICA                               -->
    <!--
    <div id="logistica" class="logistica">

        <div class="logistica-titulo">
            <h1>Logística</h1>
        </div>

        <div class="logistica-subtitulo">
            <div class="vacio-ld"></div>
            <h2>Rastreo gps de vehiculos y contenedores</h2>
        </div>
		<div class="derecha">
            <p>
                Control de las flotillas y <br>
				logística en cuanto a <br>
				transporte.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\33-2.png">
        </div>

        <div class="logistica-subtitulo2">
            <h2>candados inteligentes</h2>
            <div class="vacio-li"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\34.png">
            <p>
                Manda una señal de alarma junto <br>
                con la ubicación en tiempo real al <br>
                centro de vigilancia en caso de <br>
                que el candado ha sido abierto, <br>
                cerrado y/o violado.
            </p>
        </div>

        <div class="logistica-subtitulo3">
            <div class="vacio-ld"></div>
            <h2>Analíticos de manejo</h2>
        </div>
		<div class="derecha">
            <p>
                Monitorea el comportamiento del <br>
                personal que maneja el transporte, <br>
                reporta al detectar distracciones o <br>
                somnolencia del conductor. Genera <br>
                reportes de kilometraje recorrido, <br>
                consumo de combustible y paradas <br>
                no programadas.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\35.png">
        </div>

    </div>
    -->
    <!--                            FIN LOGISTICA                                  -->

    <!--                            INICIO COLABORACION                            -->
    <!--
    <div id="colaboracion" class="colaboracion">

        <div class="colaboracion-titulo">
            <h1>Colaboración Remota</h1>
        </div>

        <div class="colaboracion-subtitulo">
            <h2>Sala de juntas</h2>
            <div class="vacio-cri"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\36.png">
            <p>
                Mejora la experiencia en <br>
                conferencias realizadas de <br>
                manera remota.
            </p>
        </div>

        <div class="colaboracion-subtitulo">
            <div class="vacio-crd"></div>
            <h2>audio ambiental</h2>
        </div>
		<div class="derecha">
            <p>
                Armoniza cualquier espacio con <br>
                música de forma en que llegue a <br>
                todos los rincones por igual.
            </p>
            <img src="..\..\assets\Imagenes\nuevas\industria\37-2.png">
        </div>

        <div class="colaboracion-subtitulo">
            <h2>automatización de espacios</h2>
            <div class="vacio-cri"></div>
        </div>
		<div class="izquierda">
            <img src="..\..\assets\Imagenes\nuevas\industria\38.png">
            <p>
                Facilite el manejo de el equipo, <br>
                para agilizar su uso.
            </p>
        </div>

    </div>
    -->
    <!--                            FIN COLABORACION                               -->

    <app-new-footer></app-new-footer>

</div>