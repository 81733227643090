<app-navegador-n></app-navegador-n>
<div class="base">
    <div class="video">
        <!--<video src="..\..\assets\Videos\video-home.mp4" autoplay="true" loop="true" muted="true"></video>-->
        <video src="..\..\assets\Videos\certificaciones y seguridad industrial.mp4" autoplay="true" loop="true" muted="true"></video>
        <div class="fvideo">
            <h1>Certificaciones</h1>
        </div>
        <div class="fvideo2">
            <h1>Seguridad Industrial</h1>
        </div>
    </div>

    <div id="certificaciones">
        <div class="titulo">
            <h1>CERTIFICACIONES</h1>
            <div class="blanco1"></div>
        </div>
        <div id="certi">
        <div id="linea1">
            <div id="div-axis">
                <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
            </div>
            <div id="div-dahua">
                <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
            </div>
            <div id="div-hikvision">
                <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
            </div>
            <div id="div-milestone">
                <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
            </div>
            <div id="div-lenel">
                <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
            </div>
            <div id="div-radwin">
                <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
            </div>
            <div id="div-tripp-lite">
                <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
            </div>
        </div>
        <div id="linea2">
            <div id="div-panduit">
                <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
            </div>
            <div id="div-cisco">
                <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
            </div>
            <div id="div-extreme">
                <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
            </div>
            <div id="div-avaya">
                <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
            </div>
            <div id="div-internel">
                <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
            </div>
            <div id="div-siklu">
                <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
            </div>
            <div id="div-total-ground">
                <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
            </div>
        </div>
        </div>

        <div id="certi2">
            <div id="line1">
                <div id="div-axis">
                    <img id="axis" src="..\..\assets\Imagenes\certi-color\c-axis2.png">
                </div>
                <div id="div-dahua">
                    <img id="dahua" src="..\..\assets\Imagenes\certi-color\c-dahua2.png">
                </div>
            </div>
            <div id="line2">
                <div id="div-hikvision">
                    <img id="hikvision" src="..\..\assets\Imagenes\certi-color\c-hikvision.png">
                </div>
                <div id="div-milestone">
                    <img id="milestone" src="..\..\assets\Imagenes\certi-color\c-milestone3.png">
                </div>
            </div>
            <div id="line3">
                <div id="div-lenel">
                    <img id="lenel" src="..\..\assets\Imagenes\certi-color\c-lenel2.png">
                </div>
                <div id="div-radwin">
                    <img id="radwin" src="..\..\assets\Imagenes\certi-color\c-radwin2.png">
                </div>
            </div>
            <div id="line4">
                <div id="div-tripp-lite">
                    <img id="tripp-lite" src="..\..\assets\Imagenes\certi-color\c-tripp-lite2.png">
                </div>
                <div id="div-panduit">
                    <img id="panduit" src="..\..\assets\Imagenes\certi-color\c-panduit2.png">
                </div>
            </div>
            <div id="line5">
                <div id="div-cisco">
                    <img id="cisco" src="..\..\assets\Imagenes\certi-color\c-cisco.png">
                </div>
                <div id="div-extreme">
                    <img id="extreme" src="..\..\assets\Imagenes\certi-color\c-extreme.png">
                </div>
            </div>
            <div id="line6">
                <div id="div-avaya">
                    <img id="avaya" src="..\..\assets\Imagenes\certi-color\c-avaya.png">
                </div>
                <div id="div-internel">
                    <img id="intertel" src="..\..\assets\Imagenes\certi-color\c-intertel.png">
                </div>
            </div>
            <div id="line7">
                <div id="div-siklu">
                    <img id="siklu" src="..\..\assets\Imagenes\certi-color\c-siklu.png">
                </div>
                <div id="div-total-ground">
                    <img id="total-ground" src="..\..\assets\Imagenes\certi-color\c-totalground.png">
                </div>
            </div>
            
        </div>
    </div>

    <div class="titulo-industria">
        <div class="blanco2"></div>
        <h1>SEGURIDAD INDUSTRIAL</h1>
    </div>
    <div class="industrial">
        <div class="seguridad-industrial">
            <div id="texto-industrial">
                <div>
                    <p>
                        Nos especializamos en atender a clientes industriales y de Infraestructura Crítica, por lo que contamos con un Departamento de Seguridad Industrial, quienes verifican y se aseguran de que los trabajos que realizamos cumplan con las más estrictas normas internacionales en materia de Seguridad Industrial.     <br>
                        <a href='http://www.isnetworld.com' target="_blank">
                            <img alt='ISN Contractor Safety Management' src='http://www.isnetworld.com/images/memberCeLogo_small.png' />
                        </a>
                    </p>
                </div>
                <p>
                    Además, somos miembro activo de ISNetworld, que es una plataforma de gestión de contratistas que ayuda a gestionar los riesgos, a apoyar la sustentabilidad y a fortalecer las relaciones. Ayuda a reducir las duplicaciones innecesarias asociadas con los procesos de cualificación tradicionales.
                </p>
                <p>
                    El equipo de Revisión y Verificación (RAVS) de ISN verifica y evalúa nuestra información de salud, seguridad y medio ambiente para asegurarse que cumple con las regulaciones internacionales y los requisitos específicos de cada cliente.
                </p>
                <ul>
                    Entre la información que tenemos auditada por ISN destaca:
                    <li>Certificados de seguro</li>
                    <li>Documentación del IMSS</li>
                    <li>Manuales de procedimientos y Politicas de Seguridad, Salud y Medio Ambiente</li>
                    <li>Información de Entrenamiento y Capacitación</li>
                    <li>Rastreo de Horas Trabajadas e Incidentes Mensuales</li>
                </ul>
            </div>
            <div>
                <img src="../../assets/Imagenes/seg-indus.jpeg" alt="seguridad">
            </div>
        </div>
    </div>
    <app-new-footer></app-new-footer>
</div>