<!--<app-navegador-n></app-navegador-n>
<div class="titulo">
    <h1>Industria Automotriz</h1>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz1.jpg" alt="">
    </div>
    <div class="texto">
        <p>Sistema de apoyo de cámara de seguridad IP y control de acceso para la certificación C-TPAT (Customs-Trade Partnership Against Terrorism)</p>
    </div>
</div>
<div class="division">
    <div class="texto-f">
        <p>Prevención de accidentes, mayor productividad y control de calidad en la industria llantera, Todo esto mediante un sistema de CCTV y sensores de condiciones ambientales. Todo a través de una red IP Cat 6 Certificada.</p>
    </div>
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz2.png" alt="">
    </div>
</div>
<div class="division">
    <div class="imagen">
        <img src="../../assets/Imagenes/exito/sub/Automotriz3.jpg" alt="">
    </div>
    <div class="texto-b">
        <p>Mayor eficiencia, seguridad y productividad mediante una red IP de cobre y fibra óptica que transmite voz y datos así como video del sistema de cámaras de seguridad.</p>
    </div>
</div>
<app-new-footer></app-new-footer>-->

<app-navegador-wb></app-navegador-wb>
<div class="cuerpo">

    <div class="video">

        <div class="videos">
			<div>
				<video src="..\..\assets\Videos\I. AUTOMOTRIZ 1.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\I. AUTOMOTRIZ 2.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
			<div>
				<video src="..\..\assets\Videos\I. AUTOMOTRIZ 3.mp4" autoplay="true" loop="true" muted="true"></video>
			</div>
        </div><!--FIN VIDEOS-->

        <div class="tit">
            <h1>Industria Automotriz</h1>
        </div><!--FIN TIT-->

    </div><!--FIN VIDEO-->

    <div class="info">
        <p>
            Para mitigar los riesgos dentro de la industria incorporamos sistemas de apoyo que <br>
            ayuden a mejorar la productividad, eficiencia y sobre todo la seguridad de los trabajadores.
        </p>

        <div class="clientes">

            <div class="titulo-clientes">
                <h1>Clientes</h1>
                <div class="linea"></div>
            </div>

            <div class="marcas">
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/bridgestone.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/autotodo.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/benteler.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/fft.png">
                </div>
                <div>
                    <img src="../../assets/Imagenes/casos-de-exito/logos/r/federal-mogul.png">
                </div>
            </div>

            <small>
                * Las marcas registradas y logotipos aquí mostrados son propiedad de sus respectivos dueños y son utilizadas
                únicamente con fines enunciativos.
            </small>
        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Benteler</h2>
                    <p>
                        Para mayor eficiencia, seguridad y productividad anexamos red IP de 
                        cobre y fibra óptica que transmite voz y datos así como video del sistema de cámaras de seguridad.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-auto-1.png">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Bridgestone</h2>
                    <p>s
                        Para la prevención de accidentes, mayor productividad y control de calidad en la industria llantera, 
                        colocamos sistema de CCTV y sensores de condiciones ambientales.
                        Todo a través de una red IP Cat 6 Certificada.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-auto-2.png">
                </div>
            </div>

        </div>

        <div class="new-cuadros">

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Federal Mogul</h2>
                    <p>
                        Incorporamos Sistema de apoyo de cámara de seguridad IP y 
                        control de acceso para la certificación C-TPAT (Customs-Trade Partnership Against Terrorism)
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\federal.jpg">
                </div>
            </div>

            <div class="item-cuadros">
                <div class="cuadros-text">
                    <h2>Autotodo</h2>
                    <p>s
                        Hemos incorporado Infraestructura y equipamiento de red, así como control de acceso, 
                        CCTV y voceo en diversas plantas de autotodo, alrededor de la república mexicana
                        <br><br>
                        Trabajamos de la mano con la industria e integramos los servicios necesarios para que 
                        siempre tengan un buen funcionamiento. Hemos colocado nodos de red, 
                        reubicado y activado cámaras de seguridad, además de dar mantenimiento a diferentes equipos.
                    </p>
                </div>
                <div class="cuadros-image">
                    <img src="..\..\assets\Imagenes\casos-de-exito\ce-auto-4.png">
                </div>
            </div>

        </div>

        <!--
        <div class="cuadros">

            <div class="tres">
                <div class="imagen">
                    <h2>Benteler</h2>
                    <div class="texto">
                        <p>
                            Para mayor eficiencia, seguridad y productividad anexamos red IP de 
                            cobre y fibra óptica que transmite voz y datos así como video del sistema de cámaras de seguridad.
                        </p>
                    </div>
                </div>
            </div>

            <div class="dos">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Bridgestone</h2>
                    <div class="texto">
                        <p>
                            Para la prevención de accidentes, mayor productividad y control de calidad en la industria llantera, 
                            colocamos sistema de CCTV y sensores de condiciones ambientales.
                            Todo a través de una red IP Cat 6 Certificada.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        --><!--FIN CUADROS-->

        <!--
        <div class="cuadros">

            <div class="uno">
                <div class="imagen">
                    <h2>Federal Mogul</h2>
                    <div class="texto">
                        <p>
                            Incorporamos Sistema de apoyo de cámara de seguridad IP y 
                            control de acceso para la certificación C-TPAT (Customs-Trade Partnership Against Terrorism)
                        </p>
                    </div>
                </div>
            </div>

            <div class="cuatro">
                <div class="imagen">
                    --<img src="..\..\assets\Imagenes\casos-de-exito\bimbo.jpg">--
                    <h2>Autotodo</h2>
                    <div class="texto">
                        <p>
                            Hemos incorporado Infraestructura y equipamiento de red, así como control de acceso, 
                            CCTV y voceo en diversas plantas de autotodo, alrededor de la república mexicana
                            <br><br>
                            Trabajamos de la mano con la industria e integramos los servicios necesarios para que 
                            siempre tengan un buen funcionamiento. Hemos colocado nodos de red, 
                            reubicado y activado cámaras de seguridad, además de dar mantenimiento a diferentes equipos.
                        </p>
                    </div>
                </div>
            </div>

        </div>--><!--FIN CUADROS-->

    </div><!--FIN INFO-->
</div><!--FIN CUERPO-->
<app-new-footer></app-new-footer>