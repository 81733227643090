<div id="pagina">
	<div id="barra">
		<nav>
			<img src="..\..\assets\Imagenes\logostbell\T-Bell 2016 Invertido transparente.png" onclick="location.href='#/principal'">
			<h1>T-Bell Telecom</h1>	
		</nav>
	</div>
	<div id="cuerpo">
		<div id="formulario">
			<form method="POST">
				<h2>Verificación</h2>
				<p>Se ha enviado un numero de verificación a su correo seb*******@gma******</p>
				<p>favor de revisar su bandeja de entrada para confirmar el numero</p>
				<label for="POST-veri">Ingrese su numero de verificación</label>
				<input id="POST-veri" type="text" class="clase-input">
				<input type="submit" value="Verificar" class="boton">
			</form>
		</div>
	</div>
</div>