import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ci-colab-remota',
  templateUrl: './ci-colab-remota.component.html',
  styleUrls: ['./ci-colab-remota.component.css']
})
export class CiColabRemotaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
