<app-navigation-bar></app-navigation-bar>
<div id="ciudades-seguridad">
    <!--<div id="linea-nav">
        <p>
            <a href="#/dt-ciudades-inteligentes">Ciudades Inteligentes</a> - <a href="#/ci-infraestructura">Infraestructura de Telecomunicaciones</a> < <a href="#/ci-seguridad">Seguridad</a> > <a href="#/ci-com-uni">Comunicaciones Unificadas</a>
        </p>
    </div>CIERRE LINEA NAV-->
    <div id="titulo">
        <div>
            <h1>Smart Cities</h1>
        </div>
        <div>
            <div>
                <h3>Electronic Security</h3>
            </div>
        </div>
    </div><!--CIERRE TITULO-->
    <div id="cuadro-info">
        <div id="data-center">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e1.png">-->
            </div>
            <div id="texto">
                <h3>CCTV & Voice</h3>
                <p>
                    Provide more security to the citizens. Also in case of an emergency, the response time can be reduced.
                </p>
            </div>
        </div>
        <div id="idf">
            <div id="texto">
                <h3>Video analytics</h3>
                <p>
                    Be analyzing people's behavior in difficult areas to visualize, it give support to the security personnel. This can help to speed up their response in case of an incident.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e2.png">-->
            </div>
        </div>
        <div id="cableado">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Emergency posts</h3>
                <p>
                    Allow users to send an alarm in case of an emergency.
                </p>
            </div>
            <div id="imagen2"></div>
        </div>
        <div id="access-point">
            <div id="texto">
                <h3>C5</h3>
                <p>
                    Centralized the communication and security monitoring in different zones.
                </p>
            </div>
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e4.png">-->
            </div>
        </div>
        <div id="detec">
            <div id="imagen">
                <!--<img src="..\..\assets\Imagenes\verticales\industria-s-e3.png">-->
            </div>
            <div id="texto">
                <h3>Gunshot detection</h3>
                <p>
                    Detects and differentiate between a common sound and a gunshot. Once the gunshot detected, an alarm is send.
                </p>
            </div>
        </div>
        <div id="luces">
            <div id="texto">
                <h3>Vehicle signaling lights</h3>
                <p>
                    The highest technology in audiovisual signaling systems for emergency vehicles.
                    <br>
                    We have the best products such as Turrets, Mini Turrets, Strobes, Sirens, Horns, Controllers, Interior Bars, Motorcycle Accessories and much more.
                </p>
            </div>
            <div id="imagen">
            </div>
        </div>
    </div><!--CIERRE CUADRO INFO-->
    <app-footer-page></app-footer-page>
</div>