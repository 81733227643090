import { Component, OnInit } from '@angular/core';
import { CargarScriptsService } from '../cargar-scripts.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-navegador-n',
  templateUrl: './navegador-n.component.html',
  styleUrls: ['./navegador-n.component.css']
})
export class NavegadorNComponent implements OnInit {

  constructor(private _cargaScript:CargarScriptsService) {
    _cargaScript.Carga(["nav"]);
  }

  ngOnInit(): void {
  }

}
